import React, {useEffect} from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SideBar from '../../../components/organisms/Product_Page_Components/SideBar';


const ProductPage = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        <SideBar/>
    </>
  )
}

export default ProductPage