import React, { useState } from "react";
import OrderProductTable from "./OrderProductTable";
import OrderOrderTable from "./OrderOrderTable";
import { useFetchOrderQuery } from "../../../../store/store";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import Hr from "../../../../atoms/buttons/Hr";

const OrderModel = ({ performCancel, data }) => {
  return (
    <div className="model-con">
      <div className="model-box" style={{ height: "90%" }}>
        <p className="p--cross--custom" onClick={() => performCancel()}>
          <CloseOutlined />
        </p>
        <div style={{ height: "100%", overflow: "auto" }}>
         
          <OrderProductTable data={data} />
        </div>
      </div>
    </div>
  );
};
const OrdersUser = () => {
  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const [showModel, setShowModel] = useState(false);
  const performCancel = () => {
    setShowModel(false);
    setProductGet();
  };
  const [productGet, setProductGet] = useState();
  const {
    data: orders,
    isLoading: orders_loading,
    isFetching: fetch,
    error: error,
  } = useFetchOrderQuery({ _id: user?._id });

  return (
    <div className="order-details-profile-page-container">
      <h4>All Orders</h4>
      {/* <div onClick={() => setShowModel(true)}>True</div> */}

      <OrderOrderTable
        setProductGet={setProductGet}
        setShowModel={setShowModel}
        orders={orders}
      />
      {/* <OrderProductTable/> */}
      {showModel ? (
        <OrderModel performCancel={performCancel} data={productGet} />
      ) : null}
      {/* <OrderProductTable/> */}
      {/* <OrderProductTable/> */}
      {/* <OrderProductTable/> */}
    </div>
  );
};

export default OrdersUser;
