import React from "react";
import "./Footersue.css";
import { FaArrowRight } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "./../../../assets/sueLogo.png";

const Footersue = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-sue-container">
      <div className="footer-sue-top-part">
        <div className="product-footer-sue" style={{textAlign:"start"}}>
          <h3 className="footer-sue-list-heading">
            <img src={logo} alt="" />
          </h3>
          <p>
          Welcome to Swarupenterprises.in, your ultimate destination for stylish and comfortable seating solutions! Whether you're furnishing your living room, office, or patio, we have a wide selection of chairs and sofas to suit your needs and elevate your space.
          </p>
        </div>
        <div className="product-footer-sue">
          <h3 className="footer-sue-list-heading">Product</h3>
          <ul>
            <li onClick={() => navigate("/")}>Home</li>
            <li onClick={() => navigate("/shop-by-category")}>
              Shop By Category
            </li>
            <li onClick={() => navigate("/product-page")}>Products</li>
            {/* <li onClick={()=>navigate('/gallery')}>
                        Gallery
                    </li> */}
            <li onClick={() => navigate("/contact")}>Contact</li>
          </ul>
        </div>
        <div className="information-footer-sue">
          <h3 className="footer-sue-list-heading">Information</h3>
          <ul>
            <li>Terms And Condition</li>
            <li>Privacy Policy</li>
          </ul>
        </div>
        <div className="company-footer-sue">
          <h3 className="footer-sue-list-heading">Company</h3>
          <ul>
            <li>About Us</li>
            <li>Contact Us</li>
          </ul>
        </div>

        {/* <div className='subscribe-footer-sue-container'>
                <h1 className='subscribe-footer-sue-heading'>
                    Subscribe
                </h1>
                <div className='input-wrapper-footer-sue'>
                    <input className='input-footer-sue' placeholder='Email Address'/>
                    <button className='arrow-box-footer-sue'>
                        <FaArrowRight className='right-arrow-footer-sue' />
                    </button>
                </div>
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                Dolorem fugit earum cum quod ea enim, dolor praesentium atque 
                corporis obcaecati harum, dolore mollitia ipsa facere laborum, 
                id corrupti. Autem voluptatum doloribus recusandae neque.
                </p>
            </div> */}
      </div>
      <div className="footer-sue-line-container">
        <div className="footer-sue-line"></div>
      </div>
      <div className="footer-bottom-part">
        <div className="first-column"></div>
        <div className="second-column">
          <ul>
            <li>Terms</li>
            <li>Privacy</li>
            <li>Cookies</li>
          </ul>
        </div>
        <div className="third-column">
          <div className="footer-sue-icon">
            <FaLinkedinIn />
          </div>
          <div className="footer-sue-icon">
            <FaFacebookF />
          </div>
          <div className="footer-sue-icon">
            <FaTwitter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footersue;
