import { Button, Form, Input, message } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateUserMutation } from '../store/store';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Forgot=(data,query,createOrder)=>{
    if(data?.password!==data?.cpassword){
        message.error("Password is not Equal")
    }else{

        data.token= query.get('token')
        data._id=query.get('user_id')
        createOrder(data)
    }
}
const ForgotPass = () => {

    const query = useQuery();
    const token = query.get('token');
    const userId = query.get('user_id');
    const [createOrder, createOrderResponseInfo] = useUpdateUserMutation();
    const navigate=useNavigate()
    useEffect(() => {
      if(createOrderResponseInfo.isSuccess){
        message.success("Password Changed")
        navigate('/')
      }
      }, [createOrderResponseInfo]);
  return (
    <div className='login-admin-con' style={{height:"50vh"}}>

    <div className='login-admin-box'>
<h1 style={{marginBottom:"30px "}}>Forgot Password</h1>
  <Form onFinish={(data)=>Forgot(data,query,createOrder)}>
<FormItem name="cpassword">
<Input required  type='password' className='all-placeholder-grey' placeholder='Enter Your Password'/>
</FormItem>
<FormItem  name="password">
<Input type='password' required className='all-placeholder-grey' placeholder='Confirm Password'/>
</FormItem>
<FormItem >
<Button loading={false} htmlType='submit' type='primary' style={{width:"100%",background:"var(--pr-text-color)",color:"var(--pr-bg-color)"}}>Forgot</Button>
</FormItem>
  </Form>
    </div>
</div>
  )
}

export default ForgotPass
