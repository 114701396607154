import { useState } from 'react'
import React from 'react'
import { FaCircle } from "react-icons/fa";
import './PaymentPageMainPart.css'

const PaymentPageMainPart = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedBank(null); // Reset selected bank when option changes
  };

  const handleBankSelect = (bank) => {
    setSelectedBank(bank);
  };

  const renderInputFormat = () => {
    switch (selectedOption) {
      case 'Cash On Delivery':
        return(
            <div className='payment-page-main-part-payment-details'>
                <input type="text" placeholder="Enter your address" />
            </div>
        ); 
      case 'UPI':
        return(
            <div className='payment-page-main-part-payment-details'>
                <input type="text" placeholder="Enter your address" />
            </div>
        );
      case 'Credit Card/Debit Card':
        return (
          <div className='payment-page-main-part-payment-details'>
            <input type="text" placeholder="Card number" />
            <input type="text" placeholder="Expiry date" />
            <input type="text" placeholder="CVV" />
          </div>
        );
      case 'EMI':
        return (
          <div className='payment-page-main-part-payment-details'>
            <input type="text" placeholder="Bank name" />
            <input type="text" placeholder="EMI scheme" />
            <input type="text" placeholder="Duration" />
          </div>
        );
      case 'Netbanking':
        return (
          <div className='payment-page-main-part-payment-details'>
            <ul className="bank-list">
              <li className={selectedBank === 'Bank 1' ? 'selected' : ''} onClick={() => handleBankSelect('Bank 1')}><div className='payment-page-main-part-disc-icon'></div> Axis Bank</li>
              <li className={selectedBank === 'Bank 2' ? 'selected' : ''} onClick={() => handleBankSelect('Bank 2')}><div className='payment-page-main-part-disc-icon'></div>HDFC Bank</li>
              <li className={selectedBank === 'Bank 3' ? 'selected' : ''} onClick={() => handleBankSelect('Bank 3')}><div className='payment-page-main-part-disc-icon'></div>ICICI Bank</li>
              <li className={selectedBank === 'Bank 4' ? 'selected' : ''} onClick={() => handleBankSelect('Bank 4')}><div className='payment-page-main-part-disc-icon'></div>Kotak</li>
              <li className={selectedBank === 'Bank 5' ? 'selected' : ''} onClick={() => handleBankSelect('Bank 5')}><div className='payment-page-main-part-disc-icon'></div>SBI</li>
            </ul>
            {selectedBank && (
              <div className='payment-page-paynow-button-container'><button className="payment-page-main-part-pay-now" onClick={() => alert(`Paying with ${selectedBank}`)}>Pay Now</button></div>
            )}
          </div>
        );
      default:
        return null;
    }
  };


  return (
    <div className='payment-page-main-part-container'>

        <div className="payment-page-main-part-left-container">

            <div className='payment-page-main-part-left-first-box'>
                Select Payment Method
            </div>

            <div className='payment-page-main-part-left-second-box'>
                <div className="payment-page-main-part-left-sidebar">
                    <div className="payment-page-main-part-left-sidebar-option" onClick={() => handleOptionClick('Cash On Delivery')}>Cash On Delivery</div>
                    <div className="payment-page-main-part-left-sidebar-option" onClick={() => handleOptionClick('UPI')}>UPI</div>
                    <div className="payment-page-main-part-left-sidebar-option" onClick={() => handleOptionClick('Credit Card/Debit Card')}>Credit/Debit Card</div>
                    <div className="payment-page-main-part-left-sidebar-option" onClick={() => handleOptionClick('EMI')}>EMI</div>
                    <div className="payment-page-main-part-left-sidebar-option" onClick={() => handleOptionClick('Netbanking')}>Netbanking</div>
                </div>
                <div className="payment-page-main-part-left-sidebar-input-format">
                    {selectedOption && (
                    <>
                        <h3>{selectedOption}</h3>
                        {renderInputFormat()}
                    </>
                    )}
                </div>
            </div>
        </div>

        <div className='payment-page-main-part-right-container'>
                <p>Order Summary (1 Item)</p>
                <div className='address-page-main-part-right-line-container'><div className='address-page-main-part-right-horizontal-line'></div></div>
                
                <div className='address-page-right-prices-container'>
                    <div className='address-page-right-prices-first'>
                        Total MRP
                        <span>Rs.1699</span>
                    </div>
                    <div className='address-page-right-prices-second'>
                        Discount on MRP
                        <span>-Rs.300</span>
                    </div>
                    <div className='address-page-right-prices-second'>
                        Shipping Fee
                        <span>Free</span>
                    </div>
                </div>

                <div className='address-page-main-part-right-line-container'><div className='address-page-main-part-right-horizontal-line'></div></div>
                <div className='address-page-right-prices-third'>
                        Total Amount
                        <span>Rs.1399</span>
                </div>
            </div>
        
    </div>
  )
}

export default PaymentPageMainPart