import { configureStore } from "@reduxjs/toolkit";
import { allApi } from "./mutation/AllApi";
import remainingReducer from "./mutation/remainingSlice";
import userReducer from "./mutation/userSlice";
const store = configureStore({
  reducer: {
    user: userReducer,
    remaning: remainingReducer,
    [allApi.reducerPath]: allApi.reducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(allApi.middleware);
  },
  //   devTools: process.env.NODE_ENV !== "production",
});

export default store;

export {
  useCreateUserMutation,
  useDummyRunMutation,
  useFetchUserQuery,
  useUpdateUserMutation,
  useFetchStaffQuery,
  useFetchProductQuery,
  useFetchTrendingQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductQuery,
  useDeleteProductMutation,
  useFetchAdminProductQuery,
  useGetAdminProductQuery,
  useGetRelatedProductQuery,
  useFetchCartQuery,
  useUpdateCartMutation,
  useDeleteCartMutation,
  useCreateCartMutation,
  useCancelOrderMutation,
  useFailOrderMutation,
  useSuccessOrderMutation,
  usePayNowMutation,
  useFetchOrderQuery,
  useDeleteOrderForUserMutation,
  useFetchAdminOrderQuery,
  useUpdateOrderStageMutation,
  useFetchReviewQuery,
  useCreateReviewMutation,
  useDeleteReviewMutation,

  
  useFetchCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useFetchCouponAdminQuery,
  useDeleteCouponMutation,
  useFetchSuggestionsQuery,

  useForgotMailMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useFetchContactQuery,
  useGetContactQuery,

} from "./mutation/AllApi";
