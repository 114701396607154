import React, { useEffect } from "react";
import { BiCircle, BiSolidCircleHalf } from "react-icons/bi";
import { PiCircleFill } from "react-icons/pi";
import { useFetchCouponQuery } from "../../../store/store";
import { DiscountFun, SumOfCartWithDiscount } from "../../../atoms/static/StateFun";
import { message } from "antd";

const CouponBox = ({ coupon, setCoupon, cart }) => {
  const {
    data: data,
    isLoading: loading,
    error: error,
  } = useFetchCouponQuery();
  useEffect(() => {
    if (cart) {
      console.log(parseInt(SumOfCartWithDiscount(cart)));
    }
  }, [cart]);
  const handleSelect=(item)=>{
    console.log(DiscountFun(parseInt(SumOfCartWithDiscount(cart)),coupon?.percentage))
    if(parseInt(SumOfCartWithDiscount(cart))>=item?.price){

      setCoupon(coupon?._id===item?._id?"":item)
    }else{
      message.error("Cant Set This Offer")
    }
  }
  return (
    <div
      style={{
        margin: "20px 0px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {data?.map((item) => (
        <div
          className="cart-page-right-container-coupon"
          onClick={() => handleSelect(item)}
        >
          <h3>{item?.title}</h3>
          <p>
            Above {item?.price} Get Off Up To {item?.percentage} %
          </p>
          <div
            style={{
              position: "absolute",
              top: "4px",
              right: "4px",
              cursor: "pointer",
            }}
          >
            {coupon?._id === item?._id ? <PiCircleFill /> : <BiCircle />}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CouponBox;
