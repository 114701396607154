import React, { useEffect } from "react";
import {
  DiscountFun,
  IDate,
  SumOfOrder,
  SumOfOrderWithDiscount,
} from "../../../../atoms/static/StateFun";
import { CloseOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import LoadingHv from "../../../../atoms/buttons/LoadingHv";
import { useDeleteOrderForUserMutation } from "../../../../store/store";

const OrderOrderTable = ({ orders, setProductGet, setShowModel }) => {
  const [
    createOrderForUser,
    deleteOrderForUserResponseInfo,
  ] = useDeleteOrderForUserMutation();
  useEffect(() => {
    if (deleteOrderForUserResponseInfo?.isSuccess) {
      message.success("Deleted Successfully");
    }
    if (deleteOrderForUserResponseInfo?.isError) {
      message.error("Can't Deleted");
    }
  }, [deleteOrderForUserResponseInfo]);
  return (
    <ul>
      {orders ? (
        <>
          {orders?.map((data) => (
            <li>
              <p>
                {data?.order_id}{" "}
                <span
                  style={{
                    color: data?.payment === "Success" ? "green" : "red",
                  }}
                >
                  ({data?.payment})
                </span>
              </p>
              on {IDate(data?.created_at)}
              <p>{data?.stage}</p>
              <div
                className="each-order-detail-profile-page"
                style={{ position: "relative" }}
              >
                <Popconfirm
                  title="Sure to remove?"
                  onCancel={(e) => {
                    e.stopPropagation();
                  }}
                  onConfirm={() => createOrderForUser(data)}
                >
                  <p className="p--cross--custom">
                    <CloseOutlined />
                  </p>
                </Popconfirm>

                <div className="order-detail-profile-page-inner-div">
                  <p>Total Items</p>
                  <h2>{data?.product?.length}</h2>
                  <div className="vertical-line-each-order-detail-profile-page"></div>
                  <div className="product-details-each-order-detail-profile-page">
                    Shipping Name {data?.shipping?.name}
                    <span>Addresss {data?.shipping?.address}</span>
                    <span>Phone No. {data?.shipping?.phone}</span>
                    <div className="buttons-container-each-order-detail-profile-page">
                      <button
                        onClick={() => {
                          setShowModel(true);
                          setProductGet(data);
                        }}
                      >
                        View
                      </button>
                      {/* <button>Return</button> */}
                    </div>
                    {/* <span>Rs. {SumOfOrder(data)}</span> */}
                    <div className="carousel-card-price-product-page">
                      <span className="span-price-product-page">
                        Rs. {SumOfOrder(data)}
                      </span>
                      {"  "}
                      {data?.couponDiscount > 0 ? (
                        <>
                          Coupon Discount {data?.couponDiscount} %
                          <span className="span-price-product-page">
                            Rs. {parseInt(SumOfOrderWithDiscount(data))}
                          </span>
                          {"  "}
                          <>
                            Rs.{" "}
                            {DiscountFun(
                              parseInt(SumOfOrderWithDiscount(data)),
                              parseInt(data?.couponDiscount)
                            )}
                          </>
                        </>
                      ) : (
                        <>Rs. {parseInt(SumOfOrderWithDiscount(data))}</>
                      )}
                      <span
                        className="discount-rate-product-page"
                        style={{ color: "green" }}
                      >
                        SAVED Rs.
                        {parseInt(
                          parseInt(
                            SumOfOrder(data) - DiscountFun(
                              parseInt(SumOfOrderWithDiscount(data)),
                              parseInt(data?.couponDiscount)
                            )
                          )
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </>
      ) : (
        <LoadingHv />
      )}
    </ul>
  );
};

export default OrderOrderTable;
