import "./App.css";
import "./model.css";
import "./mobile.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from "./pages/home/Home";
import { useEffect, useState } from "react";
import Error from "./pages/error/Error";
import Navbar from "./components/organisms/Navbar/Navbar";
import Footersue from "./components/organisms/Footer/Footersue";
import Home from "./pages/client/home/Home";
import AdminDashboard from "./pages/admin/dashboard/AdminDashboard";
import AdminLogin from "./pages/admin/adminlogin/AdminLogin";
import ShopByCategoryPage from "./pages/client/ShopByCategory/ShopByCategory";
import ProductPage from "./pages/client/ProductPage/ProductPage";
import ProductDetailsPage from "./pages/client/ProductDetailsPage/ProductDetailsPage";
import CartPage from "./pages/client/CartPage/CartPage";
import AddressPage from "./pages/client/AddressPage/AddressPage";
import PaymentPage from "./pages/client/PaymentPage/PaymentPage";
import NavbarUser from "./components/organisms/NavbarUser/NavbarUser";
import ProfilePage from "./pages/client/ProfilePage/ProfilePage";
import { useDispatch, useSelector } from "react-redux";
import { getAdmin, getUser } from "./store/mutation/userSlice";
import Auth from "./store/auth/Auth";
import AdminAuth from "./store/auth/AdminAuth";
import SideBar from "./components/organisms/Product_Page_Components/SideBar";
import UserTable from "./pages/admin/userAndStaff/user/UserTable";
import StaffTable from "./pages/admin/userAndStaff/staff/StaffTable";
import SideAdmin from "./components/organisms/sidebar/SideAdmin";
import Admin_products from "./pages/admin/adminproducts/Admin_products";
import MainProduct from "./pages/admin/adminproducts/form/MainProduct";
import { Button, message } from "antd";
import {
  useCancelOrderMutation,
  useFailOrderMutation,
  usePayNowMutation,
  useSuccessOrderMutation,
} from "./store/store";
import OrderAdmin from "./pages/admin/adminorder/OrderAdmin";
import Staff from "./pages/admin/userAndStaff/Staff";
import Coupon from "./pages/admin/coupon/Coupon";
import ForgotPass from "./pages/ForgotPass";
import FormPage from "./pages/client/contact/Contact";
import EnquiryAdmin from "./pages/admin/contact/EnquiryAdmin";
// dkhsgdj

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleNavbar = () => {
    setIsLoggedIn((prevState) => !prevState);
  };
  const {
    userToken,
    loading,
    user,
    checkAuthLoading,
    isAuthenticated,
    admin_user,
    adminToken,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser());
    dispatch(getAdmin());
  }, []);
  const [createProduct, creatProductResponseInfo] = usePayNowMutation();
  const [cancelOrder, cancelOrderResponseInfo] = useCancelOrderMutation();
  const [failedOrder, failedOrderResponseInfo] = useFailOrderMutation();
  const [successOrder, successOrderResponseInfo] = useSuccessOrderMutation();
  useEffect(() => {
    if (cancelOrderResponseInfo?.isSuccess) {
      message.success("Order Canceled");
    }
    if (cancelOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [cancelOrderResponseInfo]);
  useEffect(() => {
    if (failedOrderResponseInfo?.isSuccess) {
      message.success("Order Failed");
    }
    if (failedOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [failedOrderResponseInfo]);
  useEffect(() => {
    if (successOrderResponseInfo?.isSuccess) {
      message.success("Order Success");
    }
    if (successOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [successOrderResponseInfo]);
  useEffect(() => {
    console.log(creatProductResponseInfo);

    if (creatProductResponseInfo?.isSuccess) {
      var options = {
        key: "" + creatProductResponseInfo?.data?.key_id + "",
        amount: "" + creatProductResponseInfo?.data?.amount + "",
        currency: "INR",
        name: "" + creatProductResponseInfo?.data?.product_name + "",
        description: "" + creatProductResponseInfo?.data?.description + "",
        image: "https://dummyimage.com/600x400/000/fff",
        order_id: "" + creatProductResponseInfo?.data?.order_id + "",
        handler: function (response) {
          message.success("Payment Succeeded");
          // razorpayObject.modal.close()
          // window.open("/profile-page","_self")
          successOrder({ order_id: creatProductResponseInfo?.data?.order_id });
        },
        modal: {
          ondismiss: function () {
            message.error("Payment Cancelled");
            cancelOrder({ order_id: creatProductResponseInfo?.data?.order_id });
          },
        },
        prefill: {
          contact: "" + creatProductResponseInfo?.data?.contact + "",
          name: "" + creatProductResponseInfo?.data?.name + "",
          email: "" + creatProductResponseInfo?.data?.email + "",
        },
        notes: {
          description: "" + creatProductResponseInfo?.data?.description + "",
        },
        theme: {
          color: "#2300a3",
        },
      };
      var razorpayObject = new window.Razorpay(options);

      razorpayObject.on("payment.failed", function (response) {
        message.error("Payment Failed");
        failedOrder({ order_id: creatProductResponseInfo?.data?.order_id });
      });

      razorpayObject.open();
    }
  }, [creatProductResponseInfo]);
  return (
    <div>
      <BrowserRouter>
        {/* NAVBAR */}
        {/* <button onClick={toggleNavbar}>Toggle Navbar</button> */}
        {admin_user && adminToken ? <SideAdmin /> : null}
        {isAuthenticated && userToken && user ? <NavbarUser /> : null}
        {!admin_user &&
        !adminToken &&
        !isAuthenticated &&
        !userToken &&
        !user ? (
          <Navbar />
        ) : null}
        {/* <div
          onClick={() =>
            createProduct([
              {
                _id: "65e82a32a6c126bbcf1629d6",
                user_id: "65e02992f67259dc0d86ad91",
                product_id: "65e6e485ebc80f819e8035de",
                quantity: 10,
                color: "Grey",
                __v: 0,
                product: {
                  _id: "65e6e485ebc80f819e8035de",
                  name: "Ergonomic Office Chair x",
                  category: "Office Furniture",
                  color: ["Black", "Gray"],
                  wheels: true,
                  special: false,
                  image: "office-chair.jpg",
                  multi_img: [],
                  seoArray: ["office chair", "ergonomic chair", "desk chair"],
                  suitableFor: "Office use",
                  stage: "In Stock",
                  price: 200,
                  discount: 10,
                  moreFeatures: [],
                  __v: 0,
                  instock: true,
                  active: false,
                },
              },
              {
                _id: "65e83526c881dd20c21b6e70",
                user_id: "65e02992f67259dc0d86ad91",
                product_id: "65e6e485ebc80f819e8035de",
                quantity: 1,
                color: "Gray",
                __v: 0,
                product: {
                  _id: "65e6e485ebc80f819e8035de",
                  name: "Ergonomic Office Chair x",
                  category: "Office Furniture",
                  color: ["Black", "Gray"],
                  wheels: true,
                  special: false,
                  image: "office-chair.jpg",
                  multi_img: [],
                  seoArray: ["office chair", "ergonomic chair", "desk chair"],
                  suitableFor: "Office use",
                  stage: "In Stock",
                  price: 200,
                  discount: 10,
                  moreFeatures: [],
                  __v: 0,
                  instock: true,
                  active: false,
                },
              },
              {
                _id: "65e8409ce3ccb5f39c21b732",
                user_id: "65e02992f67259dc0d86ad91",
                product_id: "65e6e485ebc80f819e8035de",
                quantity: 1,
                color: "Black",
                __v: 0,
                product: {
                  _id: "65e6e485ebc80f819e8035de",
                  name: "Ergonomic Office Chair x",
                  category: "Office Furniture",
                  color: ["Black", "Gray"],
                  wheels: true,
                  special: false,
                  image: "office-chair.jpg",
                  multi_img: [],
                  seoArray: ["office chair", "ergonomic chair", "desk chair"],
                  suitableFor: "Office use",
                  stage: "In Stock",
                  price: 200,
                  discount: 10,
                  moreFeatures: [],
                  __v: 0,
                  instock: true,
                  active: false,
                },
              },
            ])
          }
        >
          Pay
        </div> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<FormPage />} />
          <Route
            path="/admin"
            element={
              <AdminAuth>
                <AdminDashboard />
              </AdminAuth>
            }
          />

          <Route path="/admin/user-table" element={<UserTable />} />
          <Route path="/admin/staff-table" element={
          <AdminAuth>

          <Staff />
          </AdminAuth>
          } />
          <Route
            path="/admin/coupon"
            element={
              <AdminAuth>
                <Coupon />
              </AdminAuth>
            }
          />
          <Route
            path="/admin/enquiries"
            element={
              // <AdminAuth>
                <EnquiryAdmin />
              // </AdminAuth>
            }
          />
          {/* Add more routes for other admin components as needed */}
          <Route
            path="/admin/products"
            element={
              <AdminAuth>
                <Admin_products />
              </AdminAuth>
            }
          />
          <Route
            path="/admin/products/create"
            element={
              <AdminAuth>
                <MainProduct />
              </AdminAuth>
            }
          />
          <Route
            path="/admin/products/create/:id"
            element={
              <AdminAuth>
                <MainProduct />
              </AdminAuth>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <AdminAuth>
                <OrderAdmin />
              </AdminAuth>
            }
          />
            <Route
            path="/forgetpass"
            element={
                <ForgotPass/>
            }
          />

          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/shop-by-category" element={<ShopByCategoryPage />} />
          <Route path="/product-page" element={<ProductPage />} />
          <Route
            path="/product-details-page"
            element={<ProductDetailsPage />}
          />
          <Route
            path="/product-details-page/:id"
            element={<ProductDetailsPage />}
          />
          <Route
            path="/cart-page"
            element={
              <Auth>
                <CartPage />
              </Auth>
            }
          />
          <Route path="/address-page" element={<AddressPage />} />
          <Route path="/payment-page" element={<PaymentPage />} />
          <Route
            path="/profile-page"
            element={
              <Auth>
                <ProfilePage />
              </Auth>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>

        {/* FOOTER */}
        <Footersue />
      </BrowserRouter>
    </div>
  );
}

export default App;

//color
//price
//base (wooden,wheel)
//mech (tenting and push back)
//arms (fixed or adjustable)
