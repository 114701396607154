import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { useCreateContactMutation } from '../../../store/store';

const InputSideWrapper = styled(Form)`
  padding-bottom: 100px;
  position: relative;
  padding: 10px 10px 100px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled(Form.Item)`
  width: 100%;
  margin-right: 1rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const CustomInput = styled(Input)`
  color: #333;
  font-size: 15px;
  padding: 8px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
`;

const CustomTextArea = styled(Input.TextArea)`
  width: 100%;
  color: #333;
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  outline: 0px transparent !important;
`;

const SubMitButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 12px 25px 12px 24px;
  background-color: #F6B51B;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ContactForm = ({ Perform_cancel, userData }) => {
  const [form] = Form.useForm();
  const [createContact, creatContactResponseInfo] = useCreateContactMutation();

  const onFinish = (values) => {
    console.log('Received values:', values);
    createContact(values);
  };

  useEffect(() => {
    if (creatContactResponseInfo.isSuccess) {
      message.success("You have submitted your enquiry successfully!");
      form.resetFields();
      // Perform_cancel();
    }
    if (creatContactResponseInfo.isError) {
      message.error("You don't have clearance to submit.");
    }
  }, [creatContactResponseInfo, Perform_cancel, form]);

  return (
    <InputSideWrapper form={form} onFinish={onFinish}>
      <InputWrapper label="Name" name="name" rules={[{ required: true, message: 'Please enter your Name' }]}>
        <CustomInput placeholder="Please, enter your Name" />
      </InputWrapper>
      <InputWrapper label="Email" name="email" style={{ marginLeft: "10px" }}>
        <CustomInput type="email" placeholder="Please, enter your email address" />
      </InputWrapper>
      <InputWrapper label="Phone" name="phone" rules={[{ required: true, message: 'Please enter your phone number' }]}>
        <CustomInput type="number" placeholder="Please enter your phone number" />
      </InputWrapper>
      <InputWrapper label="Message" name="message" rules={[{ required: true, message: 'Please write your message' }]}>
        <CustomTextArea placeholder="Write your message" />
      </InputWrapper>
      <SubMitButton type="primary" htmlType="submit">Send Message</SubMitButton>
    </InputSideWrapper>
  );
};

export default ContactForm;
