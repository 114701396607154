import React, { useEffect, useState } from "react";
import { Button, Form, Input, Popconfirm, Radio, Select, Switch, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FormItem from "antd/es/form/FormItem";
import { Option } from "antd/es/mentions";
import { Order_page } from "../../../store/mutation/remainingSlice";
import { order_state, paymentArray, paymentDoneArray } from "../../../atoms/static/State";
import {
  useDeleteOrderForUserMutation,
  useFetchAdminOrderQuery,
  useUpdateOrderStageMutation,
} from "../../../store/store";
import ETable from "../../../components/table/ETable";
import { CloseOutlined } from "@ant-design/icons";
import OrderProductTable from "../../../components/organisms/ProfilePage_Components/orderBox/OrderProductTable";

const OrderModel = ({ performCancel, data }) => {
  return (
    <div className="model-con">
      <div className="model-box" style={{ height: "90%" }}>
        <p className="p--cross--custom" onClick={() => performCancel()}>
          <CloseOutlined />
        </p>
        <div style={{ height: "100%", overflow: "auto" }}>
          <OrderProductTable data={data} />
        </div>
      </div>
    </div>
  );
};
const OrderAdmin = () => {
  const dispatch = useDispatch();
  const { shipRocketToken } =useSelector((state) => state.user);
  
  const updateDATA = (data) => {
    console.log(data);
    if (data?.go_to_shiprocket===true) {
      data.go_to_shiprocket = true;
      data.shipRocketToken = shipRocketToken;
      console.log("shiprockrt")
      updateUser(data);
    } else {
      updateUser(data);
    }
    setShow(false);
    setSelected_Data();
  };

  const Order_Update = () => {
    return (
      <div className="model-main-login">
        <div className="model-main-box-login" style={{height:"600px"}}>
          <Form
            initialValues={Selected_Data}
            onFinish={(data) => updateDATA(data, Selected_Data)}
          >
            <FormItem name={"order_id"}>
              <Input disabled style={{ color: "black" }} />
            </FormItem>
            <FormItem name="go_to_shiprocket" label={"Send to Ship Rocket"}>
              <Switch  style={{ color: "black" }} />
            </FormItem>
            <FormItem name={"length"}>
              <Input type="number" required placeholder="Enter Lenght" />
            </FormItem>
            <FormItem name={"breadth"}>
              <Input type="number" required placeholder="Enter Breadth" />
            </FormItem>
            <FormItem name={"height"}>
              <Input type="number" required placeholder="Enter Height" />
            </FormItem>
            <FormItem name={"weight"}>
              <Input type="number" required placeholder="Enter Weight" />
            </FormItem>

            <FormItem name={"stage"}>
              <Select>
                {order_state.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem name={"payment"}>
              <Select>
                {paymentDoneArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem>
              <Button htmlType="SUBMIT" type="primary">
                Submit
              </Button>
            </FormItem>
            <Button danger onClick={() => setShow(false)}>
              Cancel
            </Button>
          </Form>
        </div>
      </div>
    );
  };

  const [
    deleteOrder,
    deleteOrderResponseInfo,
  ] = useDeleteOrderForUserMutation();

  const deletefun = (e, record) => {
    e.stopPropagation();
    console.log(record);
    // deleteOrder(record)
  };
  const { order_page } = useSelector((state) => state.remaning);

  const [updateUser, updateUserResponseInfo] = useUpdateOrderStageMutation();
  useEffect(() => {
    if (updateUserResponseInfo.isSuccess) {
      message.success("User Updated");
    }
  }, [updateUserResponseInfo]);
  useEffect(() => {
    if (deleteOrderResponseInfo.isSuccess) {
      message.success("Order Deleted");
    }
  }, [deleteOrderResponseInfo]);
  const toggleActive = (data, e, record) => {
    e.stopPropagation();
    const _id = record._id;
    const new_obj = { _id: _id, active: data };
    console.log(new_obj);
    //   updateUser(new_obj)
  };
  const [orderSTATE, setOrderSTATE] = useState("");
  const [paymentSTATE, setPaymentSTATE] = useState("");
  const [allFILTER, setAllFILTER] = useState("");
  const sort = -1;
  const {
    data: data,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useFetchAdminOrderQuery({ order_page, sort, orderSTATE, paymentSTATE });
  const columns = [
    {
      title: "Sr.no",
      dataIndex: "_id",
      key: "_id",
      width: 70,
      render: (text, record, index) => {
        return <span>{order_page * 10 - 10 + index + 1}</span>;
      },
      //   ...getColumnSearchProps('name'),
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "_id",
      width: 150,
    },
    // {
    //   title: "User Id",
    //   dataIndex: "user_id",
    //   key: "user_id",
    //   width: "10%",
    // },

    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "20%",
      render: (date) => {
        const indianDate = new Date(date).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
        return indianDate;
      },
    },
    {
      title: "Order State",
      dataIndex: "stage",
      key: "stage",
      width: "20%",
    },
    {
      title: "Payment Status",
      dataIndex: "payment",
      key: "payment",
      width: "20%",
    },

    {
      title: "Shipping Address",
      dataIndex: "shipping_address",
      key: "_id",
      width: "20%",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: "20%",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(data, e) => toggleActive(data, e, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: 100,

      render: (record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setProductGet(record);
            setShowModel(true);
          }}
          type="primary"
        >
          View
        </Button>
      ),
    },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: 100,

      render: (record) => (
        <Popconfirm
          title="Sure to delete?"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          onConfirm={(e) => deletefun(e, record)}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const [show, setShow] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [Selected_Data, setSelected_Data] = useState();
  const visit = (data) => {
    console.log(data);
    setSelected_Data(data);
    setShow(true);
  };

  useEffect(() => {
    if (orderSTATE) {
      setAllFILTER(orderSTATE);
      console.log(orderSTATE);
    }
  }, [orderSTATE]);
  const selectValue = (data) => {
    dispatch(Order_page(1));
    if (data === "ALL") {
      setOrderSTATE("");
    } else {
      setOrderSTATE(`&stage=${data}`);
    }
  };
  const selectPayment = (data) => {
    dispatch(Order_page(1));
    if (data === "ALL") {
      setPaymentSTATE("");
    } else {
      setPaymentSTATE(`&payment=${data}`);
    }
  };
  const [showModel, setShowModel] = useState(false);
  const performCancel = () => {
    setShowModel(false);

    setSelected_Data();
  };
  const [productGet, setProductGet] = useState();
  return (
    <div className="body-width-admin">
      <h1
        style={{ textAlign: "center", marginTop: "50px", marginBottom: "20px" }}
      >
        Order Table
      </h1>
      <div style={{ display: "flex", margin: "6px 6px", padding: "0 6rem" }}>
        <FormItem name={"Order State"} label={"Order Stage"}>
          <Select
            onSelect={selectValue}
            defaultValue={"ALL"}
            style={{ width: "150px" }}
          >
            {order_state.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          name={"Payment Status"}
          style={{ marginLeft: "20px" }}
          label={"Payment Status"}
        >
          <Select
            onSelect={selectPayment}
            defaultValue={"ALL"}
            style={{ width: "150px" }}
          >
            {paymentArray.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </FormItem>
      </div>
      <div style={{ margin: "6px 6px", padding: "0 6rem" }}>
        <ETable
          page_size={10}
          columns={columns}
          data={data}
          loading={loading || updateUserResponseInfo.isLoading}
          user_page={order_page}
          visit={visit}
          page100={"order"}
        />
      </div>
      {show ? <Order_Update /> : null}
      {showModel ? (
        <OrderModel performCancel={performCancel} data={productGet} />
      ) : null}
    </div>
  );
};

export default OrderAdmin;
