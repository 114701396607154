import React from 'react'
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const DirectionAddressPage = () => {
  return (
    <>
        <div className='product-page-link-container'>
            <Link to='/address-page' className='direction-product-page-link'>
                <FaArrowLeft/>
                <h1>Address</h1>
            </Link>
        </div>  

        <div className='CART-address-payment-container'>
            <span>Cart</span>
            <div className='connectivity-icon'>
                <img src='/straightline.png' className='straight-line-horizontal' />
                <img src='/circlebro.png' />
            </div>
            <span>Address</span>
            <div className='connectivity-icon'>
                <img src='/straightline.png' className='straight-line-horizontal' />
                <img src='/circlebro.png' />
            </div>
            Payment 
        </div>
    </>    
    )
}

export default DirectionAddressPage
