import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

import { url } from "./url";
import { Login_show } from "./remainingSlice";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ email, password }, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${url}/auth/login/`,
        { email, password },
        config
      );
      // store user's token in local storage
      const token = data.userToken;
      console.log(userToken);
      const { dispatch } = thunkAPI;
      localStorage.setItem("userToken", data.userToken);
      message.success("User Logged In");
      dispatch(getUser());
      dispatch(Login_show(false));

      return { token };
    } catch (err) {
      // return custom error message from API if any
      message.error("Invalid Credentials");
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const adminLogin = createAsyncThunk(
  "admin/login",
  async ({ email, password }, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${url}/auth/admin/login`,
        { email, password },
        config
      );
      // store user's token in local storage
      const token = data.userToken;
      const shiprocket = data.shipRocketToken;
      console.log(userToken);
      const { dispatch } = thunkAPI;
      localStorage.setItem("adminToken", data.userToken);
      localStorage.setItem("shipRocketToken", data.shipRocketToken);
      message.success("Admin Logged In");
      dispatch(getAdmin());
      return { token,shiprocket };
    } catch (err) {
      // return custom error message from API if any
      message.error("Invalid Credentials");
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const userLogin_Google = createAsyncThunk(
  "user/login",
  async (GToken, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${url}/auth/google/login`,
        { Gtoken: GToken },
        config
      );
      // store user's token in local storage
      const token = data.userToken;
      console.log(userToken);
      const { dispatch } = thunkAPI;
      localStorage.setItem("userToken", data.userToken);
      message.success("User Logged In");
      dispatch(getUser());
      dispatch(Login_show(false));

      return { token };
    } catch (err) {
      // return custom error message from API if any
      message.error("Invalid Credentials");
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

//user get as well as verify
export const getUser = createAsyncThunk(
  "api/account/me",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${url}/auth/verify`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      const data = await res.json();

      localStorage.setItem("user", JSON.stringify({ ...data }));
      console.log("getuser", data);
      if (res.status === 200) {
        return { ...data };
      } else {
        if (data.message === "Blocked") {
          message.error("You Are Blocked");
        }
        localStorage.removeItem("userToken");

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      localStorage.removeItem("userToken");

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getAdmin = createAsyncThunk(
  "api/account/admin",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${url}/auth/verify/admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      });
      const data = await res.json();

      localStorage.setItem("admin_user", JSON.stringify({ ...data }));
      if (res.status === 200) {
        return { ...data };
      } else {
        localStorage.removeItem("admin_user");
        localStorage.removeItem("adminToken");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      localStorage.removeItem("admin_user");
      localStorage.removeItem("adminToken");

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
// export const Product_page = createAsyncThunk(
//   "product_page",
//   async (username, thunkAPI) => {
//     try {
//       // configure header's Content-Type as JSON

//       console.log("product_page", username);
//       return username;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );
const product_page = "product_page";

export const Product_page = (newPage) => {
  return {
    type: product_page,
    payload: newPage,
  };
};

// initialize userToken from local storage
const userToken = localStorage.getItem("userToken", "role")
  ? localStorage.getItem("userToken", "role")
  : null;

const initialState = {
  loading: true,
  admin_loading: true,
  user: null,
  admin_user: null,
  userToken: localStorage.getItem("userToken"),
  shipRocketToken: localStorage.getItem("shipRocketToken"),
  adminToken: localStorage.getItem("adminToken"),
  admin: false,
  isAuthenticated: false,
  error: null,
  loginStatus: "",
  loginError: "",
  product_page: 1,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.removeItem("userToken");
      localStorage.removeItem("adminToken");

      return {
        ...state,
        userToken: "",
        shipRocketToken: "",
        adminToken: "",
        user: "",
        admin_user: "",
        loading: false,
        admin_loading: false,
        isAuthenticated: false,
        product_page: 1,
        admin: false,
      };
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.userToken = payload.token;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.isAuthenticated = false;
        state.userToken = "";
      })

      .addCase(adminLogin.pending, (state, action) => {
        state.admin_loading = true;
      })
      .addCase(adminLogin.fulfilled, (state, { payload }) => {
        state.admin_loading = false;
        state.adminToken = payload.token;
        state.shipRocketToken = payload.shiprocket;
        state.admin = true;
      })
      .addCase(adminLogin.rejected, (state, { payload }) => {
        state.admin_loading = false;
        state.error = payload;
        state.adminToken = "";
        state.admin = false;
      })

      //   get user
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload;

        // state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.userToken = "";

        state = action.payload;
      })
      .addCase(getAdmin.pending, (state) => {
        state.admin_loading = true;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.admin_loading = false;
        state.admin = true;
        state.admin_user = action.payload;

        // state.user = action.payload;
      })
      .addCase(getAdmin.rejected, (state, action) => {
        state.admin_loading = false;
        state.adminToken = "";
        state.admin = false;
        state = action.payload;
      })

      .addCase(product_page, (state, action) => {
        state.product_page = action.payload;
      });
  },
});
// export default userSlice.reducer;

export const { logout } = userSlice.actions;

// export default userSlice.reducer;
export default userSlice.reducer;
