import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import AddToCart_button from "./AddToCart_button";
import SignUp_button from "./SignUp_button";
import { Button, Form, Input, message } from "antd";
import Hr from "../../../atoms/buttons/Hr";
import { Login_show } from "../../../store/mutation/remainingSlice";
import { userLogin, userLogin_Google } from "../../../store/mutation/userSlice";
import { MdClose } from "react-icons/md";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useCreateUserMutation, useForgotMailMutation } from "../../../store/store";
import logo from "./../../../assets/sueLogo.png"

const LoginUser = (data, dispatch, userLogin) => {
  console.log(data);
  dispatch(userLogin(data));
};
const ForgetUser = (data, dispatch, userLogin,forgetUser) => {
  console.log(data);
  message.success("If You Have Account on Kalasparsh Mail Has Been Sent")
  // dispatch(userLogin(data));
  forgetUser(data)
};
const RegisterUser = (data, createUser) => {
  console.log(data);
  const { password, cpassword, ...data1 } = data;
  if (password !== cpassword) {
    message.error("Password Dont Match");
  } else {
    createUser(data);
  }
};

function Navbar() {
  const [click, setclick] = useState(false);

  const closeMobileMenu = () => setclick(false);
  const handleClick = () => setclick(!click);

  const navigate = useNavigate();

  const closeNavMenu = () => {
    setclick(false);
  };

  const {
    userToken,
    loading,
    checkAuthLoading,
    isAuthenticated,
    admin,
    adminToken,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [show_login_box, setShow_login_box] = useState(true);

  let authLinks;

  const [user, setUser] = useState();
  const {  login_show} = useSelector(
    (state) => state.remaning
  );
 

  const Login = () => {
    const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);

    const handleCallBack = (res) => {
      console.log(res.credential);
      dispatch(userLogin_Google(res.credential));
      var userobj = jwtDecode(res.credential);
      console.log(userobj);
    };
    const google = window.google;
const [forgetPass, setForgetPass] = useState(false);
    useEffect(() => {
      const handleScriptLoad = () => {
        google?.accounts?.id?.initialize({
          client_id:
            "287107085331-o3tpuma26c2et6chg7vep6h259erkv22.apps.googleusercontent.com",
          callback: handleCallBack,
        });

        setIsGoogleApiLoaded(true);
      };

      // Load the Google Identity Services library script
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = handleScriptLoad;
      document.body.appendChild(script);
    }, []);

    useEffect(() => {
      if (isGoogleApiLoaded) {
        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
          theme: "outline",
          size: "large",
        });
      }
    }, [isGoogleApiLoaded]);

    const [createUser, creatUserResponseInfo] = useCreateUserMutation();
    const [forgetUser, forgetUserResponseInfo] = useForgotMailMutation();

    useEffect(() => {
      if (creatUserResponseInfo?.isSuccess === true) {
        message.success("Register Successfull");
        setShow_login_box(true);
      }
      //  if(creatQuotationResponseInfo?)
    }, [creatUserResponseInfo]);
    useEffect(() => {
      if (forgetUserResponseInfo?.isSuccess === true) {
        message.success("Mail Sent");
        // setShow_login_box(true);
      }
      //  if(creatQuotationResponseInfo?)
    }, [forgetUserResponseInfo]);

    return (
      <div className="model-main-login">
        <div className="model-main-box-login">
          <div
            style={{ position: "absolute", right: "10px", cursor: "pointer" }}
            onClick={() => {
              dispatch(Login_show(false));
            }}
          >
            <MdClose />
          </div>
          <img className="my-logo" src={logo} alt="" />
          <Hr />
          <p style={{ textAlign: "center" }}>
            {show_login_box ? <>Login To</> : <>Register To</>} Shoping System
          </p>
          {forgetPass?<> <Form
              style={{ marginTop: "30px" }}
              onFinish={(data) => ForgetUser(data, dispatch, userLogin,forgetUser)}
            >
              <Form.Item name="email">
                <Input
                  required
                  className="input-login-google"
                  placeholder="Enter Your Gmail"
                />
              </Form.Item>
            
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    background: "var(--pr-text-color)",
                    color: "white",
                  }}
                >
                  Send Mail
                </Button>
             
              
              </Form.Item>
            </Form></>:


          <>
          {!show_login_box ? (
            <Form
              style={{ marginTop: "30px" }}
              onFinish={(data) =>
                RegisterUser(data, createUser, dispatch, userLogin)
              }
            >
              <Form.Item name="name">
                <Input
                  required
                  className="input-login-google"
                  placeholder="Enter Your Name"
                />
              </Form.Item>
              <Form.Item name="email">
                <Input
                  required
                  className="input-login-google"
                  placeholder="Enter Your Gmail"
                />
              </Form.Item>

              <Form.Item name="password">
                <Input
                  required
                  type="password"
                  className="input-login-google"
                  placeholder="Enter Your Password"
                />
              </Form.Item>
              <Form.Item name="cpassword">
                <Input
                  required
                  type="password"
                  className="input-login-google"
                  placeholder="Confirm Your Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  loading={creatUserResponseInfo.isLoading}
                  htmlType="submit"
                  style={{
                    width: "100%",
                    background: "var(--pr-text-color)",
                    color: "white",
                  }}
                >
                  Register
                </Button>
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span
                    onClick={() => setShow_login_box(true)}
                    style={{
                      fontSize: "12px",
                      color: "#4297ca",
                      cursor: "pointer",
                    }}
                  >
                    Already have an account
                  </span>
                  
                </p>
              </Form.Item>
            </Form>
          ) : (
            <Form
              style={{ marginTop: "30px" }}
              onFinish={(data) => LoginUser(data, dispatch, userLogin)}
            >
              <Form.Item name="email">
                <Input
                  required
                  className="input-login-google"
                  placeholder="Enter Your Gmail"
                />
              </Form.Item>
              <Form.Item name="password">
                <Input
                  required
                  type="password"
                  className="input-login-google"
                  placeholder="Enter Your Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: "100%",
                    background: "var(--pr-text-color)",
                    color: "white",
                  }}
                >
                  Login
                </Button>
                <p style={{ display: "flex", justifyContent:"space-between" }}>
                  <span
                    onClick={() => setShow_login_box(false)}
                    style={{
                      fontSize: "12px",
                      color: "#4297ca",
                      cursor: "pointer",
                    }}
                  >
                    Create an account
                  </span>
                  <span
                    onClick={() => setForgetPass(!forgetPass)}
                    style={{
                      fontSize: "12px",
                      color: "#4297ca",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password
                  </span>
                </p>
                <p style={{ display: "flex", justifyContent: "flex-end" }}>
                
                </p>
              </Form.Item>
            </Form>
          )}
          </>
          }

          <div id="signInDiv"></div>
        </div>
      </div>
    );
  };
  return (
    <header className="header-sue">
      <div onClick={closeMobileMenu}>
        <img className="my-logo" src="/sueLogo.png" alt="" />
      </div>
      <nav className="navbar">
        <div className="menu-icon" onClick={handleClick}>
          <div className={click ? "fas fa-times" : "fas fa-bars"} onClick={closeNavMenu}/>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/shop-by-category"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Shop by Category
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/product-page"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Products
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              to="/gallery"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Gallery
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact
            </NavLink>
          </li>

          <li className="nav-item" onClick={()=>dispatch(Login_show(true))}>
            <NavLink
              onClick={()=>{
                closeMobileMenu()
                dispatch(Login_show(true))
                }}
              className="nav-links-mobile"
            //   onClick={closeMobileMenu}
            >
              Sign Up
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/cart-page"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Cart
            </NavLink>
          </li>
        </ul>
      </nav>
      {login_show ? <Login /> : null}
      <div className="buttons">
        <SignUp_button />
        <AddToCart_button />
      </div>
    </header>
  );
}

export default Navbar;
