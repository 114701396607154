import React, { useEffect, useState } from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { useDeleteContactMutation, useFetchContactQuery } from '../../../store/store';
import { Button, Popconfirm } from 'antd';
import EnquiryTable from './EnquiryTable';
import MainEnquiry from './form/MainEnquiry';


const Model = ({ Perform_cancel, userData }) => {
    return (
        <div className="model-con">
            <div className="model-box" style={{ height: "50%" }}>
                <p className="p--cross--custom" onClick={() => Perform_cancel()}>
                    <CloseOutlined />
                </p>
                <div style={{ height: "100%", overflow: "auto" }}>
                    {/* <OrderProductTable data={data} /> */}
                    <MainEnquiry data={userData} Perform_cancel={Perform_cancel} />
                </div>
            </div>
        </div>
    );
};

const EnquiryAdmin = () => {

    const [show, setShow] = useState(false);
    const [userData, setUserData] = useState(null);
    const Perform_cancel = () => {
        setShow(false);
        setUserData(null);
    };
    const [deleteStaff, deleteStaffResponseInfo] =
        useDeleteContactMutation();

    const {
        data: contact,
        isLoading: loading,
        error: error,
    } = useFetchContactQuery();
    useEffect(() => {
        if (contact) {
            console.log(contact);
        }
    }, [contact]);
    const deletefun = (e, data) => {
        e.stopPropagation();
        console.log(data)
        deleteStaff(data)
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "id",
            key: "id",
            width: "10%",
            render: (_, record, index) => <>{index + 1}</>,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "_id",
            width: "20%",
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "_id",
            width: "20%",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "_id",
            width: "20%",
        },
        {
            title: "Mobile No.",
            dataIndex: "phone",
            key: "_id",
            width: "20%",
        },
        {
            title: "Action",
            key: "_id",
            fixed: "right",
            width: "10%",

            render: (record) => (
                <Button
                    className="edit-link"
                    type="primary"
                // onClick={(e) => editfun(e, record)}
                >
                    Edit
                </Button>
            ),
        },
        {
            title: "Action",
            key: "_id",
            fixed: "right",
            width: "10%",

            render: (record) => (
                <Popconfirm title="Sure to delete?" onCancel={(e) => { e.stopPropagation() }} onConfirm={(e) => deletefun(e, record)}>

                    <Button onClick={(e) => { e.stopPropagation() }} style={{ color: "red" }}>Delete</Button>
                </Popconfirm>
            ),
        },
    ];
    const get_data = (data) => {
        console.log(data);

        setUserData(data);
        setShow(true);
    };
    const handlecreate = () => {
        // navigate("/admin/staff/form");
        setShow(true);
    };

    return (
        <>
            <div className="body-width-admin">
                <h1
                    style={{ textAlign: "center", marginTop: "50px", marginBottom: "20px" }}
                >
                    Enquiry Table
                </h1>

                <Button
                    onClick={() => handlecreate()}
                    style={{ marginBottom: "20px" }}
                    className="universal-button-atom"
                >
                    CREATE Enquiry
                </Button>
                <EnquiryTable
                    columns={columns}
                    data={contact}
                    loading={loading}
                    get_data={get_data}
                />
                {show ? <Model userData={userData} Perform_cancel={Perform_cancel} /> : null}

            </div>
        </>
    );
};

export default EnquiryAdmin;