import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { NavLink, Link, Form } from "react-router-dom";
import { PiCircleThin } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch } from "react-icons/fa";

import { FaStar } from "react-icons/fa";
import CommonQuote from "../Product_DetailsPage_Components/CommonQuote";
import { Button, Input, Pagination, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Hr from "../../../atoms/buttons/Hr";
import { userLogin, userLogin_Google } from "../../../store/mutation/userSlice";
import { MdClose } from "react-icons/md";
import {
  useCreateUserMutation,
  useFetchProductQuery,
} from "../../../store/store";
import {
  Admin_product_name,
  FilterString,
  Login_show,
  Product_page,
} from "../../../store/mutation/remainingSlice";
import { jwtDecode } from "jwt-decode";
import { DiscountFun, createSlug } from "../../../atoms/static/StateFun";
import LoadingHv from "../../../atoms/buttons/LoadingHv";
import { categoryArray, forCheckValue, makeItArray, makeToFilterString } from "../../../atoms/static/State";
const LoginUser = (data, dispatch, userLogin) => {
  dispatch(userLogin(data));
};
const RegisterUser = (data, createUser) => {
  const { password, cpassword, ...data1 } = data;
  if (password !== cpassword) {
    message.error("Password Dont Match");
  } else {
    createUser(data);
  }
};
const SideBar = () => {
  const {
    userToken,
    loading,
    checkAuthLoading,
    isAuthenticated,
    admin,
    adminToken,
  } = useSelector((state) => state.user);
  const { product_page,filter_string ,product_name} = useSelector((state) => state.remaning);
  console.log("lion",filter_string)
  const dispatch = useDispatch();

  const [show_login_box, setShow_login_box] = useState(true);
  const {
    data: products,
    isLoading: product_loading,
    isFetching: fetch,
    error: error,
  } = useFetchProductQuery({ page: product_page,filter:filter_string ,product_name});

  let authLinks;

  const [user, setUser] = useState();
  const [selectedOptions, setSelectedOptions] = useState(makeItArray(filter_string));
  useEffect(() => {
if(selectedOptions){
    dispatch(FilterString(makeToFilterString(selectedOptions)))
}
  }, [selectedOptions]);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showMoreColors, setShowMoreColors] = useState(false);
  const [showMoreMaterials, setShowMoreMaterials] = useState(false);
  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleDeselectOption = (option) => {
    // Remove the selected option from the list of selected options
    const updatedSelectedOptions = selectedOptions.filter(
      (item) => item !== option
    );
    setSelectedOptions(updatedSelectedOptions);

    // Remove the selected option from the list of checked options
    const updatedCheckedOptions = { ...checkedOptions };
    delete updatedCheckedOptions[option];
    setCheckedOptions(updatedCheckedOptions);
  };

  const handlePageChange = (data) => {
    dispatch(Product_page(data));
  };

  const handleClearAll = () => {
    // Clear selected filters list
    setSelectedOptions([]);

    // Uncheck all checkboxes
    const updatedCheckedOptions = {};
    setCheckedOptions(updatedCheckedOptions);
  };

  const handleToggleColors = () => {
    setShowMoreColors(!showMoreColors);
  };

  const handleToggleMaterials = () => {
    setShowMoreMaterials(!showMoreMaterials);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [checkedOptions, setCheckedOptions] = useState(forCheckValue(makeItArray(filter_string)));
  console.log(checkedOptions)
  console.log(selectedOptions)
  const handleCheckboxChange = (option) => {

    const updatedOptions = { ...checkedOptions };
    updatedOptions[option] = !updatedOptions[option];
    setCheckedOptions(updatedOptions);

    const nonCheckboxSelectedOptions = selectedOptions.filter(
      (item) => !Object.keys(checkedOptions).includes(item)
    );

    // Update selectedOptions state based on checkedOptions and nonCheckboxSelectedOptions
    const newSelectedOptions = Object.keys(updatedOptions)
      .filter((key) => updatedOptions[key])
      .concat(nonCheckboxSelectedOptions);

    setSelectedOptions(newSelectedOptions);
  };

  const handleRadioButtonChange = (option) => {
    // Check if the selected option is from the price category
    const isPriceOption = option.startsWith("Under Rs.") || option.startsWith("Rs.") || option.startsWith("Over");
  
    // Check if the selected option is from the discount category
    const isDiscountOption = option.startsWith("10%") || 
                             option.startsWith("20%") ||
                             option.startsWith("30%") ||
                             option.startsWith("40%");
  
    let updatedOptions;
  
    // Clear previously selected options from the same category
    if (isPriceOption) {
      updatedOptions = selectedOptions.filter(
        (item) => !item.startsWith("Under Rs.") && !item.startsWith("Rs.") && !item.startsWith("Over")
      );
    } else if (isDiscountOption) {
      updatedOptions = selectedOptions.filter(
        (item) => !item.startsWith("10%") &&
                  !item.startsWith("20%") &&
                  !item.startsWith("30%") &&
                  !item.startsWith("40%")
      );
    }
  
    // Add the new selected option
    setSelectedOptions([...updatedOptions, option]);
  };
  // const handleRadioButtonChange = (option) => {
  //   // Clear previously selected options from the same category
  //   const updatedOptions = selectedOptions.filter(
  //     (item) => !item.startsWith("10% Off or more") &&
  //               !item.startsWith("20% Off or more") &&
  //               !item.startsWith("30% Off or more") &&
  //               !item.startsWith("40% Off or more")
  //   );
  //   // Add the new selected option
  //   setSelectedOptions([...updatedOptions, option]);
  // };
  useEffect(() => {
    
 if(window.innerWidth<500){
  setShowSidebar(false)
 }
  }, [window.innerWidth]);
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="product-page-container">
        <div className="left-side-product-page">
          <div className="sort-by-featured-container">
            Sort By: Featured <IoIosArrowDown />
          </div>
          <div className={`sidebar-container ${showSidebar ? "show" : "not-show"}`}>
            <nav  className={`sidebar ${showSidebar ? "show" : "not-show"}`}>
              <div className="selected-options">
                <div className="selected-options-header">
                  Selected Filters ({selectedOptions.length || 0})
                  {selectedOptions.length > 0 && (
                    <button
                      className="clear-all-button"
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                  )}
                </div>
                {selectedOptions.map((option) => (
                  <div key={option} className="selected-option">
                    {option}
                    <span
                      className="deselect-option"
                      onClick={() => handleDeselectOption(option)}
                    >
                      ✕
                    </span>
                  </div>
                ))}
              </div>
              <ul className="sidebar-options">
                <li className="options">
                  <div className="filter-heading" style={{padding: '2rem'}}>Categories</div>
                  <div className="filter-options">
                    {categoryArray.map((x) => (
                      <div
                        className={`option ${
                          selectedOptions.includes(x)
                            ? "option-selected"
                            : ""
                        }
                        `}
                        onClick={() => handleOptionClick(x)}
          
                      >
                        {x}
                      </div>
                    ))}
                  </div>
                </li>
                <li className="options">
                  <div className="filter-heading">Price</div>
                  <div className="filter-options">
                    <label className="option">
                      <input
                      
                        type="radio"
                        name="price"
                        onChange={() => handleRadioButtonChange("Under Rs.1000")}
                        checked={selectedOptions.includes("Under Rs.1000")  || false}
                      />
                      Under Rs.1000
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="price"
                        onChange={() => handleRadioButtonChange("Rs.1000-2000")}
                        checked={selectedOptions.includes("Rs.1000-2000")  || false}
                      />
                      Rs.1000-2000
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="price"
                        onChange={() => handleRadioButtonChange("Rs.2000-3000")}
                        checked={selectedOptions.includes("Rs.2000-3000") || false}
                      />
                      Rs.2000-3000
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="price"
                        onChange={() => handleRadioButtonChange("Over Rs.3000")}
                        checked={selectedOptions.includes("Over Rs.3000") || false}
                      />
                      Over Rs.3000
                    </label>
                  </div>
                </li>
                <li className="options">
                  <div className="filter-heading">Colors</div>
                  <div className="filter-options">
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Black")}
                        checked={checkedOptions["Black"] || false}
                      />
                      <PiCircleThin className="black-color-circle" />
                      Black
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("White")}
                        checked={checkedOptions["White"] || false}
                      />
                      <PiCircleThin className="White-color-circle" />
                      White
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Grey")}
                        checked={checkedOptions["Grey"] || false}
                      />
                      <PiCircleThin className="grey-color-circle" />
                      Grey
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Brown")}
                        checked={checkedOptions["Brown"] || false}
                      />
                      <PiCircleThin className="brown-color-circle" />
                      Brown
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Blue")}
                        checked={checkedOptions["Blue"] || false}
                      />
                      <PiCircleThin className="blue-color-circle" />
                      Blue
                    </label>

                    {showMoreColors ? (
                      <>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Purple")}
                            checked={checkedOptions["Purple"] || false}
                          />
                          <PiCircleThin className="purple-color-circle" />
                          Purple
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Pink")}
                            checked={checkedOptions["Pink"] || false}
                          />
                          <PiCircleThin className="pink-color-circle" />
                          Pink
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Cyan")}
                            checked={checkedOptions["Cyan"] || false}
                          />
                          <PiCircleThin className="cyan-color-circle" />
                          Cyan
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Green")}
                            checked={checkedOptions["Green"] || false}
                          />
                          <PiCircleThin className="green-color-circle" />
                          Green
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Orange")}
                            checked={checkedOptions["Orange"] || false}
                          />
                          <PiCircleThin className="orange-color-circle" />
                          Orange
                        </label>
                      </>
                    ) : (
                      <div
                        className="show-more-option"
                        onClick={handleToggleColors}
                      >
                        +5 more <IoIosArrowDown />
                      </div>
                    )}
                  </div>
                </li>
                <li className="options">
                  <div className="filter-heading">Materials</div>
                  <div className="filter-options">
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Wood")}
                        checked={checkedOptions["Wood"] || false}
                      />
                      Wood
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Metal")}
                        checked={checkedOptions["Metal"] || false}
                      />
                      Metal
                    </label>
                    <label className="option">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange("Linen")}
                        checked={checkedOptions["Linen"] || false}
                      />
                      Linen
                    </label>
                    {showMoreMaterials ? (
                      <>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Cotton")}
                            checked={checkedOptions["Cotton"] || false}
                          />
                          Cotton
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Wool")}
                            checked={checkedOptions["Wool"] || false}
                          />
                          Wool
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Silk")}
                            checked={checkedOptions["Silk"] || false}
                          />
                          Silk
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Teracotta")}
                            checked={checkedOptions["Teracotta"] || false}
                          />
                          Teracotta
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Marble")}
                            checked={checkedOptions["Marble"] || false}
                          />
                          Marble
                        </label>
                        <label className="option">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange("Iron")}
                            checked={checkedOptions["Iron"] || false}
                          />
                          Iron
                        </label>
                      </>
                    ) : (
                      <div
                        className="show-more-option"
                        onClick={handleToggleMaterials}
                      >
                        +6 more <IoIosArrowDown />
                      </div>
                    )}
                  </div>
                </li>
                <li className="options">
                  <div className="filter-heading">Discount Range</div>
                  <div className="filter-options">
                    <label className="option">
                      <input
                        type="radio"
                        name="discount"
                        onChange={() => handleRadioButtonChange("10%")}
                        // checked={checkedOptions["10% Off or more"] || false}
                        checked={selectedOptions.includes("10%") || false}

                      />
                      10% Off or more
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="discount"
                        onChange={() => handleRadioButtonChange("20%")}
                        // checked={checkedOptions["20% Off or more"] || false}
                        checked={selectedOptions.includes("20%") || false}

                      />
                      20% Off or more
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="discount"
                        onChange={() => handleRadioButtonChange("30%")}
                        // checked={checkedOptions["30% Off or more"] || false}
                        checked={selectedOptions.includes("30%") || false}

                      />
                      30% Off or more
                    </label>
                    <label className="option">
                      <input
                        type="radio"
                        name="discount"
                        onChange={() => handleRadioButtonChange("40%")}
                        // checked={checkedOptions["40% Off or more"] || false}
                        checked={selectedOptions.includes("40%") || false}

                      />
                      40% Off or more
                    </label>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="right-product-page">
          <div className="search-bar-filters-button-container">
            <div className="search-bar-product-page">
              <FaSearch className="search-icon-product-page" style={{cursor:"pointer"}} onClick={()=> dispatch(Admin_product_name(search))} />
              <input type="text" placeholder="What are you looking for?" className="custom-input" onChange={(e)=>setSearch(e.target.value)}/>
            </div>
            <div className="filters-button-container">
              <button className="filters-button" onClick={toggleSidebar}>
                Filters
              </button>
            </div>
          </div>
          {products?.data ? (
            <div className="grid-product-page-container">
              {/* {productPageCarouselMap?.map((d) => ( */}
              {products?.data?.map((d) => (
                <Link
                  to={`/product-details-page/${createSlug(d?.name)}-${d?._id}`}
                  className="carousel-chair-card-product-page"
                >
                  {d?.image.startsWith("http") ? (
                    <>
                      <img
                        className="carousel-chair-image-product-page"
                        src={d.image}
                        alt=""
                      />
                    </>
                  ) : (
                    <img
                      className="carousel-chair-image-product-page"
                      src={
                        "https://imgs.search.brave.com/IoOvviQ4di6rWn-a5_3PxljCQRXg5AQco4id07WyC3o/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9waG90by1tb2Rl/cm4tbHV4dXJ5LWFy/bS1jaGFpci1mdXJu/aXR1cmUtZGVzaWdu/Xzc2MzExMS0yMjEx/Ni5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
                      }
                      alt="hello"
                    />
                  )}
                  <div className="carousel-card-bottom-container-product-page">
                    <div className="first-line-product-page">
                      <p className="carousel-card-uptext-product-page">
                        {d.name}
                      </p>
                      <div className="rating-box-product-page">
                        {d?.rating}
                        <FaStar className="star-icon-product-page" />
                      </div>
                    </div>
                    {d?.discount===0?
                      <div className="carousel-card-price-product-page">
                       RS {d?.price}
                    
                    </div>
                    :
                    <div className="carousel-card-price-product-page">
                      <span className="span-price-product-page">
                        {d?.price}
                      </span>
                      {"  "}
                      {DiscountFun(d?.price, d?.discount)}
                      <span className="discount-rate-product-page">
                        {" "}
                        {d?.discount}% Off
                      </span>
                    </div>
                    }
                  </div>
                    <div style={{color:"white",width:"100%",textAlign:"center",background:"var(--pr-text-color)",padding:"10px",borderRadius:"0rem 0rem 1.2rem 1.2rem"}}>{d?.display}</div>
                </Link>
              ))}
            </div>
          ) : (
            <LoadingHv />
          )}

          <div style={{ textAlign: "center", margin: "20px 0px" }}>
            <Pagination
              current={product_page}
              pageSize={9}
              total={products?.totalItems}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
      <CommonQuote />
    </>
  );
};

const FilterOptionsCategories = [
  {
    name: "Premium Executive",
  },
  {
    name: "Premium Netted",
  },
  {
    name: "Ergonomic Work Series",
  },
  {
    name: "Visitor Chair",
  },
  {
    name: "Cafe Chair Series",
  },
  {
    name: "High Counter Series",
  },
  {
    name: "Lounge Chair Series",
  },
  {
    name: "Sofa Series",
  },
  {
    name: "Training Chair Series",
  },
];

const productPageCarouselMap = [
  {
    image: "/first-chair-carousel-image.png",
    uptext: "Arico",
    downtext: "Landkrona",
    spanPrice: "Rs.549",
    price: "Rs.499",
  },
  {
    image: "/second-chair-carousel-image.png",
    uptext: "Martin",
    downtext: "Strandmon",
    spanPrice: "Rs.449",
    price: "Rs.399",
  },
  {
    image: "/third-chair-carousel-image.png",
    uptext: "Oyster",
    downtext: "Odger",
    spanPrice: "Rs.849",
    price: "Rs.799",
  },
  {
    image: "/fourth-chair-carousel-image.png",
    uptext: "Stanly",
    downtext: "Soderhamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "Boss",
    downtext: "Sodehamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/third-chair-carousel-image.png",
    uptext: "New Boss",
    downtext: "Odger",
    spanPrice: "Rs.849",
    price: "Rs.799",
  },
  {
    image: "/fourth-chair-carousel-image.png",
    uptext: "Arico",
    downtext: "Soderhamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "Martin",
    downtext: "Sodehamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/third-chair-carousel-image.png",
    uptext: "New Boss",
    downtext: "Odger",
    spanPrice: "Rs.849",
    price: "Rs.799",
  },
  {
    image: "/fourth-chair-carousel-image.png",
    uptext: "Arico",
    downtext: "Soderhamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "Martin",
    downtext: "Sodehamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
  {
    image: "/fourth-chair-carousel-image.png",
    uptext: "Arico",
    downtext: "Soderhamn",
    spanPrice: "Rs.729",
    price: "Rs.660",
  },
];

export default SideBar;


