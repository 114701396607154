import React, { useEffect } from "react";
import { useState } from "react";
import "./DescriptionWarrantyReviews.css";
import {
  FaRegStar,
  FaStar,
  FaStarAndCrescent,
  FaStarHalf,
} from "react-icons/fa";
import { CloseOutlined } from "@ant-design/icons";

import { FaTrashCan } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Form, Input, Popconfirm, message } from "antd";
import ReviewForm from "./ReviewForm";
import {
  useCreateCartMutation,
  useCreateReviewMutation,
  useDeleteReviewMutation,
  useFetchReviewQuery,
} from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  Login_show,
  Review_page,
} from "../../../store/mutation/remainingSlice";
import { IDate } from "../../../atoms/static/StateFun";
import ReviewContainer from "./ReviewContainer";

const DescriptionWarrantyReviews = ({ product }) => {
  const [selectedOption, setSelectedOption] = useState("materials");
  const [isReviewFormVisible, setIsReviewFormVisible] = useState(false);
  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const { review_page } = useSelector((state) => state.remaning);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleWriteReviewClick = () => {
    if (user?._id) {
      setIsReviewFormVisible(true);
    } else {
      dispatch(Login_show(true));
    }
  };

  const handleCancelReviewClick = () => {
    setIsReviewFormVisible(false);
  };

  
  const dispatch = useDispatch();

  const [starCount, setStarCount] = useState(1);
  const [createReview, createReviewResponseInfo] = useCreateReviewMutation();
  useEffect(() => {
    if (createReviewResponseInfo?.isSuccess) {
      message.success("Thank You For Your Response");
      handleCancelReviewClick();
    }
  }, [createReviewResponseInfo]);
 
  const onFinish = (data) => {
    if (user?._id) {
      data.rating = starCount;
      data.user_id = user?._id;
      data.product_id = product?._id;
      createReview(data);
    } else {
      handleCancelReviewClick();
      dispatch(Login_show(true));
    }
  };

  const handleStarClick = (index) => {
    setStarCount(index + 1); // Increase star count by 1 (index is 0-based)
  };
  return (
    <div className="accordion2-container">
      <div className="accordion2-options">
        <div
          className={`accordion2-option ${
            selectedOption === "materials" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("materials")}
        >
          Description
        </div>
        <div
          className={`accordion2-option ${
            selectedOption === "shipping" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("shipping")}
        >
          More Features
        </div>
        <div
          className={`accordion2-option ${
            selectedOption === "return" ? "active" : ""
          }`}
          onClick={() => handleOptionClick("return")}
        >
          Reviews
        </div>
      </div>
      <div className="accordion2-content">
        {selectedOption === "materials" && (
          <div className="accordion2-content">
            <p>{product?.description}</p>
          </div>
        )}
        {selectedOption === "shipping" && (
          <div className="accordion2-content">
            {product?.moreFeatures?.map((item) => (
              <>
                <h3>{item?.title}</h3>
                <p>{item?.description}</p>
              </>
            ))}
          </div>
        )}
        {selectedOption === "return" && (
          <div className="accordion2-content">
            {isReviewFormVisible && (
              <div className="review-form-overlay">
                <div className="create-review-form">
                  <div className="star-icons-review-container">
                    {[...Array(5)].map((_, index) => (
                      <span key={index} onClick={() => handleStarClick(index)}>
                        {index < starCount ? (
                          <FaStar className="stars-given-review" />
                        ) : (
                          <FaRegStar className="stars-not-given-review" />
                        )}
                      </span>
                    ))}
                  </div>
                  <button
                    className="close-review-form"
                    onClick={handleCancelReviewClick}
                  >
                    <p className="p--cross--custom">
                      <CloseOutlined />
                    </p>
                  </button>
                  <ReviewForm onFinish={onFinish} />
                </div>
              </div>
            )}
            {!isReviewFormVisible && (
              <div className="write-review-button-container">
                <button
                  className="write-review-button"
                  onClick={handleWriteReviewClick}
                >
                  Write a review
                </button>
              </div>
            )}
<ReviewContainer product={product} user={user} handleCancelReviewClick={handleCancelReviewClick}/>
         
          </div>
        )}
      </div>
    </div>
  );
};

const Content = [
  {
    name: "Udit Kaushik",
    time: "4d ago",
    rating: "4.5/5",
    headline: "Nice Quality!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },

  {
    name: "Virat Kohli",
    time: "5d ago",
    rating: "4/5",
    headline: "Good Quality!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },
  {
    name: "Aksha Hussain",
    time: "7d ago",
    rating: "5/5",
    headline: "Cool Designs!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },
  {
    name: "Udit Kaushik",
    time: "4d ago",
    rating: "4.5/5",
    headline: "Nice Quality!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },

  {
    name: "Virat Kohli",
    time: "5d ago",
    rating: "4/5",
    headline: "Good Quality!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },
  {
    name: "Aksha Hussain",
    time: "7d ago",
    rating: "5/5",
    headline: "Cool Designs!",
    review:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis, sed accusamus? Non illum nemo eligendi numquam, adipisci eum earum ullam iure reiciendis harum, nulla voluptatum itaque necessitatibus recusandae animi nobis, id iste ea iusto in! Numquam quia in maxime nam quidem dignissimos voluptatum, quas placeat incidunt perferendis qui hic consectetur.",
  },
];

export default DescriptionWarrantyReviews;
