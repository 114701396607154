import React from "react";
import Slider from "react-slick";
import "./WeAlsoSell.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DiscountFun, createSlug } from "../../../atoms/static/StateFun";
import { useNavigate } from "react-router-dom";

const WeAlsoSell = ({ title, data, plus }) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5, // Show 5 cards at once
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4, // Show 4 cards at once on smaller screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3, // Show 3 cards at once on even smaller screens
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2, // Show 3 cards at once on even smaller screens
        },
      },
      // Add more breakpoints as needed
    ],
  };

  return (
    <div className="we-also-sell-container">
      <h1 className="we-also-sell-heading">
        {title ? title : <>We also Sell</>}
      </h1>

      <Slider {...settings}>
        {data?.map((d) => (
          <div
            className="carousel-chair-card"
            onClick={() => navigate(`/product-details-page/${createSlug(d?.name)}-${d?._id}`)}
          >
            <img
              className="carousel-chair-image"
              src={d.image ? d.image : "/fourth-chair-carousel-image.png"}
              alt=""
            />
            <div className="carousel-card-bottom-container">
              <div className="card-left-container">
                <p className="carousel-card-uptext">{d.name}</p>
                <p className="carousel-card-downtext">{d.category}</p>
                {d?.discount === 0 ? (
                  <p className="carousel-card-price">
                    Rs {DiscountFun(d?.price, d?.discount)}
                  </p>
                ) : (
                  <p className="carousel-card-price">
                    <span className="span-price">{d.price}</span>
                    {"  "}
                    {DiscountFun(d?.price, d?.discount)}
                    <span className="discount-rate-product-page">
                      {" "}
                      {d?.discount}% Off
                    </span>
                  </p>
                )}
              </div>
              <div
                className="plus-image-container"
                style={{ backgroundColor: plus ? "white" : d.color }}
              >
                <img className="plus-image" src={"plussign.png"} alt="" />
              </div>
            </div>
            <div
              style={{
                color: "white",
                width: "100%",
                textAlign: "center",
                background: "var(--pr-text-color)",
                padding: "10px",
                borderRadius: "0rem 0rem 1.2rem 1.2rem",
              }}
            >
              {d?.display}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const carouselMap = [
  {
    image: "/fourth-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Soderhamn",
    spanPrice: "$729",
    price: "$660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Sodehamn",
    spanPrice: "$729",
    price: "$660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Sodehamn",
    spanPrice: "$729",
    price: "$660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Sodehamn",
    spanPrice: "$729",
    price: "$660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Sodehamn",
    spanPrice: "$729",
    price: "$660",
  },
  {
    image: "/first-chair-carousel-image.png",
    uptext: "SOFA",
    downtext: "Sodehamn",
    spanPrice: "$729",
    price: "$660",
  },
];

export default WeAlsoSell;
