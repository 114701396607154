import React from 'react'
import './ChairsSofasThumbnails.css'

const ChairsSofasThumbnails = () => {
  return (
    <div className='chairsofathumbnails-container'>
        <div className='chairsofathumbnails-1'>
            <img src='/chairshopByCategory.png' alt='' />
            <div className='chair-text123'>
                Chairs
            </div>
        </div>
        <div className='chairsofathumbnails-2'>
            <img src='/sofashopByCategory.png' alt='' />
            <div className='sofa-text123'>
                Sofas
            </div>
        </div>
    </div>
  )
}

export default ChairsSofasThumbnails