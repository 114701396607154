import React from 'react'
import './Emojispart.css'

const Emojispart = () => {
  return (
    <div className="emojis-part-container">
        <ul>
            <li>
                <div className="first-icon-container">
                    <img className='people123'src='people123.png' alt='' />
                </div>
                <p>
                    10+ Years Of Experience
                </p>
            </li>
            <li>
                <div className='second-icon-container'>
                    <img className='smileplus' src='smileplus.png' alt=''/>
                </div>
                <p>
                    10k+ Happy Clients
                </p>
            </li>
            <li>
                <div className='third-icon-container'>
                    <img className='reminder' src='reminder.png' alt=''/>
                </div>
                <p>
                    5k+ Projects Finished
                </p>
            </li>
        </ul>
    </div>
  )
}

export default Emojispart