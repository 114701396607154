import React, { useEffect } from 'react'
import DirectionYourCartPage from '../../../components/organisms/AddressPage_Components/DirectionYourCartPage'
import AddressPageMainPart from '../../../components/organisms/AddressPage_Components/AddressPageMainPart'

const AddressPage = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <DirectionYourCartPage/>
        <AddressPageMainPart/>
    </>
  )
}

export default AddressPage