import React, { useEffect } from 'react'
import Slider from "react-slick";
import { IDate } from '../../../atoms/static/StateFun';
import { FaStar } from 'react-icons/fa';
import { useDeleteReviewMutation, useFetchReviewQuery } from '../../../store/store';
import { Popconfirm, message } from 'antd';
import { FaTrashCan } from 'react-icons/fa6';

const ReviewContainer = ({product,user,handleCancelReviewClick,admin}) => {
    const settings = {
        dots: true, // Show dot indicators
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of slides to show
        slidesToScroll: 1, // Number of slides to scroll
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      
  const [deleteReview, deleteReviewResponseInfo] = useDeleteReviewMutation();

  useEffect(() => {
    if (deleteReviewResponseInfo?.isSuccess) {
      message.success("Review Deleted");
      handleCancelReviewClick();
    }
  }, [deleteReviewResponseInfo]);
const removeReview=(e,card)=>{
  deleteReview(card)
}
const {
    data: review,
    isLoading: reviewLoading,
    isFetching: reviewFetch,
  } = useFetchReviewQuery({ product_id: product?._id });
  return (
    <div className="reviews-container-product-details-page">
    <div className="create-review-container"></div>
    {console.log(review)}
    <Slider {...settings}>
      {review?.map((card, index) => (
        <div
          key={index}
          className="reviews-card-product-details-page"
        >
          <div className="name-rating-product-details-page">
            <div className="name-time-inner-div">
              <h3>{card?.user?.name}</h3>
              <p>{IDate(card.date)}</p>
            </div>
            <div className="rating-inner-div">
              {card.rating}
              <FaStar className="chair-image-description-star" />
            </div>
          </div>

          {/* <div className="horizontal-line-reviews-product-details-page-container">
                                  <div className="horizontal-line-reviews-product-details-page"></div>
                              </div> */}

          <div className="reviews-content-container-product-details-page">
            <div className="headline-review-container-product-details-page">
              <h3>{card.headline}</h3>
            </div>

            <div className="reviews-content-product-details-page">
              {card.description.split(" ").slice(0, 35).join(" ")}{" "}
              {/* Display first 30 words */}
              {card.description.split(" ").length > 35 && (
                <span>
                  <span
                    className={`more-text-${index}`}
                    style={{ display: "none" }}
                  >
                    {card.description.split(" ").slice(35).join(" ")}{" "}
                    {/* Hidden portion */}
                  </span>
                  <button
                    className={`more-button-${index}`}
                    style={{
                      border: "none",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 600,
                      textDecoration: "underline",
                      background: "transparent",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const moreText = document.querySelector(
                        `.more-text-${index}`
                      );
                      const btnText = document.querySelector(
                        `.more-button-${index}`
                      );

                      if (moreText && btnText) {
                        if (moreText.style.display === "none") {
                          moreText.style.display = "inline";
                          btnText.textContent = "Read less";
                        } else {
                          moreText.style.display = "none";
                          btnText.textContent = "Read more";
                        }
                      }
                    }}
                  >
                    ...Read more
                  </button>
                </span>
              )}
              {user?._id===card?.user?._id||admin?
              <div className="edit-remove-buttons-container">
                {/* <button className="edit-button-reviews">Edit</button> */}
                <Popconfirm
                  title="Sure to remove?"
                  onCancel={(e) => {
                    e.stopPropagation();
                  }}
                  onConfirm={(e) => removeReview(e,card)}
                >
                  <button className="remove-button-reviews">
                  Remove <FaTrashCan/>
                </button>
                </Popconfirm>
                
              </div>:null
                }
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
  )
}

export default ReviewContainer
