import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const DirectionHomePage = () => {
  return (
    <>
        <div className='product-page-link-container'>
            <Link to='/' className='direction-product-page-link'>
                <FaArrowLeft/>
                <h1>Home</h1>
            </Link>
        </div>  
    </>
  )
}

export default DirectionHomePage