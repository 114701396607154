import React from 'react'
import { ClipLoader } from 'react-spinners'
const LoadingHv = () => {
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"60vh",width:"100%"}}>
    <ClipLoader color="#36d7b7" />
    </div>
  )
}

export default LoadingHv 
