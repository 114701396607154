import React from 'react'
import ChairsSofasThumbnails from '../../../components/organisms/ShopByCategoryPageComponents/ChairsSofasThumbnails'
import OtherCategoriesShopByCategory from '../../../components/organisms/ShopByCategoryPageComponents/OtherCategoriesShopByCategory'

const ShopByCategoryPage = () => {
  return (
    <>
        <ChairsSofasThumbnails/>  
        <OtherCategoriesShopByCategory/>
    </>
  )
}

export default ShopByCategoryPage