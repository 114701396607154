export const colorArray = [
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
  "Purple",
  "Pink",
  "Brown",
  "Beige",
  "Black",
  "White",
  "Gray",
];

export const categoryArray = [
  "CUSHION SERIES",
  "MESH SERIES",
  "PREMIUM EXECUTIVE SERIES",
  "PREMIUM NETTED SERIES",
  "ERGONOMIC WORK SERIES",
  "VISITOR CHAIR SERIES",
  "CABIN TABLE WORKSTATION CONFERENCE TABLE",
  "STORAGE PARTITION COMPACTORS",
  "CAFE CHAIRS SERIES",
  "HIGH COUNTER SERIES",
  "CAFE TABLES SERIES",
  "LOUNGE CHAIR SERIES",
  "SOFA SERIES",
  "OTTOMAN POUFFE",
  "TRAINING CHAIR SERIES",
  "SCHOOL FURNITURE",
  "TANDEM SEATING & AUDITORIUM CHAIR",
  "GARDEN FURNITURE",
  "POOL LOUNGE TENT & GAZEBO",
  "SWING & BENCHES",
];
//Shipping Soon  Shipped  Out for Delivery   Delivered
//Pending Success Failed Cancelled

export const order_state2 = [
  "ALL",
  "Shipping Soon",
  "Shipped",
  "Out for Delivery",
  "Delivered",
];
export const order_state = [
  "Pending",
  "Pending Pickup",
  "In Transit",
  "Out for Delivery",
  "Delivered",
  "Failed Delivery Attempt",
  "Exception",
  "Cancelled",
  "Returned",
];
export const paymentArray = [
  "ALL",
  "Pending",
  "Success",
  "Failed",
  "Cancelled",
  "Cash On Delivery",
];
export const paymentDoneArray = [
  "Pending",
  "Success",
  "Cancelled",
  "Failed",
  "Cancelled",
];

// const category = [
//   "Premium Executive",
//   "Premium Netted",
//   "Ergonomic Work Series",
//   "Visitor Chair",
//   "Cafe Chair Series",
//   "High Counter Series",
//   "Lounge Chair Series",
//   "Sofa Series",
//   "Training Chair Series",
// ];
export const materialNames = [
  'Wood',
  'Metal',
  'Linen',
  'Cotton',
  'Wool',
  'Silk',
  'Teracotta'
];
export const discount=[
  "10%","20%","30%","40%"
]
export const price=[
  "Under Rs.1000","Rs.1000-2000","Rs.2000-3000","Over Rs.3000"
]

export const makeToFilterString=(input)=>{
  

  const output = input.reduce((acc, item) => {
    if (categoryArray.includes(item)) {
      acc.category.push(item);
    } 
    else if (colorArray.includes(item)) {
      acc.colorArray.push(item);
    } 
    else if (materialNames.includes(item)) {
      acc.material.push(item);
    } 
    else if (price.includes(item)) {
      acc.price.push(item);
    } 
    else if (discount.includes(item)) {
      acc.discount.push(item);
    } 
    else {
      acc.other.push(item);
    }
    return acc;
  }, { category: [], colorArray: [],material:[],price:[], discount:[],other: [] });

  let queryString = Object.entries(output)
  .map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
    } else {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  })
  .filter(str => str !== '') // Filter out empty strings
  .join('&');
  // PREMIUM NETTED SERIES
// Prepend '&' if queryString is not empty
queryString = queryString ? `&${queryString}` : '';
  return queryString
  
}
export const makeItArray=(queryString)=>{
  
  const params = {};

  queryString.split("&").forEach(pair => {
    const [key, value] = pair.split("="); // Split each pair into key and value
    const decodedValue = decodeURIComponent(value); // Decode the URL encoded value
    if (params[key]) {
      params[key].push(decodedValue); // If the key already exists, add the decoded value to the array
    } else {
      params[key] = [decodedValue]; // If the key doesn't exist, create a new array with the decoded value
    }
  });
  
  const valuesArray = Object.values(params).flat(); 
  const filteredArray = valuesArray.filter(item => item !== "undefined");
  return filteredArray
}

export const forCheckValue=(array)=>{
  const result = array.reduce((acc, color) => {
    acc[color] = true;
    return acc;
}, {});
return result
}