import React, { useEffect } from 'react'
import DirectionAddressPage from '../../../components/organisms/PaymentPage_Components/DirectionAddressPage'
import PaymentPageMainPart from '../../../components/organisms/PaymentPage_Components/PaymentPageMainPart'

const PaymentPage = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <DirectionAddressPage/>
        <PaymentPageMainPart/>
    </>
  )
}

export default PaymentPage