import React from 'react'
import { useEffect } from 'react';
import MainSectionProfilePage from '../../../components/organisms/ProfilePage_Components/MainSectionProfilePage';
import DirectionHomePage from '../../../components/organisms/ProfilePage_Components/DirectionHomePage';

const ProfilePage = () => {
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);


  return (
    <>
        <DirectionHomePage/>
        <MainSectionProfilePage/>
    </>
  )
}

export default ProfilePage