import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logout } from "./userSlice";

export const Login_show = createAsyncThunk(
  "login_show_s",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Login_show", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Admin_color = createAsyncThunk(
  "admin_color",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Admin_color", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Admin_material = createAsyncThunk(
  "admin_material",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Admin_material", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const Admin_active = createAsyncThunk(
  "admin_active",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Admin_active", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }

);

export const Admin_category = createAsyncThunk(
  "admin_category",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Admin_category", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Admin_product_name = createAsyncThunk(
  "admin_product_name",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Admin_product_name", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const FilterString = createAsyncThunk(
  "filter_string",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("filter_string", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const product_page = "product_page";
const trending_page = "trending_page";
const admin_product_page = "admin_product_page";
const order_page = "order_page";
const review_page = "review_page";

export const Trending_page = (newPage) => {
  return {
    type: trending_page,
    payload: newPage,
  };
};
export const Product_page = (newPage) => {
  return {
    type: product_page,
    payload: newPage,
  };
};

export const Admin_Product_page = (newPage) => {
  return {
    type: admin_product_page,
    payload: newPage,
  };
};

export const Order_page = (newPage) => {
  return {
    type: order_page,
    payload: newPage,
  };
};
export const Review_page = (newPage) => {
  return {
    type: review_page,
    payload: newPage,
  };
};
// initialize userToken from local storage

const initialState = {
  login_show: false,
  product_page: 1,
  order_page: 1,
  admin_product_page: 1,
  admin_trending_page: 1,

  review_page: 1,
  filter_string: "",
  admin_category: "",
  admin_color: "",
  product_name: "",
  admin_material: "",
  admin_active: "",

};

const remainingSlice = createSlice({
  name: "remaning",
  initialState,
  reducers: {
    logout(state, action) {
      return {
        ...state,
        product_page: 1,
        order_page: 1,
        admin_product_page: 1,
        admin_trending_page: 1,
        review_page: 1,
        filter_string: "",
        admin_category: "",
        admin_color: "",
        product_name: "",
        admin_material: "",
        admin_active: "",
      };
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(Login_show.fulfilled, (state, action) => {
        state.login_show = action.payload;
      })

      .addCase(logout, (state, action) => {
        // Reset the state to the initial state
        return initialState;
      })
      .addCase(product_page, (state, action) => {
        state.product_page = action.payload;
      })
      .addCase(trending_page, (state, action) => {
        state.trending_page = action.payload;
      })
      .addCase(admin_product_page, (state, action) => {
        state.admin_product_page = action.payload;
      })
      .addCase(FilterString.fulfilled, (state, action) => {
        state.filter_string = action.payload;
        state.admin_product_page=1;
        state.product_page=1;
      })
      .addCase(review_page, (state, action) => {
        state.review_page = action.payload;
      })
      .addCase(Admin_category.fulfilled, (state, action) => {
        state.admin_category = action.payload;
        state.admin_product_page = 1;

      })
      .addCase(Admin_color.fulfilled, (state, action) => {
        state.admin_color = action.payload;
        state.admin_product_page = 1;
      })
      .addCase(Admin_product_name.fulfilled, (state, action) => {
        state.product_name = action.payload;
        state.admin_product_page = 1;
        state.product_page = 1;
      })
      .addCase(Admin_material.fulfilled, (state, action) => {
        state.admin_material = action.payload;
        state.admin_product_page = 1;
      })
      .addCase(Admin_active.fulfilled, (state, action) => {
        state.admin_active = action.payload;
        state.admin_product_page = 1;
      })
      .addCase(order_page, (state, action) => {
        state.order_page = action.payload;
      })
      ;
  },
});
// export default userSlice.reducer;

// export default userSlice.reducer;
export default remainingSlice.reducer;
