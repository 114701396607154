import {
  Button,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { CloseOutlined } from "@ant-design/icons";
import FormList from "antd/es/form/FormList";
import { Option } from "antd/es/mentions";
import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleUp, FaCross } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../../../store/store";
import MultiImgUpload from "../../../../atoms/imgUpload/MultiImgUpload";
import { url } from "../../../../store/mutation/url";
import AnimatedFileInput from "../../../../atoms/imgUpload/AnimatedFileInput";
import Hr from "../../../../atoms/buttons/Hr";
import {
  categoryArray,
  colorArray,
  materialNames,
} from "../../../../atoms/static/State";
import ReviewContainer from "../../../../components/organisms/Product_DetailsPage_Components/ReviewContainer";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 15 },
};
const onFinish = (data, Submitimg, setLoa, createProduct) => {
  // setLoa(true);
  console.log(data);
  const { _id, ...newData } = data;
  // createProduct(newData);

  if (newData.rating > 5) {
    message.error("Check Your Product Rating");
    setLoa(false);
  } else {
    Submitimg(newData);
  }
};
const onUpdate = (data, Submitimg, setLoa, updateProduct) => {
  // setLoa(true);
  console.log(data);
  // updateProduct(data);
  if (data.rating > 5) {
    message.error("Check Your Product Rating");
    setLoa(false);
  } else {
    Submitimg(data);
  }
};
const CreateProduct = ({ product }) => {
  const size = ["X", "L", "M"];
  const colorOptions = [
    "Red",
    "Blue",
    "Green",
    "Yellow",
    "Black",
    "White",
    "Orange",
    "Purple",
    "Pink",
    "Gray",
  ];
  const navigate = useNavigate();
  const { user, admin, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const [varient, setVarient] = useState(false);
  const handleRevision = () => {
    setVarient(true);
  };
  const [loa, setLoa] = useState(false);
  const [image, setImage] = useState(null);
  const [multifilePreviews, setMultiFilePreviews] = useState([]);

  const handleFileSelect = (file) => {
    console.log("Selected file:", file);
    setImage(file);
  };
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);

  const handleMultiFileSelect = (file) => {
    console.log("Selected files:", file);
    // console.log(images => [...images, ...file])
    setImages((images) => [...images, ...file]);
  };
  const [isReviewVisible, setIsReviewVisible] = useState(false);
  const [createProduct, creatProductResponseInfo] = useCreateProductMutation();
  const [updateProduct, updateProductResponseInfo] = useUpdateProductMutation();
  const [filePreviews, setFilePreviews] = useState();
  useEffect(() => {
    if (creatProductResponseInfo.isLoading === false) {
      setLoa(false);
    }

    if (creatProductResponseInfo.isSuccess) {
      message.success("Product Created");
      navigate("/admin/products");
    }
  }, [creatProductResponseInfo]);
  useEffect(() => {
    if (updateProductResponseInfo.isLoading === false) {
      setLoa(false);
    }

    if (updateProductResponseInfo.isSuccess) {
      message.success("Product Updated");
      navigate("/admin/products");
    }
  }, [updateProductResponseInfo]);

  // const Submitimg = (data1) => {
  //   if(image){

  //     const data = new FormData();
  //     data.append("file", image);
  //     data.append("upload_preset", "usdw3wzg");
  //     data.append("cloud_name", "djathks89");

  //     fetch("https://api.cloudinary.com/v1_1/djathks89/image/upload", {
  //     method: "POST",
  //     body: data,
  //   })
  //   .then((res) => res.json())
  //   .then((data) => {
  //     data1.product_img = data.url;
  //     // console.log(data.url);
  //     console.log("lion",data1);
  //     if(product){
  //       // updateProduct(data1)
  //     }else{

  //       // createProduct(data1);
  //     }
  //   })
  //   .catch((err) => {
  //     setLoa(false);

  //     console.log(err);
  //   });
  // }else{
  //   if(product){
  //     updateProduct(data1)
  //   }else{

  //     createProduct(data1);
  //   }
  // }
  // };
  const [productIn, setProductIn] = useState([]);
  useEffect(() => {
    if (product) {
      setFilePreviews(product.image);
      setMultiFilePreviews(product.multi_img);
      setProductIn(product.multi_img);
    }
  }, [product]);
  const Submitimg = async (data1) => {
    if (images) {
      const formData = new FormData();

      for (let i = 0; i < images.length; i++) {
        formData.append(`image`, images[i]);
      }

      try {
        const response = await fetch(`${url}/aws/multiple`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log(
          "Images uploaded successfully!",
          data.map((item) => item.location)
        );
        data1.multi_img = [...productIn, ...data.map((item) => item.location)];
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    } else {
      data1.multi_img = [...productIn];
    }

    if (image) {
      const formData = new FormData();
      formData.append("image", image);

      try {
        const response = await fetch(`${url}/aws/upload`, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log("Image uploaded successfully!", data.location);
        data1.image = data.location;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    if (product && varient) {
      createProduct(data1);
    } else if (product) {
      updateProduct(data1);
    } else {
      createProduct(data1);
    }
    // }
  };

  const [form] = Form.useForm();

  const data = {};
  const labelCol = 8;
  const wrapperCol = 10;
  const unit = [];
  const PullIt = (item, index) => {
    setImages((images) =>
      images.filter((_, ind) => ind !== index - productIn.length)
    );
    setMultiFilePreviews((prevImageUrls) =>
      prevImageUrls.filter((url) => url !== item)
    );
    setProductIn(productIn.filter((it) => it !== item));
  };

  return (
    <div
      style={{ marginTop: "40px", textAlign: "center" }}
      className="body-width-admin"
    >
      <h2 style={{ marginBottom: "30px" }}>Create Product</h2>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={(data) => {
          if (product && varient) {
            onFinish(data, Submitimg, setLoa, createProduct);
          } else {
            if (product) {
              onUpdate(data, Submitimg, setLoa, updateProduct);
            } else {
              onFinish(data, Submitimg, setLoa, createProduct);
            }
          }
        }}
        style={{
          maxWidth: "100%",
        }}
        // autoComplete="off"
        {...layout}
        initialValues={product}
        // initialValues={thisone}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem labelCol={labelCol} label="Name" name="name">
              <Input
                className="create-product-input-from"
                placeholder="Enter Product Name"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Suitable For"
              name="suitableFor"
            >
              <Input
                className="create-product-input-from"
                placeholder="Suitable For"
              />
            </FormItem>

            <FormItem labelCol={labelCol} label="Category" name="category">
              <Select
                className="create-product-select-from"
                placeholder="Enter Category"
              >
                {categoryArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem labelCol={labelCol} label="Special" name="special">
              <Switch />
            </FormItem>
            <FormItem labelCol={labelCol} label="Rating" name="rating">
              <Input
                className="create-product-input-from"
                placeholder="Enter Rating"
              />
            </FormItem>
            {/* <FormItem labelCol={labelCol} label="Display" name="display">
              <Input
                className="create-product-input-from"
                placeholder="Enter Display"
              />
            </FormItem> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem labelCol={labelCol} label="Instock" name="instock">
              <Switch />
            </FormItem>
            <FormItem labelCol={labelCol} label="Active" name="active">
              <Switch />
            </FormItem>

            <FormItem labelCol={labelCol} label="Color" name="color">
              <Select
                className="create-product-select-from"
                placeholder="Enter Product Size"
                style={{ width: "100%" }}
              >
                {colorArray.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem labelCol={labelCol} label="Price" name="price">
              <Input
                className="create-product-input-from"
                placeholder="Enter Price"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Product Discount"
              name="discount"
            >
              <Input
                type="number"
                className="create-product-input-from"
                placeholder="Enter Discount In Percentage"
              />
            </FormItem>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "85%",
            margin: "auto",
          }}
        >
          <FormItem
            labelCol={labelCol}
            label="Description"
            name="description"
            // span={10}
          >
            <TextArea style={{ width: "1000px" }} />
          </FormItem>
         
        </div>
        <div
          style={{
            display: "flex",
            width: "85%",
            margin: "auto",
          }}
        >
        <FormItem
            labelCol={labelCol}
            label="Display"
            name="display"
            // span={10}
          >
            <TextArea style={{ width: "1000px" }} />
          </FormItem>
         
        </div>

        <Hr />
        <h3
          style={{
            textAlign: "center",
            background: "var(--pr-text-color)",
            color: "var(--white)",
          }}
        >
          Features
        </h3>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            margin: "auto",
            // border:"1px solid lightgrey",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem labelCol={labelCol} label="Material" name={["material"]}>
              {/* <Input
                className="create-product-input-from"
                placeholder="Enter Product Price"
              /> */}
              <Select
                className="create-product-select-from"
                placeholder="Enter Material"
              >
                {materialNames.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Cover"
              name={["chair", "cover"]}
              span={10}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Cover"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Back Cushion"
              name={["chair", "backCushion"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Back Cushion"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Arm Rest"
              name={["chair", "armRest"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Arm Rest"
              />
            </FormItem>
            <FormItem labelCol={labelCol} label="Base" name={["chair", "base"]}>
              <Input
                className="create-product-input-from"
                placeholder="Enter Base"
              />
            </FormItem>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem labelCol={labelCol} label="Mech" name={["chair", "mech"]}>
              <Input
                className="create-product-input-from"
                placeholder="Enter Mech"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Gas lift"
              name={["chair", "gaslift"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Gaslift"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Max Load"
              name={["chair", "maxLoad"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Max Load"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Center Beam"
              name={["chair", "centerBeam"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Center Beam"
              />
            </FormItem>
          </div>
        </div>
        <Hr />
        <div style={{ marginTop: "20px" }}>
          {/* <FormList name="product_highlight" style={{ width: "100%" }}>
              {(fields, { add, remove }) => (
                <div className="form-list-admin-highlights-add">
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      style={{
                        // display: "flex",
                        marginBottom: "50px",
                        marginLeft: "50px",
                        padding: "20px",
                        flexDirection: "column",
                        background: "#e5e5e5",
                        // width:"100%",
                        boxShadow: " 0px 0px 2px gray inset",
                      }}
                      align="baseline"
                      className="quotation-form-list"
                    >
                      <Row className="from-row">
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Item Name",
                            },
                          ]}
                          style={{ width: "100%" }}
                          wrapperCol={wrapperCol}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                      </Row>
  
                      <Form.Item
                        {...restField}
                        name={[name, "desc"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing first name",
                          },
                        ]}
                        wrapperCol={wrapperCol}
                      >
                        <Input placeholder="Description" />
                      </Form.Item>
  
                      <Button
                        danger
                        style={{ background: "red", color: "white" }}
                        className="c"
                        onClick={() => remove(name)}
                      >
                        Delete
                      </Button>
                    </Space>
                  ))}
                  <FormItem>
                    <Button
                      style={{ width: "100%" }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Product HighLight
                    </Button>
                  </FormItem>
                </div>
              )}
            </FormList> */}
          <Form.Item label="More Features">
            <Form.List name="moreFeatures">
              {(subFields, subOpt) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                  }}
                >
                  {subFields.map((subField) => (
                    <Space key={subField.key}>
                      <Form.Item noStyle name={[subField.name, "title"]}>
                        <Input placeholder="title" />
                      </Form.Item>
                      <Form.Item noStyle name={[subField.name, "description"]}>
                        <Input
                          placeholder="description"
                          style={{ width: "400px" }}
                        />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name);
                        }}
                      />
                    </Space>
                  ))}
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + Add More Features
                  </Button>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </div>
        <Hr />
        <h3
          style={{
            textAlign: "center",
            background: "var(--pr-text-color)",
            color: "var(--white)",
          }}
        >
          Dimensions
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "85%",
            margin: "auto",
            // border:"1px solid lightgrey",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem
              labelCol={labelCol}
              label="Total Height"
              name={["size", "totalHeight"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Total Height"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Seat Height"
              name={["size", "seatHeight"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Seat Height"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Length"
              name={["size", "length"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Length"
              />
            </FormItem>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem
              labelCol={labelCol}
              label="Width"
              name={["chair", "width"]}
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Width"
              />
            </FormItem>
            <FormItem labelCol={labelCol} label="Unit" name={["chair", "unit"]}>
              <Input
                className="create-product-input-from"
                placeholder="Enter Unit"
              />
            </FormItem>
          </div>
        </div>
        <FormItem name="image"></FormItem>
        <div>
          {filePreviews ? (
            <img style={{ width: "200px" }} src={filePreviews} alt="" />
          ) : null}
        </div>
        <AnimatedFileInput
          onFileSelect={handleFileSelect}
          setFilePreviews={setFilePreviews}
        />
        <FormItem name="_id"></FormItem>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {multifilePreviews ? (
            <>
              {multifilePreviews.map((item, index) => (
                <div style={{ position: "relative", width: "200px" }}>
                  <MdClose
                    onClick={() => PullIt(item, index)}
                    style={{
                      cursor: "pointer",
                      background: "white",
                      borderRadius: "360px",
                      position: "absolute",
                      right: "25px",
                      top: "25px",
                      verticalAlign: "middle",
                    }}
                  />
                  <img
                    style={{ width: "100%", padding: "20px" }}
                    src={item}
                    alt=""
                  />
                </div>
              ))}
            </>
          ) : null}
        </div>
        <MultiImgUpload
          onFileSelect={handleMultiFileSelect}
          setMultiFilePreviews={setMultiFilePreviews}
          multifilePreviews={multifilePreviews}
        />

        <div className="additional-info-section">
          <div
            className="additional-info-header"
            style={{
              display: "flex",
              width: "100px",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <div>
              <h2>Review</h2>
            </div>
            <div
              onClick={() => setIsReviewVisible(!isReviewVisible)}
              style={{ cursor: "pointer" }}
            >
              {!isReviewVisible ? (
                <FaAngleUp style={{ verticalAlign: "middle" }} />
              ) : (
                <FaAngleDown style={{ verticalAlign: "middle" }} />
              )}
            </div>
          </div>
          {product?._id ? (
            <div style={{ marginBottom: "100px" }}>
              {isReviewVisible && (
                //  <ReviewComponent/>
                <>
                  <ReviewContainer
                    admin={admin}
                    product={product}
                    user={user}
                  />
                </>
              )}
            </div>
          ) : null}
        </div>
        {/* <Button onClick={Submitimg}>Upload</Button> */}
        <FormItem>
          <Button
            // loading={true}
            // style={{ height: "100%", background: "var(--pr-color) " }}
            loading={loa}
            type="primary"
            htmlType="submit"
          >
            {!product?._id ? <>Create Product</> : <>Update Product</>}
          </Button>

          {product?._id ? (
            <Button
              onClick={handleRevision}
              loading={updateProductResponseInfo?.isLoading}
              style={{
                marginLeft: "40px",
                height: "100%",
              }}
              type="primary"
              htmlType="submit"
            >
              Create Varient
            </Button>
          ) : null}
        </FormItem>
      </Form>
    </div>
  );
};

export default CreateProduct;
