import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './AddToCart_button.css'

const AddToCart_button = () => {
  return (
    <button className='addtocart-button-container'>
        <NavLink to='/cart-page' className='addtocart-link' activeClassName="underline">
            Cart
        </NavLink>
    </button>
  )
}

export default AddToCart_button