import React from 'react'
import './SavorComfort.css'

const SavorComfort = () => {
  return (
    <div className='savor-comfort-container'>
        <div className='savor-comfort-image'>
            <img className='longdesk-image' src='longdesk.png' alt=''/>
        </div>
        <div className='savor-comfort-right-side'>
            <h2 className='savor-comfort-right-side-heading'>
                Savor comfort, style, and simplicity with our 
                {' '}
                <span className='sleek-sofas'>sleek sofas</span>
            </h2>
            <ul>
                <li>
                    Luxurious Comfort
                </li>
                <li>
                    Timeless Elegance
                </li>
                <li>
                    Customizable Options
                </li>
                <li>
                    Exquisite Craftsmanship
                </li>
                <li>
                    Thoughtful Features
                </li>
                <li>
                    Easy Maintenance
                </li>
            </ul>
        </div>
    </div>
  )
}

export default SavorComfort