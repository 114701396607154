import React from "react";
import CreateProduct from "./CreateProduct";
import { useParams } from "react-router-dom";
import { useGetAdminProductQuery, useGetProductQuery } from "../../../../store/store";
import LoadingHv from "../../../../atoms/buttons/LoadingHv";

const MainProduct = () => {
  const { id } = useParams();
  const {
    data: data,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useGetAdminProductQuery({ _id: id });
  // const fetch=false
 
  return (
    <div>
      {fetch?
      <><LoadingHv/></>
     
    : <CreateProduct product={data} />}
      </div>
  );
};

export default MainProduct;
