import React, { useEffect } from 'react'
import DIrectionProductPage from '../../../components/organisms/CartPage_Components/DIrectionProductPage'
import MainDetailsCartPage from '../../../components/organisms/CartPage_Components/MainDetailsCartPage'
import YouMayAlsoCartPage from '../../../components/organisms/CartPage_Components/YouMayAlsoCartPage'
import { useFetchCartQuery, useFetchTrendingQuery } from '../../../store/store'
import { useSelector } from 'react-redux'
import WeAlsoSell from '../../../components/organisms/HomePage_Components/WeAlsoSell'


const CartPage = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const { user, userToken, loading, isAuthenticated } =
  useSelector((state) => state.user);
  const {
    data: cart,
    isLoading: cartLoading,
    isFetching: cartFetch,
  } = useFetchCartQuery({_id:user?._id});
  const {
    data: trending,
    isLoading: trending_loading,
  } = useFetchTrendingQuery({filter:""});
  console.log(cart)
  return (
    <>
      <DIrectionProductPage/>
      
      
      <MainDetailsCartPage cart={cart}/>
      
      <WeAlsoSell title={"Trending"} data={trending} plus={true}/>
    </>
  )
}

export default CartPage