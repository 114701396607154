import React from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';
import ContactDetailsBar from './ContactDetailsBar';
import "./contact.css";
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  background-color: whitesmoke;
  padding-bottom: 50px;
`;

const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 0.7rem;
`;

const FormContainer = styled.div`
  width: 70%;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  height: 70vh;
  display: flex;
  @media (max-width: 768px) {
    width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
  }
`;

const TextOne = styled.b`
  font-size: 30px;
  color: rgb(4, 4, 59);
  text-align: center;
`;

const TextTwo = styled.p`
  color: rgb(4, 4, 34);
  font-size: 15px;
  text-align: center;
`;

const FormPage = () => {
    return (
        <PageWrapper>
            <PageHeadingWrapper>
                <TextOne>Contact US</TextOne>
                <TextTwo>Any Question or remarks? Just write us a message</TextTwo>
            </PageHeadingWrapper>
            <FormContainer>
                <ContactDetailsBar />
                <ContactForm />
            </FormContainer>
        </PageWrapper>
    );
};

export default FormPage;