import { Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import React from 'react'

const ReviewForm = ({onFinish}) => {
  return (
    <div>
          <Form onFinish={onFinish}>
                    {/* <Form.Item
                      label="Review"
                      name="review"

                      rules={[
                        { required: true, message: 'Please provide a rating!' },
                        {
                          type: 'number',
                          min: 1,
                          max: 5,
                          message: 'Rating must be between 1 and 5',
                          transform: (value) => Number(value), // Convert input value to number
                        },
                       
                      ]}
                    >
                      <Input type='number' placeholder="Write your review here" />
                    </Form.Item> */}

                    <Form.Item label="Comments" name="description">
                      <TextArea required placeholder="Please Write Your Views" />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
    </div>
  )
}

export default ReviewForm
