import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import './admin_component.css'
import { Admin_Product_page, Order_page, Product_page } from "../../store/mutation/remainingSlice";

const ETable = ({ editfun,data, loading, columns ,product_page,visit,user_page,page100,page_size,size}) => {
    const dispatch=useDispatch()
  const [total_item, setTotal_item] = useState(1);
  const [datas, setDatas] = useState(null);
  useEffect(() => {
    if (data) {
      setTotal_item(data.totalItems);
      setDatas(data.data)
    }
  }, [data]);
  const [currentPage, setCurrentPage] = useState(1);
useEffect(() => {
  if(product_page){

    setCurrentPage(product_page)
  }
}, [product_page]);
useEffect(() => {
  if(user_page){
console.log(user_page)
    setCurrentPage(user_page)
  }
}, [user_page]);

  return (<>
 

        <div>
        <Table
        size={size?size:"medium"}
        style={{ cursor: "pointer" }}
        className="custom-table-ant"
        columns={columns}
        loading={loading}
        dataSource={datas}
        
        pagination={{
          total: total_item,
          pageSize: page_size?page_size:9,
         
          current: currentPage,
          onChange: (page) => {
            if(page100==="product"){

              dispatch(Product_page(page))
            }
            if(page100==="admin-product"){

              dispatch(Admin_Product_page(page))
            }
            if(page100==="user"){
            //   dispatch(User_page(page))
            }
            if(page100==="order"){
              dispatch(Order_page(page))
            }
          },
        }}
        onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
            // console.log(event)
            // console.log(record)
            visit(record)
              },
            };
          }}
      
      ></Table>
      </div>
    </>
      );
    };

export default ETable;
