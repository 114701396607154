export const DiscountFun = (price, discount) => {
  const value = parseInt(price - price * (discount / 100));
  if (isNaN(value)) {
    return price;
  } else {
    return value;
  }
};
export const SumOfCart = (items) => {
  const totalPrice = items?.reduce((sum, item) => {
    const totalItemPrice = item.quantity * item.product.price;
    return sum + totalItemPrice;
  }, 0);

  return parseInt(totalPrice);
};
export const SumOfOrder = (items) => {
  const totalPrice = items?.product?.reduce((sum, item) => {
    const totalItemPrice = item.quantity * item.price;
    return sum + totalItemPrice;
  }, 0);

  return totalPrice;
};
export const SumOfCartWithDiscount = (items) => {
  const totalPrice = items?.reduce((sum, item) => {
    const discountedPrice =
      item.product.price - (item.product.price * item.product.discount) / 100;
    const totalItemPrice = item.quantity * discountedPrice;
    return sum + totalItemPrice;
  }, 0);

  return parseInt(totalPrice);
};
export const SumOfOrderWithDiscount = (items) => {
  const totalPrice = items?.product?.reduce((sum, item) => {
    const discountedPrice = item.price - (item.price * item.discount) / 100;
    const totalItemPrice = item.quantity * discountedPrice;
    return sum + totalItemPrice;
  }, 0);

  return totalPrice;
};

export const IDate = (date) => {
  const IndianDate = new Date(date).toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return IndianDate;
};

export const validateEmail = (rule, value, callback) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (value && !emailRegex.test(value)) {
    callback("Please enter a valid email address");
  } else {
    callback(); // Validation succeeded
  }
};

export const validatePhoneNumber = (rule, value, callback) => {
  const phoneNumberRegex = /^\d{10}$/;
  if (value && !phoneNumberRegex.test(value)) {
    callback("Please enter a valid 10-digit phone number");
  } else {
    callback(); // Validation succeeded
  }
};

export const isAnyFieldEmpty = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      if (isAnyFieldEmpty(obj[key])) {
        return true;
      }
    } else if (!obj[key] && obj[key] !== 0) {
      return true;
    }
  }
  return false;
};

export const createSlug = (text) => {
  return text
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, "") // Remove special characters
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
};
export const getId = (location) => {

  // Get the pathname (e.g., "/product-details-page/")

  // Get the search parameters (e.g., "?id=662f8af9f1ef1558fa71a3ed&name=miracle-hb")
  const id = location.split('-').pop();
  return id
};
