import React, { useEffect, useState } from "react";
import { Button, Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import CouponTable from "./CouponTable";
import './form/coupon.css'
import { CloseOutlined } from "@ant-design/icons";
import MainCoupon from "./form/MainCoupon";
import { useDeleteCouponMutation, useFetchCouponAdminQuery } from "../../../store/store";

const Model = ({ Perform_cancel, userData }) => {
  return (
    <div className="model-con">
      <div className="model-box" style={{ height: "50%" }}>
        <p className="p--cross--custom" onClick={() => Perform_cancel()}>
          <CloseOutlined />
        </p>
        <div style={{ height: "100%", overflow: "auto" }}>
          {/* <OrderProductTable data={data} /> */}
          <MainCoupon data={userData} Perform_cancel={Perform_cancel} />
        </div>
      </div>
    </div>
  );
};
const Coupon = () => {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const Perform_cancel = () => {
    setShow(false);
    setUserData(null);
  };

  const [deleteStaff, deleteStaffResponseInfo] =
    useDeleteCouponMutation();
  useEffect(() => {
    if (deleteStaffResponseInfo.isSuccess) {
      message.success("Delete Success")
    }
  }, [deleteStaffResponseInfo]);

  const navigate = useNavigate();
  const {
    data: staff,
    isLoading: loading,
    error: error,
  } = useFetchCouponAdminQuery();
  useEffect(() => {
    if (staff) {
      console.log(staff);
    }
  }, [staff]);
  const editfun = (e, record) => {
    e.stopPropagation();
    setUserData(record);
    setShow(true);
  };
  const deletefun = (e, data) => {
    e.stopPropagation();
    console.log(data)
    deleteStaff(data)
  };
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record, index) => <>{index + 1}</>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "_id",
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "_id",
      width: "20%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "_id",
      width: "20%",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "_id",
      width: "10%",
    },

    // {
    //   title: "Action",
    //   key: "_id",
    //   fixed: "right",
    //   width: "10%",

    //   render: (record) => (
    //     <Button
    //       className="edit-link"
    //       type="primary"
    //       onClick={(e) => editfun(e, record)}
    //     >
    //       Edit
    //     </Button>
    //   ),
    // },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: "10%",

      render: (record) => (
        <Popconfirm title="Sure to delete?" onCancel={(e) => { e.stopPropagation() }} onConfirm={(e) => deletefun(e, record)}>

          <Button onClick={(e) => { e.stopPropagation() }} style={{ color: "red" }}>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const get_data = (data) => {
    console.log(data);

    setUserData(data);
    setShow(true);
  };
  const handlecreate = () => {
    // navigate("/admin/staff/form");
    setShow(true);
  };
  return (
    <div className="body-width-admin">
      <h1
        style={{ textAlign: "center", marginTop: "50px", marginBottom: "20px" }}
      >
        Coupon Table
      </h1>

      <Button
        onClick={() => handlecreate()}
        style={{ marginBottom: "20px" }}
        className="universal-button-atom"
      >
        CREATE COUPON
      </Button>

      <CouponTable
        columns={columns}
        data={staff}
        loading={loading}
        get_data={get_data}
      />
      {show ? <Model userData={userData} Perform_cancel={Perform_cancel} /> : null}

    </div>
  );
};

export default Coupon;
