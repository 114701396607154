import React from 'react'
import './TrackerBar.css'
import { MdOutlineHorizontalRule } from "react-icons/md";
import { FaCircle } from "react-icons/fa";

const TrackerBar = () => {
  return (
    <div className='tracker-bar-container'>
        Shipping Soon
            <div className='tracker-bar-connectivity-icon'>
                <span></span>
                <FaCircle className='tracker-bar-circle' />
            </div>
        Shipped
            <div className='tracker-bar-connectivity-icon'>
                <span></span>
                <FaCircle className='tracker-bar-circle' />
            </div>
        Out for Delivery
            <div className='tracker-bar-connectivity-icon'>
                {/* <div className='tracker-bar-horizontal-line'></div> */}
                <span></span>
                <FaCircle className='tracker-bar-circle' />
            </div>
        Delivered
    </div>
  )
}

export default TrackerBar