import React from 'react'
import CreateEnquiry from './CreateEnquiry';
import { useParams } from 'react-router-dom';
import { useGetContactQuery } from '../../../../store/store';

const MainEnquiry = ({data,Perform_cancel}) => {
    const { id } = useParams();
    // console.log("Data", data);
  const {
    data: contactData,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useGetContactQuery({ _id: data?._id });

  return (
    <div>
      <CreateEnquiry userData={contactData} Perform_cancel={Perform_cancel}/>
    </div>
  )
}

export default MainEnquiry;
