import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useCreateContactMutation, useUpdateContactMutation } from "../../../../store/store";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 15 },
};

const CreateEnquiry = ({ Perform_cancel, userData }) => {
  const [form] = Form.useForm();
  const [createStaff, creatStaffResponseInfo] = useCreateContactMutation();
  const [updateStaff, updateStaffResponseInfo] = useUpdateContactMutation();

  // Handle response messages for creation
  useEffect(() => {
    if (creatStaffResponseInfo.isSuccess) {
      message.success("Enquiry Created");
      Perform_cancel();
    }
    if (creatStaffResponseInfo.isError) {
      message.error("You don't have clearance");
    }
  }, [creatStaffResponseInfo, Perform_cancel]);

  // Handle response messages for update
  useEffect(() => {
    if (updateStaffResponseInfo.isSuccess) {
      message.success("Enquiry Updated");
      Perform_cancel();
    }
    if (updateStaffResponseInfo.isError) {
      message.error("You don't have clearance");
    }
  }, [updateStaffResponseInfo, Perform_cancel]);

  return (
    <div>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={(data) => {
          if (userData) {
            updateStaff(data);
          } else {
            createStaff(data);
          }
        }}
        initialValues={userData} // Populate form fields with userData
        {...layout}
        style={{
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "85%",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Form.Item style={{ display: "none" }} name="_id" />
            <Form.Item label="Name" name="name" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Input placeholder="Enter Name" />
            </Form.Item>
            <Form.Item label="Email" name="email" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Input placeholder="Enter Email Address" />
            </Form.Item>
            <Form.Item label="Mobile Number" name="phone" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Input placeholder="Enter Mobile Number" />
            </Form.Item>
            <Form.Item label="Message" name="message" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
              <Input placeholder="Enter the message" />
            </Form.Item>
          </div>
        </div>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {userData ? "UPDATE" : "CREATE"}
          </Button>
          <Button danger type="primary" style={{ marginLeft: "20px" }} onClick={Perform_cancel}>
            CANCEL
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateEnquiry;
