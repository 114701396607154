import React from 'react'
import CreateCoupon from './CreateCoupon'

const MainCoupon = ({data,Perform_cancel}) => {
  return (
    <div>
      <CreateCoupon userData={data} Perform_cancel={Perform_cancel}/>
    </div>
  )
}

export default MainCoupon
