import React from "react";

const UserTable = () => {
  return (
    <div className="body-width" >
     
    </div>
  );
};

export default UserTable;
