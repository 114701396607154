import React from "react";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./NavbarUser.css";
import { FiShoppingBag } from "react-icons/fi";
import { IoSearchSharp } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/mutation/userSlice";
import { LuLogOut } from "react-icons/lu";
import account from "../../../assets/img/account_circle.png"
import favorite from "../../../assets/img/favorite.png"
import search from "../../../assets/img/search.png"
import shopping from "../../../assets/img/shopping.png"

function NavbarUser() {
  const [click, setclick] = useState(false);
  const dispatch = useDispatch();
  const closeMobileMenu = () => setclick(false);
  const handleClick = () => setclick(!click);

  return (
    <header className="header-sue">
      <div onClick={closeMobileMenu}>
        <img className="my-logo" src="/sueLogo.png" alt="" />
      </div>
      <nav className="navbar">
        <div className="menu-icon" onClick={handleClick}>
          <div className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/shop-by-category"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Shop by Category
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/product-page"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Products
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              to="/gallery"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Gallery
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              to="/contact"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/signup"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Search
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              to="/wishlist-page"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Wishlist
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              to="/cart-page"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Cart
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/profile-page"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Profile
            </NavLink>
          </li>
          <li className="nav-item">
            <div
              className="nav-links-mobile"
              onClick={() => {
                dispatch(logout());
                closeMobileMenu();
              }}
            >
              Logout
            </div>
          </li>
        </ul>
      </nav>
      <div className="navbar-user-buttons">
        <NavLink to="/search">
          <img src={search} className="navbar-user-icon" />
        </NavLink>
        {/* <NavLink to="/wishlist-page">
          <img src={favorite} className="navbar-user-icon" />
        </NavLink> */}
        <NavLink to="/cart-page">
          <img src={shopping} className="navbar-user-icon" />
        </NavLink>
        <NavLink to="/profile-page">
          <img src={account} className="navbar-user-icon" />
        </NavLink>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(logout());
          }}
        >
          <LuLogOut color="#f6b51b" fontSize={"20px"} />
        </div>
      </div>
    </header>
  );
}

export default NavbarUser;
