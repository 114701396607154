import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Select, Switch, Table, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";
import querystring from "querystring";
import {
  useFetchAdminProductQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useFetchTrendingQuery,
} from "../../../store/store";
import {Admin_active, Admin_category, Admin_color, Admin_material, Admin_product_name, Product_page } from "../../../store/mutation/remainingSlice";
import ETable from "../../../components/table/ETable";
import LoadingHv from "../../../atoms/buttons/LoadingHv";
import styled from "styled-components";
import { categoryArray, colorArray, materialNames, order_state, order_state2, paymentArray } from "../../../atoms/static/State";

const Admin_products = () => {
  const fabricStyles_array = [];
  const occassion_array = [];
  const sareeStyles_array = [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admin_product_page, trending_page, product_name, filter_obj, admin_color, admin_category, admin_material, admin_active } = useSelector(
    (state) => state.remaning
  );

  const [activeFilter, setActiveFilter] = useState("All");

  const [updateProduct, updateProductResponseInfo] = useUpdateProductMutation();

  const [
    deleteTrending,
    deleteTrendingResponseInfo,
  ] = useDeleteProductMutation();
  useEffect(() => {
    if (updateProductResponseInfo.isSuccess) {
      message.success("Update Success");
    }
  }, [updateProductResponseInfo]);
  useEffect(() => {
    if (deleteTrendingResponseInfo.isSuccess) {
      message.success("Removed From Trending");
    }
  }, [deleteTrendingResponseInfo]);

  const toggleActive = (data, e, record) => {
    e.stopPropagation();
    const _id = record._id;
    const new_obj = { _id: _id, active: data };
    updateProduct(new_obj);
  };
  const toggleStock = (data, e, record) => {
    e.stopPropagation();
    const _id = record._id;
    const new_obj = { _id: _id, instock: data };
    updateProduct(new_obj);
  };
  const deletefun = (e, record) => {
    e.stopPropagation();
    deleteProduct(record);
  };
  const editfun = (e, record) => {
    e.stopPropagation();
    // console.log(record)
    const _id = record._id;
    const new_obj = { _id: _id, trending: true };
    updateProduct(new_obj);
    console.log(record);
    // navigate(`/admin/products/create/${record._id}`)
  };
  const visit = (record) => {
    console.log(record);
    navigate(`/admin/products/create/${record._id}`);
  };
  const filterobj = {};
  const [search, setSearch] = useState("");

  const handleClick = () => {
    console.log(search);
    dispatch(Admin_product_name(search));
  };

  const {
    data: data,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useFetchAdminProductQuery({
    filterobj,
    admin_product_page,
    product_name,
    admin_color,
    admin_category,
    admin_material,
    admin_active
  });
  const {
    data: trending,
    isLoading: trending_loading,
  } = useFetchTrendingQuery();

  const [deleteProduct, deleteProductResponseInfo] = useDeleteProductMutation();
  useEffect(() => {
    if (deleteProductResponseInfo.isSuccess) {
      message.success("Delete SuccessFull");
    }
  }, [deleteProductResponseInfo]);
  useEffect(() => {
    if (updateProductResponseInfo.isSuccess) {
      message.success("Update SuccessFull");
    }
  }, [updateProductResponseInfo]);
  const remove_trending = (e, record) => {
    e.stopPropagation();
    console.log(record);
    const _id = record._id;
    const new_obj = { _id: _id, trending: false };
    updateProduct(new_obj);
    // deleteTrending(data);
  };
  const columns = [
    {
      title: "Sr.no",
      dataIndex: "_id",
      key: "_id",
      width: 70,
      render: (text, record, index) => {
        return <span>{admin_product_page * 10 - 10 + index + 1}</span>;
      },
      //   ...getColumnSearchProps('name'),
    },
    // {
    //   title: "Product ID",
    //   dataIndex: "_id",
    //   key: "_id",
    //   width: 150,
    // },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "_id",
      width: "10%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "_id",
      width: "10%",
      render: (price) => <span>₹ {price}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "_id",
      width: "10%",
      render: (discount) => <span>₹ {discount}</span>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "_id",
      width: 150,
    },
    {
      title: "Special",
      dataIndex: "display",
      key: "_id",
      width: 150,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "_id",
      width: 150,
    },

    {
      title: "InStock",
      dataIndex: "instock",
      key: "_id",
      width: "7%",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(data, e) => toggleStock(data, e, record)}
        />
      ),
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "_id",
      width: "7%",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(data, e) => toggleActive(data, e, record)}
        />
      ),
    },

    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: 100,

      render: (record) => (
        <Button className="edit-link" onClick={(e) => editfun(e, record)}>
          Add To Trending
        </Button>
      ),
    },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: 100,

      render: (record) => (
        <Popconfirm
          title="Sure to delete?"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          onConfirm={(e) => deletefun(e, record)}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const columns_trending = [
    {
      title: "Sr.no",
      dataIndex: "_id",
      key: "_id",
      width: 70,
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
      //   ...getColumnSearchProps('name'),
    },
    // {
    //   title: "Product ID",
    //   dataIndex: "_id",
    //   key: "_id",
    //   width: 150,
    // },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "_id",
      width: 250,

    },
    {
      title: "Price",
      dataIndex: "price",
      key: "_id",
      width: "10%",
      render: (price) => <span>₹ {price}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "_id",
      width: "10%",
      render: (discount) => <span>₹ {discount}</span>,
    },
    {
      title: "Special",
      dataIndex: "display",
      key: "_id",
      width: 150,
    },
    {
      title: "Product Type",
      dataIndex: "category",
      key: "_id",
      width: 150,
    },
    {
      title: "InStock",
      dataIndex: "instock",
      key: "_id",
      width: "7%",
      render: (active, record) => (
        <Switch
          checked={active}
          onChange={(data, e) => toggleStock(data, e, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: 100,

      render: (record) => (
        <Popconfirm
          title="Sure to remove?"
          onCancel={(e) => {
            e.stopPropagation();
          }}
          onConfirm={(e) => remove_trending(e, record)}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ color: "red" }}
          >
            Remove
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handlecreate = () => {
    navigate("/admin/products/create");
  };

  const getColor = (data) => {
    console.log(data)
    if (data === "All Colors") {
      dispatch(Admin_color(""))

    } else {
      dispatch(Admin_color(`&color=${data}`))

      // queryParams.product_occasion = data;

      // const queryString = querystring.stringify(queryParams);

      // console.log(queryString);
      // dispatch(Filter_obj(queryString));
    }
  };

  const getMaterial = (data) => {
    console.log(data);
    if (data === "All Materials") {
      dispatch(Admin_material(""))

    } else {
      dispatch(Admin_material(`&material=${data}`))

      // queryParams.product_occasion = data;

      // const queryString = querystring.stringify(queryParams);

      // console.log(queryString);
      // dispatch(Filter_obj(queryString));
    }
  };

 const getActive = (data) => {
  console.log(data)
  if (data === "Active") {
    dispatch(Admin_active(""));
  } else {
    dispatch(Admin_active(`&active=${data}`));
  }
 };

  const handleActiveFilterChange = (checked) => {
    setActiveFilter(checked ? "Active" : "Inactive");
    // Apply filtering based on the active status here
    getActive(checked ? "Active" : "");
  };

  const getStyle = (data) => {
    console.log(data);
    if (data === "All Categorys") {
      dispatch(Admin_category(""))
      // dispatch(Filter_obj(queryString));
    } else {
      dispatch(Admin_category(`&category=${data}`))

      // dispatch(Filter_obj(queryString));
    }
  };
  // const getFabric = (data) => {
  //   if (data === "All Fabrics") {

  //     dispatch(Filter_obj(""));
  //   } else {

  //     dispatch(Filter_obj(`&fabric=${data}`));
  //   }
  // };
  return (
    <Style>
      <div className="body-width-admin" style={{ marginTop: "100px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => handlecreate()}
            className="universal-button-atom"
          >
            CREATE PRODUCTS
          </Button>
          <div style={{ display: "flex", marginLeft: "30px" }}>
            <input
              className="search-pr-place"
              onChange={(e) => setSearch(e.target.value)} placeholder="Search Your Product"
              style={{
                width: "500px",
                borderRadius: "none!important",
                height: "50px",
                padding: "20px",
              }}
              type="text"
            />
            <p
              className="all-center-flex"
              style={{
                background: "var(--pr-text-color)",
                padding: "0px 25px",
                cursor: "pointer",
              }}
              onClick={() => handleClick()}
            >
              <FaSearch color="white" />
            </p>
          </div>
        </div>
        <div className="admin-select-product">
          <Select

            style={{ width: "200px" }}
            placeholder="Select Category"
            showSearch
            defaultValue={"All Categorys"}
            onSelect={getStyle}
          >
            {categoryArray?.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
          <Select
            multiple

            style={{ width: "200px" }}
            placeholder="Select Colors"
            showSearch
            defaultValue={"All Colors"}
            onSelect={getColor}
          >
            {colorArray?.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>

          <Select
            style={{ width: "200px" }}
            placeholder="Select Material"
            showSearch
            defaultValue={"All Materials"}
            onSelect={getMaterial}
          >
            {materialNames.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
          <span style={{ marginRight: "10px" }}>Active Products</span>
          <Switch checked={activeFilter === "Active"} onChange={handleActiveFilterChange} />
        </div>

        <div style={{ margin: "20px 0px" }}>
          {data ? (
            <ETable
              size={"small"}
              columns={columns}
              data={data}
              page_size={10}
              loading={loading || updateProductResponseInfo.isLoading}
              product_page={admin_product_page}
              visit={visit}
              page100={"admin-product"}
            />
          ) : (
            <LoadingHv />
          )}
        </div>
        <div style={{ margin: "20px 0px" }}>
          <h1 style={{ marginBottom: "10px", textAlign: "center" }}>
            Trending Products
          </h1>
          <div style={{ margin: "20px 0px" }}>
            {trending ? (
              <Table
                columns={columns_trending}
                dataSource={trending}
                size="small"
                loading={trending_loading}
              ></Table>
            ) : (
              <LoadingHv />
            )}
          </div>
          {/* <ETable columns={columns} data={data} loading={loading||updateProductResponseInfo.isLoading} product_page={product_page} visit={visit} page100={"product"}/> */}
          {/* <Table
          columns={columns_trending}
          dataSource={trending}
          loading={trending_loading}
        ></Table> */}
        </div>
      </div>
    </Style>
  );
};

export default Admin_products;


const Style = styled.div`
margin: 4rem 10rem;
.ant-table-wrapper .ant-table.ant-table-small {
  font-size: 13px !important;
  font-weight: 500;
}
.ant-btn {
  font-size: 12px;
  line-height: 1.5714285714285714;
}
.ant-switch {
  display: inline-block;
  min-width: 35px;
  height: 15px;
}
.ant-switch .ant-switch-handle {
  width: 12px;
  height: 12px;
}
`