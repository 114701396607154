import { Button, Form, Input, Switch, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect, useState } from "react";
import Hr from "../../../../atoms/buttons/Hr";
import TextArea from "antd/es/input/TextArea";
import { useUpdateUserMutation } from "../../../../store/store";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../store/mutation/userSlice";
import { validateEmail, validatePhoneNumber } from "../../../../atoms/static/StateFun";

const onFinish = (data, shipping, updateUser) => {
  if (shipping) {
    const newData = { ...data, shipping: { ...data.billing } };

    updateUser(newData);
  } else {
    updateUser(data);
  }
};
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 20 },
};
const EditForm = ({ user, setEditState }) => {
  const [updateUser, updateUserResponseInfo] = useUpdateUserMutation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (updateUserResponseInfo?.isSuccess) {
      message.success("User Data Saved");
      dispatch(getUser());
      setEditState(false);
    }
  }, [updateUserResponseInfo]);
  const [form] = Form.useForm();
  const labelCol = 0;
  const [shipping, setShipping] = useState(false);
  return (
    <>
      <FormItem
        labelCol={labelCol}
        label="Have Same Billing And Shipping Address"
        name="special"
      >
        <Switch value={shipping} onClick={() => setShipping(!shipping)} />
      </FormItem>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={(data) => {
          onFinish(data, shipping, updateUser);
        }}
        style={{
          maxWidth: "100%",
        }}
        // autoComplete="off"
        {...layout}
        initialValues={user}
        // initialValues={thisone}
      >
        <div
          style={{
            // display: "flex",
            justifyContent: "space-between",
            // width: "85%",
            margin: "auto",
          }}
        >
          <div
            style={{
              //   display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <h3
              style={{
                textAlign: "center",
                background: "var(--pr-text-color)",
                color: "var(--white)",
              }}
            >
              Account Details
            </h3>
            <h2>{user?.name}</h2>
            <h5>{user?.email}</h5>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "85%",
            margin: "auto",
          }}
        ></div>
        {!shipping ? (
          <h3
            style={{
              textAlign: "center",
              background: "var(--pr-text-color)",
              color: "var(--white)",
            }}
          >
            Billing Details
          </h3>
        ) : null}
        <div
          style={{
            // display: "flex",
            justifyContent: "space-between",
            // width: "85%",
            margin: "auto",
            // border:"1px solid lightgrey",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              //   display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <FormItem
              labelCol={labelCol}
              label="Name"
              name={["billing", "name"]}
            >
              <Input
              required

                className="create-product-input-from-widthvw"
                placeholder="Enter Name"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Email"
              name={["billing", "email"]}
              rules={[{ required: true, message: 'Please enter your email' }, { validator: validateEmail }]}
            >
              <Input
              
              

                className="create-product-input-from-widthvw"
                placeholder="Enter Email"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Address"
              name={["billing", "address"]}
              span={10}
            >
              <TextArea
                className="create-product-input-from-widthvw"
                placeholder="Enter Address"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Phone"
              name={["billing", "phone"]}
              rules={[{ required: true, message: 'Please enter your phone number' }, { validator: validatePhoneNumber }]}
            >
              <Input
                className="create-product-input-from-widthvw"
                placeholder="Enter Phone No."
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="City"
              name={["billing", "city"]}
            >
              <Input
                className="create-product-input-from-widthvw"
                placeholder="Enter City"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="State"
              name={["billing", "state"]}
            >
              <Input
                className="create-product-input-from-widthvw"
                placeholder="Enter State"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Pincode"
              name={["billing", "pincode"]}
            >
              <Input
                className="create-product-input-from-widthvw"
                placeholder="Enter Pincode"
              />
            </FormItem>
          </div>
        </div>
        <Hr />
        <div style={{ marginTop: "20px" }}>
          {/* <FormList name="product_highlight" style={{ width: "100%" }}>
          {(fields, { add, remove }) => (
            <div className="form-list-admin-highlights-add">
              {fields.map(({ key, name, ...restField }, index) => (
                <Space
                  key={key}
                  style={{
                    // display: "flex",
                    marginBottom: "50px",
                    marginLeft: "50px",
                    padding: "20px",
                    flexDirection: "column",
                    background: "#e5e5e5",
                    // width:"100%",
                    boxShadow: " 0px 0px 2px gray inset",
                  }}
                  align="baseline"
                  className="quotation-form-list"
                >
                  <Row className="from-row">
                    <Form.Item
                      {...restField}
                      name={[name, "title"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Item Name",
                        },
                      ]}
                      style={{ width: "100%" }}
                      wrapperCol={wrapperCol}
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                  </Row>

                  <Form.Item
                    {...restField}
                    name={[name, "desc"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing first name",
                      },
                    ]}
                    wrapperCol={wrapperCol}
                  >
                    <Input placeholder="Description" />
                  </Form.Item>

                  <Button
                    danger
                    style={{ background: "red", color: "white" }}
                    className="c"
                    onClick={() => remove(name)}
                  >
                    Delete
                  </Button>
                </Space>
              ))}
              <FormItem>
                <Button
                  style={{ width: "100%" }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Product HighLight
                </Button>
              </FormItem>
            </div>
          )}
        </FormList> */}
        </div>
        <Hr />
        {!shipping ? (
          <>
            <h3
              style={{
                textAlign: "center",
                background: "var(--pr-text-color)",
                color: "var(--white)",
              }}
            >
              Shipping Details
            </h3>
            <div
              style={{
                // display: "flex",
                justifyContent: "space-between",
                // width: "85%",
                margin: "auto",
                // border:"1px solid lightgrey",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  //   display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "end",
                }}
              >
                <FormItem
                  labelCol={labelCol}
                  label="Name"
                  name={["shipping", "name"]}
                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter Name"
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="Email"
                  name={["shipping", "email"]}
              rules={[{ required: true, message: 'Please enter your email' }, { validator: validateEmail }]}

                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter Email"
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="Address"
                  name={["shipping", "address"]}
                  span={10}
                >
                  <TextArea
                    className="create-product-input-from-widthvw"
                    placeholder="Enter Address"
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="Phone"
                  name={["shipping", "phone"]}
                  rules={[{ required: true, message: 'Please enter your phone number' }, { validator: validatePhoneNumber }]}
                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter Phone No."
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="City"
                  name={["shipping", "city"]}
                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter City"
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="State"
                  name={["shipping", "state"]}
                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter State"
                  />
                </FormItem>
                <FormItem
                  labelCol={labelCol}
                  label="Pincode"
                  name={["shipping", "pincode"]}
                >
                  <Input
                    className="create-product-input-from-widthvw"
                    placeholder="Enter Pincode"
                  />
                </FormItem>
              </div>
            </div>
          </>
        ) : null}

        <FormItem name="_id"></FormItem>

        {/* <Button onClick={Submitimg}>Upload</Button> */}
        <FormItem>
          <Button
            // loading={true}
            // style={{ height: "100%", background: "var(--pr-color) " }}
            loading={false}
            type="primary"
            htmlType="submit"
          >
            SUBMIT
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

export default EditForm;
