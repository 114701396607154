import React from 'react';
import './DiscoverPart.css';
import { Link } from 'react-router-dom';

const DiscoverPart = () => {
  return (
    <div className='discover-part-container'>
      <div className='left-side-container'> 
        <h1 className='discover-part-heading'>
          Discover the ultimate in office comfort
        </h1>
        <p className='discover-part-text'>
          The Executive Chair. Combining style, comfort, and functionality, this chair
          is designed to meet the demands of your dynamic workspace. With its sleek silhouette
          and plush cushioning, it offers unparalleled support for long hours of productivity.
        </p>
        <button className='shop-now-button-container'>
          <Link to='/product-page'>Shop Now</Link>
        </button>
      </div>

      <div  className="chair-image-container">
        <img className='chair-image' src='chairImage.png' alt=''/>
      </div>
    </div>
  )
}

export default DiscoverPart