import React from "react";
import TrackerBar from "../TrackerBar";
import {
  DiscountFun,
  IDate,
  SumOfOrder,
  SumOfOrderWithDiscount,
  createSlug,
} from "../../../../atoms/static/StateFun";
import { useNavigate } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import Hr from "../../../../atoms/buttons/Hr";

const OrderProductTable = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="address-page-main-part-address-details">
        <div className="address-page-main-part-address-name"> {data?.name}</div>
        <div className="address-page-main-part-address-text">{data?.email}</div>
        <Hr />
        <b>Billing Details</b>
        <div className="address-page-main-part-address-pincode">
          <b>Name :</b> {data?.billing?.name} <b>Phone :</b>{" "}
          {data?.billing?.phone}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>State :</b> {data?.billing?.state} <b>City :</b>{" "}
          {data?.billing?.city}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Email :</b> {data?.billing?.email}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Addresss :</b> {data?.billing?.address}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Pincode :</b> {data?.billing?.pincode}
        </div>
        <Hr />

        <b>Shipping Details</b>

        <div className="address-page-main-part-address-pincode">
          <b>Name :</b> {data?.shipping?.name} <b>Phone :</b>{" "}
          {data?.shipping?.phone}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>State :</b> {data?.shipping?.state} <b>City :</b>{" "}
          {data?.shipping?.city}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Email :</b> {data?.shipping?.email}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Addresss :</b> {data?.shipping?.address}
        </div>
        <div className="address-page-main-part-address-pincode">
          <b>Pincode :</b> {data?.shipping?.pincode}
        </div>
      </div>
      <ul>
        <li>
          <p>{data?.stage}</p>
          {/* on Tuesday, 6 Feb */}
          on {IDate(data?.created_at)}
          {/* <div className="carousel-card-price-product-page">
            <span className="span-price-product-page">
              Rs. {SumOfOrder(data)}
            </span>
            {"  "}
            Rs. {SumOfOrderWithDiscount(data)}
            <span
              className="discount-rate-product-page"
              style={{ color: "green" }}
            >
              SAVED Rs.
              {parseInt(SumOfOrder(data) - SumOfOrderWithDiscount(data))}
            </span>
          </div> */}

<div className="carousel-card-price-product-page">
                      <span className="span-price-product-page">
                        Rs. {SumOfOrder(data)}
                      </span>
                      {"  "}
                      {data?.couponDiscount > 0 ? (
                        <>
                          Coupon Discount {data?.couponDiscount} %
                          <span className="span-price-product-page">
                            Rs. {parseInt(SumOfOrderWithDiscount(data))}
                          </span>
                          {"  "}
                          <>
                            Rs.{" "}
                            {DiscountFun(
                              parseInt(SumOfOrderWithDiscount(data)),
                              parseInt(data?.couponDiscount)
                            )}
                          </>
                        </>
                      ) : (
                        <>Rs. {parseInt(SumOfOrderWithDiscount(data))}</>
                      )}
                      <span
                        className="discount-rate-product-page"
                        style={{ color: "green" }}
                      >
                        SAVED Rs.
                        {parseInt(
                          parseInt(
                            SumOfOrder(data) - DiscountFun(
                              parseInt(SumOfOrderWithDiscount(data)),
                              parseInt(data?.couponDiscount)
                            )
                          )
                        )}
                      </span>
                    </div>
          {data?.product?.map((item) => (
            <>
              <div className="each-order-detail-profile-page">
                <div className="order-detail-profile-page-inner-div">
                  <img
                    src="/chair8.png"
                    className="image-each-order-detail-profile-page"
                    alt=""
                  />
                  <div className="vertical-line-each-order-detail-profile-page"></div>
                  <div className="product-details-each-order-detail-profile-page">
                    <div style={{ fontSize: "16px" }}>
                      {item?.product?.name}{" "}
                      <b style={{ marginLeft: "20px", color: "red" }}>
                        X({item?.quantity})
                      </b>
                    </div>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {item?.product?.category}{" "}
                      <FaCircle
                        style={{
                          color: item?.color,
                          marginLeft: "10px",
                        }}
                      />
                    </span>
                    <div className="buttons-container-each-order-detail-profile-page">
                      <button
                        onClick={() =>
                          navigate(`/product-details-page/${createSlug(item?.product?.name)}-${item?.product_id}`)
                        }
                      >
                        View
                      </button>
                      {/* <button>Return</button> */}
                    </div>
                    <div className="carousel-card-price-product-page">
                      <span className="span-price-product-page">
                        Rs. {item.price}
                      </span>
                      {"  "}
                      Rs. {DiscountFun(item.price, item.discount)}
                      <span
                        className="discount-rate-product-page"
                        style={{ color: "green" }}
                      >
                        SAVED Rs.
                        {parseInt(
                          item.price - DiscountFun(item.price, item.discount)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <TrackerBar />
              </div>
            </>
          ))}
        </li>
      </ul>
    </>
  );
};

export default OrderProductTable;
