import { useEffect } from "react";
import React from "react";
import ChairImage_Description from "../../../components/organisms/Product_DetailsPage_Components/ChairImage_Description";
import DescriptionWarrantyReviews from "../../../components/organisms/Product_DetailsPage_Components/DescriptionWarrantyReviews";
import RelatedProducts from "../../../components/organisms/Product_DetailsPage_Components/RelatedProducts";
import CommonQuote from "../../../components/organisms/Product_DetailsPage_Components/CommonQuote";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetProductQuery,
  useGetRelatedProductQuery,
} from "../../../store/store";
import LoadingHv from "../../../atoms/buttons/LoadingHv";
import WeAlsoSell from "../../../components/organisms/HomePage_Components/WeAlsoSell";
import { getId } from "../../../atoms/static/StateFun";

const ProductDetailsPage = () => {
  const {id}=useParams()
  const Getid=getId(id)
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [id]);
  const {
    data: data,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useGetProductQuery({ _id: Getid });
  const {
    data: related,
    isLoading: related_loading,
    isFetching: related_fetch,
  } = useGetRelatedProductQuery({ name: Getid });

  const data2 = {
    name: "Chair",
    category: "Furniture",
    wheels: false,
    special: false,
    instock: true,
    active: true,
    color: ["Red", "Blue", "Green"],
    image: "https://example.com/chair.jpg", //this will be main image
    multi_img: [
      "https://example.com/chair1.jpg",
      "https://example.com/chair2.jpg",
    ], //these are optional images
    seoArray: ["Chair", "Furniture", "Comfort"],
    suitableFor: "Home",
    rating: 4.5,
    chair: {
      material: "Wood",
      cover: "Fabric",
      backCushion: "Yes",
      armRest: "Adjustable",
      base: "Metal",
      mech: "Swivel",
      gaslift: "Yes",
      maxLoad: "150 kg",
      centerBeam: "Yes",
    },
    size: {
      totalHeight: 100,
      seatHeight: 50,
      length: 80,
      width: 60,
      unit: "cm",
    },
    description: "A comfortable chair for your home or office.",
    stage: "New",
    price: 150, // Rounded up to the nearest whole number
    discount: 20, // Rounded up to the nearest whole number
    moreFeatures: [
      {
        title: "Adjustable Height",
        description:
          "The chair's height can be adjusted according to your preference.",
      },
      {
        title: "Ergonomic Design",
        description:
          "Designed to provide optimal comfort and support during prolonged sitting.",
      },
    ],
  };
  return (
    <>
      {!loading&&data ? <ChairImage_Description product={data} id={Getid} /> : <LoadingHv />}
      {/* <ChairImage_Description product={data} />  */}
      {!related_loading &&related? <DescriptionWarrantyReviews product={data}/> : <LoadingHv />}

      {/* <ChairImage_Description product={data} id={id} /> */}
      {/* <DescriptionWarrantyReviews product={data} /> */}

      {/* <RelatedProducts /> */}
      {related_loading?<LoadingHv/>:

      <WeAlsoSell title={"Related Products"} data={related}/>
      }
      <CommonQuote />
    </>
  );
};

export default ProductDetailsPage;
