import React, { useEffect } from "react";
import "./MainDetailsCartPage.css";
import { Link, useNavigate } from "react-router-dom";
import { IoIosCheckboxOutline, IoMdExit } from "react-icons/io";
import LoadingHv from "../../../atoms/buttons/LoadingHv";
import {
  SumOfCart,
  SumOfCartWithDiscount,
  createSlug,
} from "../../../atoms/static/StateFun";
import { FaCircle, FaCross } from "react-icons/fa";
import { CloseOutlined } from "@ant-design/icons";
import { useDeleteCartMutation, useUpdateCartMutation } from "../../../store/store";
import { message } from "antd";
import { useSelector } from "react-redux";
import OrderSummaryBox from "./OrderSummaryBox";

const MainDetailsCartPage = ({ cart }) => {
  const [createCart, deleteCartResponseInfo] = useDeleteCartMutation();
  const [updateCart, updateCartResponseInfo] = useUpdateCartMutation();
  
  const navigate = useNavigate();
  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const RemoveCart = (data) => {
    createCart({ _id: data?._id, user_id: user?._id });
  };
  useEffect(() => {
    if (deleteCartResponseInfo?.isSuccess) {
      message.success("Removed From Cart");
    }
    if (deleteCartResponseInfo?.isError) {
      message.error("Cant Remove From Cart");
    }
  }, [deleteCartResponseInfo]);
  useEffect(() => {
    if (updateCartResponseInfo?.isSuccess) {
      message.success("Cart Updated");
    }
    if (updateCartResponseInfo?.isError) {
      message.error("Cart Not Updated");
    }
  }, [updateCartResponseInfo]);
  const handleQuantityChange=(data,id)=>{
console.log(data,id)
const structure={
  _id:id,
  quantity:data,
  user_id:user?._id
}
updateCart(structure)
  }
  return (
    <div className="main-details-cart-page-container">
      {cart ? (
        <div className="cart-page-left-container">
          <div className="cart-page-left-first-box">
            Check Delivery Time and Services
            {/* <Link className="enter-pin-code-link">Enter Pin Code</Link> */}
          </div>

          <div className="cart-page-left-second-box">
            <div className="cart-page-left-icon-selected">
              <IoIosCheckboxOutline className="cart-page-left-check-icon" />
              1/1 Items Selected
            </div>

            <div className="cart-page-remove-wishlist">
              Remove | Move to Wishlist
            </div>
          </div>

          <div className="cart-page-left-third-box">
            <div className="cart-page-left-third-table-headings">
              Product
              <div className="cart-page-left-third-table-rest">
                <ul>
                  <li>Price</li>
                  <li>Quantity</li>
                  <li>Total</li>
                </ul>
              </div>
            </div>
            <div className="seperating-line-container">
              <div className="seperating-line"></div>
            </div>

            {cart?.map((x) => (
              <div
                className="cart-page-left--item"
                onClick={() =>
                  navigate(`/product-details-page/${createSlug(x?.product?.name)}-${x?.product_id}`)
                }
              >
                <div className="cart-page-left-item-first-part">
                  <IoIosCheckboxOutline className="cart-page-left-third-check-icon" />
                  {x?.product?.image ? (
                    <img
                      className="cart-left-page-item-image"
                      src={x?.product?.image}
                      alt=""
                    />
                  ) : (
                    <img
                      className="cart-left-page-item-image"
                      src={
                        "https://imgs.search.brave.com/IoOvviQ4di6rWn-a5_3PxljCQRXg5AQco4id07WyC3o/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9waG90by1tb2Rl/cm4tbHV4dXJ5LWFy/bS1jaGFpci1mdXJu/aXR1cmUtZGVzaWdu/Xzc2MzExMS0yMjEx/Ni5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
                      }
                      alt=""
                    />
                  )}
                  <div className="cart-page-left-vertical-line-container">
                    <div className="cart-page-left-vertical-line"></div>
                  </div>
                  <div className="cart-page-left-item-name">
                    {x?.product?.name}
                    <span style={{ color: "red" }}>{x?.product?.display} </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {x?.product?.category}{" "}
                      <FaCircle
                        style={{
                          color: x?.color,
                          marginLeft: "10px",
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="cart-page-left-item-second-part">
                  <p>{x?.product?.price}</p>
                  <p style={{cursor:"pointer"}} onClick={(e)=>e.stopPropagation()}> <select
                    className="chair-image-description-dropdown"
                    style={{cursor:"pointer"}} 
                    id="quantity"
                    value={x?.quantity} 
                    onChange={(data)=>handleQuantityChange(data.target.value,x?._id)}
                  >
                    {[...Array(10)].map((_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select></p>
                 
                  {/* <p>{x?.quantity}</p> */}
                  <p>{x?.product?.price * x?.quantity}</p>
                </div>
                <p
                  className="p--cross--custom"
                  onClick={(e) => {
                    e.stopPropagation();
                    RemoveCart(x);
                  }}
                >
                  <CloseOutlined />
                </p>
              </div>
            ))}

            <div className="seperating-line-container">
              <div className="seperating-line"></div>
            </div>
            <div className="cart-page-left-subtotal-container">
              <div className="cart-page-left-subtotal">
                <p>
                  Subtotal({cart?.length} items): Rs.{SumOfCart(cart)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "65%" }}>
          <LoadingHv />
        </div>
      )}

      <OrderSummaryBox cart={cart} />
    </div>
  );
};

const cartPageItem = [
  {
    img: "/chair6.png",
    name: "Martin",
    category: "Premium Executive Chairs",
    price: "Rs.1399",
    Total: "Rs.1399",
    quantity: "1",
  },

  {
    img: "/chair6.png",
    name: "Arico",
    category: "Premium Executive Chairs",
    price: "Rs.1299",
    Total: "Rs.2598",
    quantity: "2",
  },
];

export default MainDetailsCartPage;
