import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./url";

const allApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    "Product",
    "Coupon",
    "Trending",
    "User",
    "Cart",
    "Wishlist",
    "Staff",
    "Order",
    "CartQuantity",
    "Review",
    "Staff",
    "Payment",
    "Mail",
    "Contact",
  ], //refresh when it innvalidates
  endpoints(build) {
    return {
      payNow: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/v1/payment/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Payment", _id: arg._id },
        ],
      }),
      updateUser: build.mutation({
        query: (createJobcardData) => {
          const { _id, token } = createJobcardData;
          const user = `Bearer ${localStorage.getItem("userToken")}`;
          const admin = `Bearer ${localStorage.getItem("adminToken")}`;

          return {
            url: `/auth/user/${_id}`,
            method: "PATCH",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: token ? `Bearer ${token}` : admin ? admin : user,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "User", _id: arg._id },
        ],
      }),
      createUser: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/auth/register/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "User", _id: arg._id },
        ],
      }),

      createProduct: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/product/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Product", _id: arg._id },
        ],
      }),
      createCoupon: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/coupon/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Coupon", _id: arg._id },
        ],
      }),
      createReview: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/review/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Review", _id: arg._id },
        ],
      }),
      updateProduct: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;
          return {
            url: `/api/product/${_id}`,
            method: `PATCH`,
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Product", _id: arg._id },
          { type: "Trending", _id: arg._id },
        ],
      }),
      updateCoupon: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;
          return {
            url: `/api/coupon/${_id}`,
            method: `PATCH`,
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Coupon", _id: arg._id },
        ],
      }),
      deleteProduct: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;

          return {
            url: `/api/product/${_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Product", _id: arg._id },
        ],
      }),

      fetchProduct: build.query({
        query: ({ page, filter, product_name }) => {
          return {
            url: `/api/product/?limit=9&page=${page}${filter ? filter : ""}${product_name ? `&product_name=${product_name}` : ""}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.data?.length
            ? [
              ...result?.data?.map(({ id }) => ({ type: "Product", id })),
              "Product",
            ]
            : ["Product"],
      }),
      fetchTrending: build.query({
        query: () => {
          return {
            // url: `/api/product/trending/${filter ? filter : ""}`,
            url: `/api/product/trending/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
              ...result?.map(({ id }) => ({ type: "Trending", id })),
              "Trending",
            ]
            : ["Trending"],
      }),
      fetchSuggestions: build.query({
        query: () => {
          return {
            url: `/api/suggestions-product`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
      }),
      fetchReview: build.query({
        query: ({ product_id }) => {
          return {
            url: `/api/review/?product_id=${product_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Review", id })), "Review"]
            : ["Review"],
      }),
      fetchCoupon: build.query({
        query: () => {
          return {
            url: `/api/coupon/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Coupon", id })), "Coupon"]
            : ["Coupon"],
      }),
      fetchCouponAdmin: build.query({
        query: () => {
          return {
            url: `/api/coupon/admin/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Coupon", id })), "Coupon"]
            : ["Coupon"],
      }),
      fetchAdminProduct: build.query({
        query: ({ admin_product_page, admin_color, admin_category, product_name, admin_material, admin_active }) => {
          return {
            url: `/api/admin-product/?limit=10&page=${admin_product_page}${admin_color}${admin_category}${admin_material}${admin_active}${product_name ? `&product_name=${product_name}` : ""}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.data?.length
            ? [
              ...result?.data?.map(({ id }) => ({ type: "Product", id })),
              "Product",
            ]
            : ["Product"],
      }),
      getProduct: build.query({
        query: ({ _id }) => {
          return {
            url: `/api/product/${_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
      }),
      getRelatedProduct: build.query({
        query: ({ name }) => {
          if (name) {
            return {
              url: `/api/related-product/${name}`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            };
          }
        },
      }),
      getAdminProduct: build.query({
        query: ({ _id }) => {
          return {
            url: `/api/admin-product/${_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
      }),

      dummyRun: build.mutation({
        query: () => {
          return {
            success: "200",
          };
        },
        invalidatesTags: ["Product"],
      }),
      fetchUser: build.query({
        query: () => {
          return {
            url: "/auth/user/?limit=10&page=1",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.data?.length
            ? [...result?.data?.map(({ id }) => ({ type: "User", id })), "User"]
            : ["User"],
      }),
      fetchStaff: build.query({
        query: () => {
          return {
            url: "/auth/staff/",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.data?.length
            ? [
              ...result?.data?.map(({ id }) => ({ type: "Staff", id })),
              "Staff",
            ]
            : ["Staff"],
      }),
      createStaff: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/auth/admin/register`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Staff", _id: arg._id },
        ],
      }),
      updateStaff: build.mutation({
        query: (createJobcardData) => {
          const { _id } = createJobcardData;
          return {
            url: `/auth/staff/${_id}`,
            method: "PATCH",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Staff", _id: arg._id },
        ],
      }),
      forgotMail: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/auth/forgot`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")
                  ? localStorage.getItem("adminToken")
                  : localStorage.getItem("userToken")
                }`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Mail", _id: arg._id },
        ],
      }),
      cancelOrder: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/order/cancel`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Order", _id: arg._id },
        ],
      }),
      failOrder: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/order/failed`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Order", _id: arg._id },
        ],
      }),
      successOrder: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/order/success`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Order", _id: arg._id },
          { type: "Cart", _id: arg._id },
        ],
      }),
      deleteOrderForUser: build.mutation({
        query: (createJobcardData) => {
          const { order_id, ...data } = createJobcardData;
          return {
            url: `/api/order/user/${order_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Order", _id: arg._id },
        ],
      }),
      createCart: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/api/cart/`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Cart", _id: arg._id },
        ],
      }),
      updateCart: build.mutation({
        query: (createJobcardData) => {
          const { _id, user_id, ...data } = createJobcardData;
          return {
            url: `/api/cart/${user_id}`,
            method: `PATCH`,
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Cart", _id: arg._id },
        ],
      }),
      deleteCart: build.mutation({
        query: (createJobcardData) => {
          const { user_id, ...data } = createJobcardData;

          return {
            url: `/api/cart/${user_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Cart", _id: arg._id },
        ],
      }),
      deleteReview: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;

          return {
            url: `/api/review/${_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Review", _id: arg._id },
        ],
      }),
      deleteCoupon: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;

          return {
            url: `/api/coupon/${_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Coupon", _id: arg._id },
        ],
      }),
      updateOrderStage: build.mutation({
        query: (createJobcardData) => {
          const { order_id, ...data } = createJobcardData;

          return {
            url: `/api/order/stage/${order_id}`,
            method: "PATCH",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Order", _id: arg._id },
        ],
      }),

      fetchCart: build.query({
        query: ({ _id }) => {
          return {
            url: `/api/cart/${_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Cart", id })), "Cart"]
            : ["Cart"],
      }),
      fetchOrder: build.query({
        query: ({ _id }) => {
          return {
            url: `/api/order/${_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Order", id })), "Order"]
            : ["Order"],
      }),
      fetchAdminOrder: build.query({
        query: ({ order_page, sort, orderSTATE, paymentSTATE }) => {
          return {
            url: `/api/order/?limit=10&page=${order_page}&sort=${sort}${paymentSTATE}${orderSTATE}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.data?.length
            ? [
              ...result?.data?.map(({ id }) => ({ type: "Order", id })),
              "Order",
            ]
            : ["Order"],
      }),
      createContact: build.mutation({
        query: (createJobcardData) => {
          return {
            url: `/contacts`,
            method: "POST",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${localStorage.getItem("userToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", _id: arg._id },
        ],
      }),
      updateContact: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;

          return {
            url: `/contacts/${_id}`,
            method: "PUT",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", _id: arg._id },
        ],
      }),
      deleteContact: build.mutation({
        query: (createJobcardData) => {
          const { _id, ...data } = createJobcardData;

          return {
            url: `/contacts/${_id}`,
            method: "DELETE",
            body: createJobcardData,
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${localStorage.getItem("adminToken")}`,

              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Contact", _id: arg._id },
        ],
      }),
      fetchContact: build.query({
        query: () => {
          return {
            url: `/contacts/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result?.map(({ id }) => ({ type: "Contact", id })), "Contact"]
            : ["Contact"],
      }),
      getContact: build.query({
        query: ({ _id }) => {
          return {
            url: `/contacts/${_id}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              // Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useCreateUserMutation,

  useDummyRunMutation,

  useFetchUserQuery,
  useUpdateUserMutation,

  useFetchStaffQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,

  useFetchProductQuery,
  useFetchTrendingQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductQuery,
  useDeleteProductMutation,
  useFetchAdminProductQuery,
  useGetAdminProductQuery,
  useGetRelatedProductQuery,
  useFetchSuggestionsQuery,

  useFetchCartQuery,
  useUpdateCartMutation,
  useDeleteCartMutation,
  useCreateCartMutation,

  useCancelOrderMutation,
  useFailOrderMutation,
  useSuccessOrderMutation,
  usePayNowMutation,

  useFetchOrderQuery,
  useDeleteOrderForUserMutation,
  useFetchAdminOrderQuery,
  useUpdateOrderStageMutation,

  useFetchReviewQuery,
  useCreateReviewMutation,
  useDeleteReviewMutation,

  useFetchCouponQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useFetchCouponAdminQuery,
  useDeleteCouponMutation,

  useForgotMailMutation,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useFetchContactQuery,
  useGetContactQuery,

} = allApi;

export { allApi };
