import React from 'react'
import AdminLoginForm from './form/AdminLoginForm'

const AdminLogin = () => {
  return (
    <div>
      <AdminLoginForm/>
    </div>
  )
}

export default AdminLogin
