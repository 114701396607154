import React from 'react'
import './CommonQuote.css'

const CommonQuote = () => {
  return (
    <div className='nina-campbell-quote'>
        <img src='/invertedcomas.png'/>
        <p>Chair are uniquely personal. They express a form, a comfort, and an aesthetic that speaks to the individuality of each person.</p>
        <h2>- Nina Cambell</h2>
    </div>
  )
}

export default CommonQuote