import React, { useEffect, useState } from "react";
import {  useFetchStaffQuery } from "../../../store/store";
import Staff_Table from "./staff2/Staff_Table";
import { Button, Popconfirm, message } from "antd";
import { useNavigate } from "react-router-dom";
import "./staff2/staffModel.css";
import Staff_form_parents from "./staff2/Staff_form_parents";
import styled from "styled-components";

const Model = ({Perform_cancel,userData}) => {
  return (
    <div className="model-con">
      <div className="model-box">
        <Staff_form_parents Perform_cancel={Perform_cancel} userData={userData} />

   

        
      </div>
    </div>
  );
};
const Staff = () => {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const Perform_cancel = () => {
    setShow(false);
    setUserData(null);
  };

  // const [deleteStaff, deleteStaffResponseInfo] =
  // useDeleteStaffMutation();
//   useEffect(() => {
// if(deleteStaffResponseInfo.isSuccess){
//     message.success("Delete Success")
// }
//   }, [deleteStaffResponseInfo]);

  const navigate = useNavigate();
  const {
    data: staff,
    isLoading: loading,
    error: error,
  } = useFetchStaffQuery();
  useEffect(() => {
    if (staff) {
      console.log(staff);
    }
  }, [staff]);
  const editfun = (e,record) => {
    e.stopPropagation();
    setUserData(record);
    setShow(true);
  };
  const deletefun = (e,data) => {
    e.stopPropagation();
console.log(data)
// deleteStaff(data)
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "_id",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "_id",
      width: "20%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "_id",
      width: "20%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "_id",
      width: "10%",
    },

    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: "10%",

      render: (record) => (
        <Button
          className="edit-link"
          type="primary"
          onClick={(e) => editfun(e, record)}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "Action",
      key: "_id",
      fixed: "right",
      width: "10%",

      render: (record) => (
        <Popconfirm title="Sure to delete?" onCancel={(e)=>{e.stopPropagation()}} onConfirm={(e) => deletefun(e,record)}>

        <Button onClick={(e)=>{e.stopPropagation()}} style={{color:"red"}}>Delete</Button>
            </Popconfirm>
      ),
    },
  ];

  const get_data = (data) => {
    console.log(data);

    setUserData(data);
    setShow(true);
  };
  const handlecreate = () => {
    // navigate("/admin/staff/form");
    setShow(true);
  };
  return (
    <Style>
    <div className="body-width-admin">
      <h1
        style={{ textAlign: "center", marginTop: "50px", marginBottom: "20px" }}
      >
        Staff User Table
      </h1>

      <Button
        onClick={() => handlecreate()}
        style={{ marginBottom: "20px" }}
        className="universal-button-atom"
      >
        CREATE STAFF USER
      </Button>

      <Staff_Table
        columns={columns}
        data={staff}
        loading={loading}
        get_data={get_data}
      />
      {show ? <Model userData={userData} Perform_cancel={Perform_cancel} /> : null}
    </div>
    </Style>
  );
};

export default Staff;

const Style = styled.div`
  .ant-table-wrapper .ant-table{
    padding: 0 3rem 0 6rem;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination{
    padding-right: 4rem;
  }
`

