import React from 'react'
import { useState } from 'react'
import './MainSectionProfilePage.css'
import { Link } from 'react-router-dom'
import TrackerBar from './TrackerBar'
import { useSelector } from 'react-redux'
import OrdersUser from './orderBox/OrdersUser'
import AddressPageMainPart from '../AddressPage_Components/AddressPageMainPart'
import { CloseOutlined } from "@ant-design/icons";

const UserModel=({Perform_cancel})=>{
  return(
    <>
      <div className="model-con">
      <div className="model-box" style={{height:"90vh",padding:"0px",width:"90vw"}}>
      <p className="p--cross--custom" onClick={() => Perform_cancel()}>
          <CloseOutlined />
        </p>
        <div style={{ height: "100%", overflow: "auto" }}>

    <AddressPageMainPart buy={"no"}/>
        </div>
      </div>
      </div>
    </>
  )
}
const MainSectionProfilePage = () => {
    const { user, userToken, loading, isAuthenticated } =
    useSelector((state) => state.user);
    const [selectedOption, setSelectedOption] = useState('Profile');
const [showUserModel, setShowUserModel] = useState(false);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };
    const Perform_cancel=()=>{
      setShowUserModel(false)
    }
    const renderInputFormat = () => {
        switch (selectedOption) {
          case 'Profile':
            return(
                <div className='profile-page-main-part-details'>
                    <div className='right-container-main-section-profile-page'>
                        <span>
                            <h4>Hello,</h4>
                            <h2>{user?.name}</h2>
                        </span>
                        
                        <img className='profile-image-main-section-profile-page' src='profilepageImage.png' alt=''/>
                        <div className='info-main-sectin-profile-page'>
                            <p>Mobile No : <span>{user?.billing?.phone}</span></p>
                            <p>Email ID : <span>{user?.email}</span></p>
                            <p>City : <span>{user?.billing?.city}</span></p>
                            <p>State : <span>{user?.billing?.state}</span></p>
                        </div>
                        <button onClick={()=>setShowUserModel(true)}>
                            Edit Profile
                        </button>
                    </div>

                </div>
            ); 
          case 'Your Orders':
            return(
                <div className='profile-page-main-part-details'>
                    <OrdersUser/>
                </div>
            );
          case 'Terms of Use':
            return (
              <div className='profile-page-main-part-details'>
                Terms of Use
              </div>
            );
          case 'Privacy Policy':
            return (
              <div className='profile-page-main-part-details'>
                Privacy Policy
              </div>
            );
          default:
            return null;
        }
      };

  return (
    <div className='main-section-profile-page-container'>
            <h2 className='payment-page-main-part-left-first-box'>
                Account
                <span>{user?.name}</span>
            </h2>

            <div className='profile-page-main-part-left-second-box'>
                <div className="profile-page-main-sidebar">
                    <div style={{position:"sticky",top:"10px"}}>


                    <div className={selectedOption==="Profile"?"payment-page-main-part-left-sidebar-option-active":"payment-page-main-part-left-sidebar-option"} onClick={() => handleOptionClick('Profile')}>Profile</div>
                    <div className={selectedOption==="Your Orders"?"payment-page-main-part-left-sidebar-option-active":"payment-page-main-part-left-sidebar-option"} onClick={() => handleOptionClick('Your Orders')}>Your Orders</div>
                    <div className={selectedOption==="Terms of Use"?"payment-page-main-part-left-sidebar-option-active":"payment-page-main-part-left-sidebar-option"} onClick={() => handleOptionClick('Terms of Use')}>Terms of Use</div>
                    <div className={selectedOption==="Privacy Policy"?"payment-page-main-part-left-sidebar-option-active":"payment-page-main-part-left-sidebar-option"} onClick={() => handleOptionClick('Privacy Policy')}>Privacy Policy</div>
                    </div>
                </div>
                <div className="payment-page-main-part-left-sidebar-input-format">
                    {selectedOption && (
                    <>
                        {renderInputFormat()}
                    </>
                    )}
                </div>
            </div>
{showUserModel?<UserModel Perform_cancel={Perform_cancel}/>:null}
    </div>
  )
}

export default MainSectionProfilePage