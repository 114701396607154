import React, { useEffect, useState } from "react";
import { BiLoader } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Login_show } from "../mutation/remainingSlice";

function Auth({ children }) {
  const { user, userToken, loading, isAuthenticated } =
    useSelector((state) => state.user);
const dispatch=useDispatch()

  // console.log(checkAuthLoading)
  // console.log(loading)
  // console.log(userToken)
  // console.log(user)

  // console.log(isAuthenticated)

  console.log("1");

 
  if (loading) {
    return <p>
      <BiLoader/>
    </p>;
  }
  if (!userToken || isAuthenticated === false) {
    dispatch(Login_show(true))
    return <Navigate to={"/"} />;
  }

  return <>{children}</>;
}

export default Auth;
