import React from 'react'
import { Link } from 'react-router-dom'
import './SignUp_button.css'
import { Login_show } from '../../../store/mutation/remainingSlice'
import { useDispatch } from 'react-redux'

const SignUp_button = () => {
  const dispatch=useDispatch()
  return (
    <Link className='signup-button-container' onClick={()=>dispatch(Login_show(true))}>
        <div className='signup-button-link' >
            Sign Up
        </div>
    </Link>
  )
}

export default SignUp_button