import React, { useEffect, useState } from "react";
import {
  DiscountFun,
  SumOfCart,
  SumOfCartWithDiscount,
  isAnyFieldEmpty,
} from "../../../atoms/static/StateFun";
import { Link, useNavigate } from "react-router-dom";
import {
  useCancelOrderMutation,
  useFailOrderMutation,
  usePayNowMutation,
  useSuccessOrderMutation,
} from "../../../store/store";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import logo from "./../../../assets/sueLogo.png";
import CouponBox from "../AddressPage_Components/CouponBox";

const BuyIt = (createProduct, cart, user, coupon) => {
  const data = isAnyFieldEmpty(user);
  if (data) {
    message.error("Please Fill Address Details Completely");
  } else {
    const namesArray = cart?.map?.((item) => item.product.name) || []; // Extract names from cart or initialize as empty array if cart is undefined/null
    const concatenatedString = namesArray.join(", "); // Join names with commas
    const structure = {
      amount: DiscountFun(
        parseInt(SumOfCartWithDiscount(cart)),
        coupon?.percentage
      ),
      name: user?.billing?.name,
      phone: user?.billing?.phone,
      email: user?.email,
      description: concatenatedString,
      couponDiscount: coupon?.percentage ? coupon?.percentage : 0,
    };
    createProduct(structure);
  }
};
const OrderSummaryBox = ({ cart, place }) => {
  const [createProduct, creatProductResponseInfo] = usePayNowMutation();
  const navigate = useNavigate();
  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [cancelOrder, cancelOrderResponseInfo] = useCancelOrderMutation();
  const [failedOrder, failedOrderResponseInfo] = useFailOrderMutation();
  const [successOrder, successOrderResponseInfo] = useSuccessOrderMutation();
  useEffect(() => {
    if (cancelOrderResponseInfo?.isSuccess) {
      message.success("Order Canceled");
    }
    if (cancelOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [cancelOrderResponseInfo]);
  useEffect(() => {
    if (failedOrderResponseInfo?.isSuccess) {
      message.success("Order Failed");
    }
    if (failedOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [failedOrderResponseInfo]);
  useEffect(() => {
    if (successOrderResponseInfo?.isSuccess) {
      message.success("Order Success");
    }
    if (successOrderResponseInfo?.isError) {
      message.error("Something Went Wrong In Cart And Order");
    }
  }, [successOrderResponseInfo]);
  useEffect(() => {
    if (creatProductResponseInfo?.isSuccess) {
      var options = {
        key: "" + creatProductResponseInfo?.data?.key_id + "",
        amount: "" + creatProductResponseInfo?.data?.amount + "",
        currency: "INR",
        name: "" + creatProductResponseInfo?.data?.product_name + "",
        description: "" + creatProductResponseInfo?.data?.description + "",
        image: logo,
        order_id: "" + creatProductResponseInfo?.data?.order_id + "",
        handler: function (response) {
          message.success("Payment Succeeded");
          // razorpayObject.modal.close()
          // window.open("/profile-page","_self")
          successOrder({ order_id: creatProductResponseInfo?.data?.order_id });
        },
        modal: {
          ondismiss: function () {
            message.error("Payment Cancelled");
            cancelOrder({ order_id: creatProductResponseInfo?.data?.order_id });
          },
        },
        prefill: {
          contact: "" + creatProductResponseInfo?.data?.contact + "",
          name: "" + creatProductResponseInfo?.data?.name + "",
          email: "" + creatProductResponseInfo?.data?.email + "",
        },
        notes: {
          description: "" + creatProductResponseInfo?.data?.description + "",
        },
        theme: {
          color: "#35363a",
        },
      };
      var razorpayObject = new window.Razorpay(options);

      razorpayObject.on("payment.failed", function (response) {
        message.error("Payment Failed");
        failedOrder({ order_id: creatProductResponseInfo?.data?.order_id });
      });

      razorpayObject.open();
    }
  }, [creatProductResponseInfo]);
  const handlePlaceOrder = () => {
    if (cart?.length > 0) {
      navigate("/address-page");
      console.log("hello");
    } else {
      message.error("Your Cart Is Empty");
    }
  };
  const [coupon, setCoupon] = useState();
  return (
    <div className="for-coupon">
      <div style={{ width: "100%" }} className="cart-page-right-container">
        <h3>Order Summary ({cart?.length} items)</h3>
        <div className="cart-page-right-prices-container">
          <div className="cart-page-right-prices-first">
            Total MRP
            <span>Rs.{SumOfCart(cart)}</span>
          </div>
          <div className="cart-page-right-prices-second">
            Discount on MRP
            <span>
              -Rs.{parseInt(SumOfCart(cart) - SumOfCartWithDiscount(cart))}
            </span>
          </div>
          <div className="cart-page-right-prices-second">
            Coupon Discount
            <span>
              -Rs.
              {parseInt(SumOfCartWithDiscount(cart)) -
                DiscountFun(
                  parseInt(SumOfCartWithDiscount(cart)),
                  coupon?.percentage
                )}
            </span>
          </div>
          <div className="cart-page-right-prices-second">
            Shipping Free
            <span>Free</span>
          </div>
          <div className="cart-page-right-prices-third">
            Total Amount
            <span>
              Rs.
              {DiscountFun(
                parseInt(SumOfCartWithDiscount(cart)),
                coupon?.percentage
              )}
            </span>
          </div>
          {place === "Buy Now" ? (
            <>
              <Button
                style={{ height: "100%" }}
                loading={creatProductResponseInfo?.isLoading}
                type="primary"
                onClick={() => BuyIt(createProduct, cart, user, coupon)}
                className="cart-page-right-place-order-link"
              >
                Buy Now
              </Button>
            </>
          ) : (
            <div
              onClick={handlePlaceOrder}
              className="cart-page-right-place-order-link"
            >
              Place Order
            </div>
          )}
        </div>
      </div>{" "}
      {place === "Buy Now" ? (
        <div style={{ width: "100%" }}>
          <CouponBox setCoupon={setCoupon} coupon={coupon} cart={cart} />
        </div>
      ) : null}
    </div>
  );
};

export default OrderSummaryBox;
