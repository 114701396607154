import React from "react";
import DiscoverPart from "../../../components/organisms/HomePage_Components/DiscoverPart";
import TrendingProducts from "../../../components/organisms/HomePage_Components/TrendingProducts";
import SavorComfort from "../../../components/organisms/HomePage_Components/SavorComfort";
import WeAlsoSell from "../../../components/organisms/HomePage_Components/WeAlsoSell";
import Sale from "../../../components/organisms/HomePage_Components/Sale";
import ShopByCategory from "../../../components/organisms/HomePage_Components/ShopByCategory";
import Emojispart from "../../../components/organisms/HomePage_Components/Emojispart";
import {
  useFetchSuggestionsQuery,
  useFetchTrendingQuery,
} from "../../../store/store";
import OtherCategoriesShopByCategory from "../../../components/organisms/ShopByCategoryPageComponents/OtherCategoriesShopByCategory";

const Home = () => {
  const carouselMap = [
    {
      image: "/fourth-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Soderhamn",
      spanPrice: "$729",
      price: "$660",
    },
    {
      image: "/first-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Sodehamn",
      spanPrice: "$729",
      price: "$660",
    },
    {
      image: "/first-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Sodehamn",
      spanPrice: "$729",
      price: "$660",
    },
    {
      image: "/first-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Sodehamn",
      spanPrice: "$729",
      price: "$660",
    },
    {
      image: "/first-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Sodehamn",
      spanPrice: "$729",
      price: "$660",
    },
    {
      image: "/first-chair-carousel-image.png",
      uptext: "SOFA",
      downtext: "Sodehamn",
      spanPrice: "$729",
      price: "$660",
    },
  ];
  const {
    data: trending,
    isLoading: trending_loading,
  } = useFetchTrendingQuery({ filter: "" });
  const {
    data: suggestions,
    isLoading: suggestions_loading,
  } = useFetchSuggestionsQuery();
console.log(suggestions)
  return (
    <>
      <DiscoverPart />
      {/* <TrendingProducts/>
       */}
      <WeAlsoSell title={"Trending"} data={trending} plus={true} />

      <SavorComfort />
      <Emojispart />
      <WeAlsoSell data={suggestions} title={"Suggested For You"} plus={true} />
      {/* <Sale/> */}
      <OtherCategoriesShopByCategory count={4} title={"Shop By Category"} />

      {/* <ShopByCategory/> */}
    </>
  );
};

export default Home;
