import { Button, Form, Input, Radio, Switch, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useEffect, useState } from "react";
import {
    useCreateCouponMutation,
    useUpdateCouponMutation,
} from "../../../../store/store";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 15 },
};
const onFinish = (data, createStaff) => {
  console.log("create", data);

  createStaff(data);
};
const onUpdate = (data, updateStaff) => {
  console.log("update", data);
    updateStaff(data)
};

const CreateCoupon = ({ Perform_cancel, userData }) => {
  const labelCol = 8;
  const wrapperCol = 10;
  const product = false;
  const [form] = Form.useForm();
  const [createStaff, creatStaffResponseInfo] = useCreateCouponMutation();
  console.log(creatStaffResponseInfo);
  const [updateStaff, updateStaffResponseInfo] = useUpdateCouponMutation();
  useEffect(() => {
    if (creatStaffResponseInfo.isSuccess) {
      message.success("Staff Created");
      Perform_cancel();
    }
    if (creatStaffResponseInfo.isError) {
      message.error("You Dont Have Clearence");
    }
  }, [creatStaffResponseInfo]);
  useEffect(() => {
    if (updateStaffResponseInfo.isSuccess) {
      message.success("Staff Updated");
      Perform_cancel();
    }

    if (updateStaffResponseInfo.isError) {
      message.error("You Dont Have Clearence");
    }
  }, [updateStaffResponseInfo]);

  return (
    <div>
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={(data) => {
          if (userData) {
            onUpdate(data, updateStaff);
          } else {
            onFinish(data, createStaff);
          }
        }}
        style={{
          maxWidth: "100%",
        }}
        // autoComplete="off"
        {...layout}
        initialValues={userData}
        // initialValues={thisone}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "85%",
            margin: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              width: "100%",
            }}
          ><FormItem labelCol={labelCol} label="Active" name="active">
          <Switch />
        </FormItem>
            <FormItem style={{display:"none"}} name="_id"></FormItem>
            <FormItem
              labelCol={labelCol}
              label="Title"
              name="title"
              className="formitem-staff"
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Title"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Description"
              name="description"
              className="formitem-staff"
            >
              <Input
                className="create-product-input-from"
                placeholder="Enter Description"
              />
            </FormItem>

            <FormItem
              labelCol={labelCol}
              label="Price"
              name="price"
              className="formitem-staff"
            >
              <Input
                type="number"
                className="create-product-input-from"
                placeholder="Enter Price"
              />
            </FormItem>
            <FormItem
              labelCol={labelCol}
              label="Percentage"
              name="percentage"
              className="formitem-staff"
            >
              <Input
                type="number"
                className="create-product-input-from"
                placeholder="Enter Percentage"
              />
            </FormItem>
            
          </div>
        </div>

        <FormItem>
          <Button
            // loading={loa}
            type="primary"
            htmlType="submit"
          >
            {userData ? <span>UPDATE</span> : <span>CREATE</span>}
          </Button>
          <Button
            // loading={loa}
            style={{ marginLeft: "20px" }}
            danger
            type="primary"
            onClick={() => Perform_cancel()}
          >
            CANCEL
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default CreateCoupon;
