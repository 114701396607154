import "./AddressPageMainPart.css";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFetchCartQuery } from "../../../store/store";
import OrderSummaryBox from "../CartPage_Components/OrderSummaryBox";
import Hr from "../../../atoms/buttons/Hr";
import EditForm from "../CartPage_Components/editform/EditForm";
import CouponBox from "./CouponBox";

const AddressPageMainPart = ({buy}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const handleClick = (index) => {
    setSelectedAddress(index);
  };

  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const {
    data: cart,
    isLoading: cartLoading,
    isFetching: cartFetch,
  } = useFetchCartQuery({ _id: user?._id });
  const [editState, setEditState] = useState(false);
  return (
    <div className="address-page-main-part-container">
      <div className="address-page-main-part-left-container">
        <div className="address-page-main-part-left-first-box">
          Select Delivery Address
          <button onClick={() => setEditState(!editState)} className="address-page-add-new-address">
            Add New Address
          </button>
        </div>
        <p>Default Address</p>
        {/* <div className="address-page-main-part-left-second-box">
                <ul className="address-page-main-part-address-list">
                    {addresses.map((address, index) => (
                        <React.Fragment key={index}>
                            {index === 1 && <li className="address-page-main-part-list-header">Other Addresses</li>}
                            <li key={index}
                            className={`address-page-main-part-address-item ${selectedAddress === index ? 'selected' : ''}`}
                            onClick={() => handleClick(index)}>
                                <div className="address-page-main-part-bullet"></div>
                            </li>
                                <div className="address-page-main-part-address-details">
                                    <div className='address-page-main-part-address-name'> {address.name}</div>
                                    <div className='address-page-main-part-address-text'>{address.lorem}</div>
                                    <div className='address-page-main-part-address-pincode'>{address.pincode}</div>
                                    <div className='address-page-main-part-address-mobile'><span>Mobile - </span>{address.mobile}</div>
                                    <div className='address-page-main-part-buttoons-container'>
                                        <button>Remove</button>
                                        <button>Edit</button>
                                    </div>
                                </div>
                        </React.Fragment>
                        
                    ))}
                </ul>
                    
            </div> */}
        {editState ? (
          <>
            <EditForm user={user} setEditState={setEditState} />
            <div className="address-page-main-part-buttoons-container">
              <button onClick={() => setEditState(false)}>Close</button>
            </div>
          </>
        ) : (
          <div className="address-page-main-part-address-details">
            <div className="address-page-main-part-address-name">
              {" "}
              {user?.name}
            </div>
            <div className="address-page-main-part-address-text">
              {user?.email}
            </div>
            <Hr />
            <b>Billing Details</b>
            <div className="address-page-main-part-address-pincode">
              <b>Name :</b> {user?.billing?.name} <b>Phone :</b>{" "}
              {user?.billing?.phone}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>State :</b> {user?.billing?.state} <b>City :</b>{" "}
              {user?.billing?.city}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Addresss :</b> {user?.billing?.address}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Email :</b> {user?.billing?.email}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Pincode :</b> {user?.billing?.pincode}
            </div>
            <Hr />

            <b>Shipping Details</b>

            <div className="address-page-main-part-address-pincode">
              <b>Name :</b> {user?.shipping?.name} <b>Phone :</b>{" "}
              {user?.shipping?.phone}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>State :</b> {user?.shipping?.state} <b>City :</b>{" "}
              {user?.shipping?.city}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Email :</b> {user?.shipping?.email}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Addresss :</b> {user?.shipping?.address}
            </div>
            <div className="address-page-main-part-address-pincode">
              <b>Pincode :</b> {user?.shipping?.pincode}
            </div>
            <div className="address-page-main-part-buttoons-container">
              {/* <button>Remove</button> */}
              <button onClick={() => setEditState(true)}>Edit</button>
            </div>
          </div>
        )}
      </div>

      {/* <div className='address-page-main-part-right-container'>
            <p>Delivery Estimates</p>

            <div className='address-page-main-part-right-first-box'>
                <img src='/chair7.png' alt=''/>
                <div className='address-page-main-part-right-line-container'><div className='address-page-main-part-right-vertical-line'></div></div>
                <p>Estimated Delivery by <span>17th Feb 2024</span></p>
            </div>

            <div className='address-page-main-part-right-second-box'>
                <p>Order Summary (1 Item)</p>
                <div className='address-page-main-part-right-line-container'><div className='address-page-main-part-right-horizontal-line'></div></div>
                
                <div className='address-page-right-prices-container'>
                    <div className='address-page-right-prices-first'>
                        Total MRP
                        <span>Rs.1699</span>
                    </div>
                    <div className='address-page-right-prices-second'>
                        Discount on MRP
                        <span>-Rs.300</span>
                    </div>
                    <div className='address-page-right-prices-second'>
                        Shipping Fee
                        <span>Free</span>
                    </div>
                </div>

                <div className='address-page-main-part-right-line-container'><div className='address-page-main-part-right-horizontal-line'></div></div>
                <div className='address-page-right-prices-third'>
                        Total Amount
                        <span>Rs.1399</span>
                </div>
                <Link to='/payment-page' className='address-page-right-place-order-link'>
                    Continue
                </Link>
            </div>

        </div> */}
        {buy==="no"?null:
      <OrderSummaryBox cart={cart} place={"Buy Now"} />
        }
    </div>
  );
};

const addresses = [
  {
    name: "Harsh Shah",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    pincode: "Nasik - 422013",
    mobile: "9876543210",
  },
  {
    name: "Om Shah",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    pincode: "Rohtak - 124001",
    mobile: "0123456789",
  },
  {
    name: "Jane Doe",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    pincode: "Pune - 124123",
    mobile: "0123456789",
  },
];

export default AddressPageMainPart;
