import React, { useEffect } from "react";
import { useState } from "react";
import "./ChairImage_Description.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { LuShoppingBag } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { DiscountFun, createSlug } from "../../../atoms/static/StateFun";
import { message } from "antd";
import { useCreateCartMutation } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Login_show } from "../../../store/mutation/remainingSlice";
const ChairImage_Description = ({ product, id }) => {
  const { user, userToken, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );
  console.log(product);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("productinfo");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const [quantity, setQuantity] = useState(1);
  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };
  const [createCart, createCartResponseInfo] = useCreateCartMutation();
  const handleBuy = () => {
    if (product?.color.filter((item) => item._id === id)[0]?.color) {
      const structure = {
        quantity: quantity,
        color: product?.color.filter((item) => item._id === id)[0]?.color,
        product_id: product?._id,
      };
      if (user && isAuthenticated) {
        createCart(structure);
      } else {
        dispatch(Login_show(true));
      }
    } else {
      message.error("Please Select Color");
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (createCartResponseInfo?.isSuccess) {
      message.success("Added To Cart");
      navigate("/cart-page");
    }
    if (createCartResponseInfo?.isError) {
      message.error("Something Went Wrong");
    }
  }, [createCartResponseInfo]);
  const [color, setColor] = useState();
  return (
    <>
      <div className="chair-image-description-container">
        <div className="chair-image-description-left-container">
          {product?.image && product?.multi_img.length > 0 ? (
            <Slider {...settings}>
              <div>
                <img
                  className="chair-image-description-image"
                  src={
                    product?.image
                      ? product?.image
                      : "https://imgs.search.brave.com/IoOvviQ4di6rWn-a5_3PxljCQRXg5AQco4id07WyC3o/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9waG90by1tb2Rl/cm4tbHV4dXJ5LWFy/bS1jaGFpci1mdXJu/aXR1cmUtZGVzaWdu/Xzc2MzExMS0yMjEx/Ni5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
                  }
                  alt=""
                />
              </div>
              {product?.multi_img?.map((x) => (
                <>
                  <div>
                    {console.log("lion", product?.image && product?.multi_img)}
                    <img
                      className="chair-image-description-image"
                      src={x}
                      alt=""
                    />
                  </div>
                </>
              ))}
            </Slider>
          ) : (
            <div>
              <img
    className="chair-image-description-image"
    src={product?.image || "https://imgs.search.brave.com/IoOvviQ4di6rWn-a5_3PxljCQRXg5AQco4id07WyC3o/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9waG90by1tb2Rl/cm4tbHV4dXJ5LWFy/bS1jaGFpci1mdXJu/aXR1cmUtZGVzaWdu/Xzc2MzExMS0yMjEx/Ni5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"} // Replace with your placeholder image URL
    alt=""
/>
            </div>
          )}

          {/* <img
            className="chair-image-description-image"
            src={
              "https://imgs.search.brave.com/IoOvviQ4di6rWn-a5_3PxljCQRXg5AQco4id07WyC3o/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9waG90by1tb2Rl/cm4tbHV4dXJ5LWFy/bS1jaGFpci1mdXJu/aXR1cmUtZGVzaWdu/Xzc2MzExMS0yMjEx/Ni5qcGc_c2l6ZT02/MjYmZXh0PWpwZw"
            }
            alt=""
          /> */}
        </div>
        <div className="chair-image-description-right-container">
          <div className="chair-image-descrition-heading-container">
            <h1>
              {product?.name}
              <p> {product?.category}</p>
              <p style={{ color: product?.instock ? "green" : "red" }}>
                <br />
                {product?.instock ? "In stock" : "Out of stock"}
              </p>
              <p
                style={{
                  color: product?.active ? "green" : "grey",
                  fontSize: "0.7rem",
                }}
              >
                {product?.active ? "Active" : "Not Active"}
              </p>
            </h1>
            <div className="stars-container-123">
              {/* <FaStar className="chair-image-description-star-icon" />
              <FaStar className="chair-image-description-star-icon" />
              <FaStar className="chair-image-description-star-icon" />
              <FaStar className="chair-image-description-star-icon" />
              <FaStar className="chair-image-description-star-icon" /> */}
              <p className="chair-image-description-star-icon">
                {" "}
                {product?.rating}/5{" "}
                <FaStar className="chair-image-description-star" />
              </p>
            </div>
          </div>
          {product?.discount === 0 ? (
            <div className="chair-image-description-price-whole-container">
              <h2 className="chair-image-description-another-heading">
                Rs.{product?.price} <p></p>
              </h2>
            </div>
          ) : (
            <div className="chair-image-description-price-whole-container">
              <span className="chair-image-description-price-cut">
                Rs.{product?.price}
              </span>
              <h2 className="chair-image-description-another-heading">
                Rs.{DiscountFun(product?.price, product?.discount)} <p></p>
                <p>{product?.discount}% Off</p>{" "}
              </h2>
            </div>
          )}

          <div className="chair-image-description-choose-colour-container">
            Choose Colour
            <div className="colour-circles-container">
              {product?.color?.map((item) => (
                <FaCircle
                  onClick={() => {
                    navigate(`/product-details-page/${createSlug(product?.name)}-${item?._id}`);
                  }}
                  style={{
                    color: item.color,
                    textShadow: "0px 0px 7px black",
                    cursor: "pointer",
                    transform: id === item._id ? "scale(1.2)" : "scale(1)",
                  }}
                />
              ))}
            </div>
          </div>
          <div className="accordion-container">
            <div className="accordion-options">
              <div
                className={`accordion-option ${
                  selectedOption === "productinfo" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("productinfo")}
              >
                Product Info
              </div>
              <div
                className={`accordion-option ${
                  selectedOption === "dimensions" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("dimensions")}
              >
                Dimensions
              </div>
              <div
                className={`accordion-option ${
                  selectedOption === "return" ? "active" : ""
                }`}
                onClick={() => handleOptionClick("return")}
              >
                Return Policy
              </div>
            </div>
            <div className="accordion-content">
              {selectedOption === "productinfo" && (
                // <div className="accordion-content">
                //   <ul>
                //     <li>
                //       Suitable For: <span>{product?.suitableFor}</span>
                //     </li>
                //     <li>
                //       Material: <span>{product?.chair?.material}</span>{" "}
                //     </li>
                //     <li>
                //       Max Load: <span>{product?.chair?.maxLoad}</span>
                //     </li>
                //     <li>
                //       Cover: <span>{product?.chair?.cover}</span>
                //     </li>
                //     <li>
                //       Back Cushion: <span>{product?.chair?.backCushion}</span>
                //     </li>
                //     <li>
                //       Center Beam: <span>{product?.chair?.centerBeam}</span>
                //     </li>
                //     <li>
                //       Arm Rest: <span>{product?.chair?.armRest}</span>
                //     </li>
                //     <li>
                //       Base: <span>{product?.chair?.base}</span>
                //     </li>
                //     <li>
                //       Mech: <span>{product?.chair?.mech}</span>
                //     </li>
                //     <li>
                //       Gas Lift: <span>{product?.chair?.gaslift}</span>
                //     </li>
                //   </ul>
                // </div>
                <div className="accordion-content">
                  <ul>
                    {product && product?.chair && (
                      <>
                        {product?.suitableFor && (
                          <li>
                            Suitable For: <span>{product?.suitableFor}</span>
                          </li>
                        )}
                        {product?.chair.material && (
                          <li>
                            Material: <span>{product?.chair.material}</span>
                          </li>
                        )}
                        {product?.chair.maxLoad && (
                          <li>
                            Max Load: <span>{product?.chair.maxLoad}</span>
                          </li>
                        )}
                        {product?.chair.cover && (
                          <li>
                            Cover: <span>{product?.chair.cover}</span>
                          </li>
                        )}
                        {product?.chair.backCushion && (
                          <li>
                            Back Cushion:{" "}
                            <span>{product?.chair.backCushion}</span>
                          </li>
                        )}
                        {product?.chair.centerBeam && (
                          <li>
                            Center Beam:{" "}
                            <span>{product?.chair.centerBeam}</span>
                          </li>
                        )}
                        {product?.chair.armRest && (
                          <li>
                            Arm Rest: <span>{product?.chair.armRest}</span>
                          </li>
                        )}
                        {product?.chair.base && (
                          <li>
                            Base: <span>{product?.chair.base}</span>
                          </li>
                        )}
                        {product?.chair.mech && (
                          <li>
                            Mech: <span>{product?.chair.mech}</span>
                          </li>
                        )}
                        {product?.chair.gaslif && (
                          <li>
                            Gas Lift: <span>{product?.chair.gaslif}</span>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              )}
              {selectedOption === "dimensions" && (
                <div className="accordion-content">
                  <ul>
                    {product && product?.size && (
                      <>
                        {product?.size.totalHeight && (
                          <li>
                            Total Height:{" "}
                            <span>{product?.size.totalHeight}</span>
                          </li>
                        )}
                        {product?.size.seatHeight && (
                          <li>
                            Seat Height: <span>{product?.size.seatHeight}</span>
                          </li>
                        )}
                        {product?.size.length && (
                          <li>
                            Length: <span>{product?.size.length}</span>
                          </li>
                        )}
                        {product?.size.width && (
                          <li>
                            Width: <span>{product?.size.width}</span>
                          </li>
                        )}
                        {product?.size.unit && (
                          <li>
                            Unit: <span>{product?.size.unit}</span>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              )}
              {selectedOption === "return" && (
                <div className="accordion-content">
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Nemo corrupti omnis qui similique, voluptatem ex assumenda!
                    Ipsa quaerat sit harum iusto tenetur molestiae nesciunt est
                    consequuntur labore aut quo? Expedita numquam illo
                    inventore, voluptates soluta harum rerum sed.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="quantity-selector">
            <label
              className="chair-image-description-quantity"
              htmlFor="quantity"
            >
              Quantity:
            </label>
            <select
              className="chair-image-description-dropdown"
              id="quantity"
              value={quantity}
              onChange={handleQuantityChange}
            >
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="chair-image-description-buttons-container">
            <div
              onClick={() => handleBuy()}
              className="chair-image-description-buy-now"
            >
              <LuShoppingBag />
              Buy Now
            </div>
            <button className="chair-image-description-wishlist">
              <FaRegHeart className="chair-image-description-heart" />
              Wishlist
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const images = [
  {
    path: "/chair5.png",
  },
  {
    path: "/chair5.png",
  },
  {
    path: "/chair5.png",
  },
];
export default ChairImage_Description;
