import React, { useEffect, useState } from "react";
import "./OtherCategoriesShopByCategory.css";
import { Link, useNavigate } from "react-router-dom";
import { categoryArray } from "../../../atoms/static/State";
import { useDispatch, useSelector } from "react-redux";
import { FilterString } from "../../../store/mutation/remainingSlice";

const OtherCategoriesShopByCategory = ({title,count}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [runs, setruns] = useState();
  const { filter_string } = useSelector((state) => state.remaning);

  useEffect(() => {
    if (`&category=${runs}` === filter_string) {
      navigate("/product-page");
    }
  }, [runs,filter_string]);
  const [displayedItems, setDisplayedItems] = useState(count?count:8);

  const handleViewMore = () => {
    // Set the number of displayed items to the total number of items
    setDisplayedItems(categoryArray.length);
  };
  return (
    <div className="OtherCategoriesShopByCategory-container">


      <h1>{title?title:
      "Other Categories"
      }
      </h1>
      <div className="important-dabba">
        <ul>
          {categoryArray.slice(0, displayedItems).map((item,index) => (
            <li
            key={index}
            style={{cursor:"pointer"}}
              onClick={() => {
                dispatch(FilterString(`&category=${item}`));
                setruns(item);
              }}
            >
              <>
                <img src="/chair1.png" />
                <p>{item}</p>
              </>
            </li>
          ))}
         
          {/* <li>
                    <img src='/chair2.png' />
                    <p>Premium Visitor Chair</p>
                </li>
                <li>
                    <img src='/chair3.png' />
                    <p>Ergonomic Work Series</p>
                </li>
                <li>
                    <img src='/chair4.png' />
                    <p>Premium Netted Chair</p>
                </li>
                <li>
                    <img src='/chair1.png' />
                    <p>Cafe Chair Series</p>
                </li>
                <li>
                    <img src='/chair2.png' />
                    <p>High Counter Series</p>
                </li>
                <li>
                    <img src='/chair3.png' />
                    <p>Lounge Chair Series</p>
                </li>
                <li>
                    <img src='/chair4.png' />
                    <p>Training Chair Series</p>
                </li> */}
        </ul>
        
      </div>
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px"}}>

      {displayedItems < categoryArray.length && (
          <button    className="chair-image-description-buy-now" onClick={handleViewMore}>View More</button>
        )}
        </div>
      <div className="nina-campbell-quote">
        <img src="/invertedcomas.png" />
        <p>
          Chair are uniquely personal. They express a form, a comfort, and an
          aesthetic that speaks to the individuality of each person.
        </p>
        <h2>- Nina Cambell</h2>
      </div>
    </div>
  );
};

export default OtherCategoriesShopByCategory;
