import { Button, Form, Input } from 'antd'
import React, { useEffect } from 'react'
import './adminlogin.css'
import FormItem from 'antd/es/form/FormItem'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminLogin } from '../../../../store/mutation/userSlice'
const Login = (data, dipatch, adminLogin) => {
  dipatch(adminLogin(data))
  console.log(data)
}
export default function AdminLoginForm() {
  const dipatch = useDispatch()
  const { user, userToken, admin_loading, admin } = useSelector
    (
      (state) => state.user
    );
  const navigate = useNavigate();

  useEffect(() => {
    if (admin === true) {
      navigate('/admin')
    }
  }, [admin]);
  return (
    <div className='login-admin-con'>

      <div className='login-admin-box'>
        <h1 style={{ marginBottom: "30px " }}>Admin Login</h1>
        <Form onFinish={(data) => Login(data, dipatch, adminLogin)}>
          <FormItem name="email">
            <Input required className='all-placeholder-grey' placeholder='Enter Your Email' />
          </FormItem>
          <FormItem name="password">
            <Input type='password' required className='all-placeholder-grey' placeholder='Enter Your Password' />
          </FormItem>
          <FormItem >
            <Button loading={admin_loading} htmlType='submit' type='primary' style={{ width: "100%", background: "var(--pr-text-color)", color: "var(--pr-bg-color)" }}>Login</Button>
          </FormItem>
        </Form>
      </div>
    </div>
  )
}
